import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';

import { Icon, IconButton } from 'components';
import { Modal } from 'components/Modal/Modal.component';
import { ButtonV3, InputV2 } from 'components/ComponentV2';
import { Checkbox } from 'components/form';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { REGEX } from 'lib/utils/regex';

import styles from './EditShortLinkModal.module.scss';

interface EditShortLinkModalProps {
  open: boolean;
  onClose: () => void;
  slug: string;
  changeShortSlug: (shortSlug: string) => void;
  error?: string;
}

export const EditShortLinkModal: React.FC<EditShortLinkModalProps> = (
  props: EditShortLinkModalProps
) => {
  const { open, onClose, slug: oldSlug, changeShortSlug, error } = props;

  const [isConsent, setIsConsent] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>('');
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setSlug('');
      setIsConsent(false);
    } else {
      setSlug(oldSlug);
    }
  }, [open]);

  const linkPrefix = useMemo(() => {
    const environment = process.env.NEXT_PUBLIC_TARGET_ENVIRONMENT;
    switch (environment) {
      case 'production':
        return 'huzzle.app/s/';
      case 'uat':
        return 'uat.huzzle.app/s/';
      case 'staging':
      default:
        return 'staging.huzzle.app/s/';
    }
  }, []);

  const toggleConsent = () => {
    setIsConsent(!isConsent);
  };

  const onSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const lowercapsSlug = event.target.value.toLowerCase();
    const emailRegex = REGEX.SHORTLINK;
    setSlug(lowercapsSlug);
    setIsInvalid(!emailRegex.test(lowercapsSlug));
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      contentClassName={styles.editShortLink}
    >
      <div className={styles.editShortLinkHeader}>
        <p className={styles.title}>
          {oldSlug ? 'Change society profile link' : 'Claim your shortlink'}
        </p>
        <IconButton
          iconName="close"
          size="small"
          className={styles.closeIcon}
          onClick={onClose}
        />
      </div>

      <div className={styles.editShortLinkContent}>
        {!oldSlug && (
          <p className={styles.infoBox}>
            Use your unique shortlink to easily engage with your members.
          </p>
        )}
        <div className={styles.inputWrapper}>
          <div
            className={cn(styles.inputBox, {
              [styles.errorBox]: !!error || isInvalid,
            })}
          >
            <p className={styles.inputPrefix}>{linkPrefix}</p>
            <InputV2 value={slug} onChange={onSlugChange} />
          </div>
          {error && (
            <div className={styles.error}>
              <Icon iconName="icon_info" size="small" />
              <p>{error}</p>
            </div>
          )}
        </div>
        {oldSlug && (
          <div className={styles.consentBox}>
            <Checkbox
              className={styles.checkBox}
              checked={isConsent}
              onChange={toggleConsent}
            >
              <p className={styles.consentText}>
                I understand that the current society profile link will stop
                working once I change the link
              </p>
            </Checkbox>
          </div>
        )}
      </div>

      <div className={styles.editShortLinkAction}>
        {oldSlug && (
          <ButtonV3 color="secondary" onClick={onClose}>
            Cancel
          </ButtonV3>
        )}
        <Tooltip
          className={styles.actionTooltip}
          content="Check the box that you understand link change implications in order to continue"
          color="dark"
          position="top"
          disabled={!oldSlug || isConsent}
        >
          <ButtonV3
            color="primary"
            disabled={(!!oldSlug && !isConsent) || isInvalid}
            onClick={() => changeShortSlug(slug)}
          >
            {oldSlug ? 'Change link' : 'Claim link'}
          </ButtonV3>
        </Tooltip>
      </div>
    </Modal>
  );
};
