import React, {
  ReactElement,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import cn from 'classnames';
import useSWR from 'swr';
import { noop } from 'lodash';
import { useTranslation } from 'next-i18next';
import { Icon, IconButton, Tooltip } from 'components';

import { Row } from 'components/layout';
import MultiSelect from 'components/MultiSelect';
import GroupedMultiSelect from 'components/GroupedMultiSelect';
import SocietyUniversityFilter from 'components/SocietyUniversityFilter';
import { useResponsive } from 'hooks/useResponsive';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';
import { useCommonList } from 'lib/contexts/common-list-context';
import { Option } from 'lib/models/option';
import { API_ROUTES } from 'lib/api-routes';

import styles from './ToolFilters.module.scss';

export type TCandidateTransactionTypes = Array<{
  name: string;
  filter: string;
}>;

type MultiRefs = {
  listRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

const ToolFilters = ({
  open,
  isScroll,
  className,
  filters,
  partialFilters,
  onChangeFilter,
  isTalentSource,
  showFirstFilteredEntityName = true,
  scrollPosition,
  isMultiselectDisabled,
  partialCount,
  isPartialLoading,
  isInvitePopup,
  onChangeGroupedFilter,
}: {
  open?: boolean;
  isScroll?: boolean;
  className?: string;
  filters?: Record<string, string[]> | null;
  partialFilters?: Record<string, string[]> | null;
  onChangeFilter: (key: string, values: string[], isPartial?: boolean) => void;
  isTalentSource?: boolean;
  showFirstFilteredEntityName?: boolean;
  scrollPosition?: string;
  showWhiteMultiselects?: boolean;
  isMultiselectDisabled: boolean;
  partialCount: number;
  isPartialLoading: boolean;
  isInvitePopup?: boolean;
  onChangeGroupedFilter: ({
    filter,
    isPartial,
  }: {
    filter: Record<string, string[]>;
    isPartial: boolean;
  }) => void;
}): ReactElement => {
  const screens = useResponsive();
  const { isRecruiter, isManager, recruiterCompany } = useAuth();
  const [tc] = useTranslation('common');

  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);

  const companyName = isRecruiter ? recruiterCompany?.name : '';

  const { data: candidateTransactionTypesResponse } = useSWR<{
    data: TCandidateTransactionTypes;
  }>(
    isRecruiter ? API_ROUTES.COMPANY_CANDIDATE_TRANSACTION_TYPES : null,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );
  const candidateTransactionTypes = useMemo(() => {
    return candidateTransactionTypesResponse?.data?.map(({ name, filter }) => ({
      id: filter,
      value: filter,
      label: name,
    }));
  }, [candidateTransactionTypesResponse]);

  const {
    areaOfResponsibilities,
    opportunityTypes,
    industryOptions,
    subjects,
    cityCountryList,
    studyYearOptions,
    graduateDateOptions,
    verifiedSocietiesOptions,
    isSocietiesDataLoading,
    fetchMoreSocieties,
    setSocietyFilterParams,
    universitiesOptions,
    isUniversitiesDataLoading,
    fetchMoreUniversities,
    setUniversityFilterParams,
    languageOptions,
    countries,
    genders,
    raceEthnicity,
    disabilityFilterOptions,
    socioEconomicStatusFilterOptions,
    internshipKindOptions,
  } = useCommonList();

  const opportunityTypeSuboptions = useMemo(() => {
    const internshipSuboptions =
      internshipKindOptions?.map((item) => ({
        ...item,
        parent_id: 'internship',
      })) || [];
    return internshipSuboptions;
  }, [internshipKindOptions]);

  const genderOptions = useMemo(
    () => genders?.filter((item) => item.label !== 'Prefer not to say'),
    [genders]
  );

  const ethnicityOptions = useMemo(
    () => raceEthnicity.filter((item) => item.label !== 'Prefer not to say'),
    [raceEthnicity]
  );

  const updatedIndustryOptions = industryOptions?.map((item) => ({
    ...item,
    value: item?.id,
  }));

  const filtersListRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (filtersListRef.current) {
        const { current } = filtersListRef;
        setShowPrevArrow(current.scrollLeft > 0);
        setShowNextArrow(
          current.scrollLeft + current.clientWidth + 10 < current.scrollWidth
        );
      }
    };

    // Call it once to set initial state
    handleScroll();

    // Add event listener
    filtersListRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      // Cleanup listener on unmount
      filtersListRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [isScroll, open, filters, companyName, filtersListRef]);

  /**  todo: Need to check why this was written.
   * This is re-rendering the filters and
   *  shifting positions of selecting options
  useEffect(() => {
    if (showPrevArrow && filtersListRef.current) {
      const { current } = filtersListRef;

      // Move the scroll all the way to the right
      current.scrollLeft = current.scrollWidth - current.clientWidth;
    }
  }, [filters, showPrevArrow]);
   */

  const otherOptions = [
    { id: 'cv_available', value: 'cv_available', label: 'CV visible' },
  ];

  const opportunityTypesOptions = useMemo(() => {
    return opportunityTypes
      ?.filter((item) => item.display_name !== 'Part-time Jobs')
      ?.map((item) => ({
        id: item.id,
        value: item.id,
        label: item.display_name,
      }));
  }, [opportunityTypes]);

  const filtersList = [
    showFirstFilteredEntityName
      ? {
          title: companyName || '',
          options: candidateTransactionTypes,
          values: filters?.['candidate_transactions'] || [],
          partialValues: partialFilters?.['candidate_transactions'],
          isSearchable: false,
          onChange: (values: Array<string>, isPartial?: boolean) =>
            onChangeFilter('candidate_transactions', values, isPartial),
          isDisabled: false,
          showFilter: true,
        }
      : undefined,
    {
      title: 'University',
      options: universitiesOptions,
      values: filters?.['university_ids'] || [],
      partialValues: partialFilters?.['university_ids'],
      isSearchable: true,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('university_ids', values, isPartial);
      },
      isDisabled: false,
      showFilter: true,
      fetchMoreData: fetchMoreUniversities,
      setNestedFilterParams: setUniversityFilterParams,
      showLoader: isUniversitiesDataLoading,
      showSocietyUniversityFilter: true,
    },
    {
      title: 'Subject',
      options: subjects,
      values: filters?.['subject_ids'] || [],
      partialValues: partialFilters?.['subject_ids'],
      isSearchable: true,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('subject_ids', values, isPartial);
      },
      isDisabled: false,
      showFilter: true,
    },
    {
      title: 'Study year',
      options: studyYearOptions,
      values: filters?.['study_year'] || [],
      partialValues: partialFilters?.['study_year'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('study_year', values, isPartial);
      },
      isDisabled: false,
      showFilter: isManager ? true : false,
    },
    {
      title: 'Graduate date',
      options: graduateDateOptions,
      values: filters?.['education_end_date_year'] || [],
      partialValues: partialFilters?.['education_end_date_year'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('education_end_date_year', values, isPartial);
      },
      isDisabled: false,
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Society affiliation',
      options: verifiedSocietiesOptions,
      values: filters?.['affiliated_society_ids'] || [],
      partialValues: partialFilters?.['affiliated_society_ids'],
      isSearchable: true,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('affiliated_society_ids', values, isPartial);
      },
      isDisabled: false,
      showFilter: isRecruiter ? true : false,
      fetchMoreData: fetchMoreSocieties,
      setNestedFilterParams: setSocietyFilterParams,
      showLoader: isSocietiesDataLoading,
      showSocietyUniversityFilter: true,
      isSocietyFilter: true,
    },
    {
      title: 'Looking for',
      options: opportunityTypesOptions,
      subOptions: opportunityTypeSuboptions,
      values: filters?.['opportunity_types'] || [],
      subValues: filters?.['touchpointable_kinds'] || [],
      partialValues: partialFilters?.['opportunity_types'],
      partialSubValues: partialFilters?.['touchpointable_kinds'],
      isSearchable: false,
      onGroupChange: ({
        values,
        subValues,
        isPartial,
      }: {
        values: Array<string>;
        subValues: Array<string>;
        isPartial?: boolean;
      }) => {
        onChangeGroupedFilter({
          filter: {
            opportunity_types: values,
            touchpointable_kinds: subValues,
          },
          isPartial: !!isPartial,
        });
      },
      isDisabled: false,
      showFilter: true,
      isGroupedMultiSelect: true,
    },
    {
      title: 'Location preference',
      options: countries,
      subOptions: cityCountryList.map((item) => ({
        id: item.id,
        label: item.label,
        value: item.id,
        parent_id: item.country_id,
      })),
      values: filters?.['country_ids'],
      partialValues: partialFilters?.['country_ids'],
      subValues: filters?.['city_ids'],
      partialSubValues: partialFilters?.['city_ids'],
      isSearchable: true,
      onGroupChange: ({
        values,
        subValues,
        isPartial,
      }: {
        values: Array<string>;
        subValues: Array<string>;
        isPartial?: boolean;
      }) => {
        onChangeGroupedFilter({
          filter: { country_ids: values, city_ids: subValues },
          isPartial: !!isPartial,
        });
      },
      isDisabled: false,
      showFilter: true,
      isGroupedMultiSelect: true,
    },
    {
      title: 'Role preference',
      options: areaOfResponsibilities,
      values: filters?.['area_of_responsibility_ids'] || [],
      partialValues: partialFilters?.['area_of_responsibility_ids'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('area_of_responsibility_ids', values, isPartial);
      },
      isDisabled: false,
      showFilter: true,
    },
    {
      title: 'Industry preference',
      options: updatedIndustryOptions,
      values: filters?.['industry_ids'] || [],
      partialValues: partialFilters?.['industry_ids'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('industry_ids', values, isPartial);
      },
      isDisabled: false,
      showFilter: true,
    },
    {
      title: 'Language',
      options: languageOptions,
      values: filters?.['language_ids'] || [],
      partialValues: partialFilters?.['language_ids'],
      isSearchable: true,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('language_ids', values, isPartial);
      },
      isDisabled: false,
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Work eligibility',
      options: countries,
      values: filters?.['visa_country_ids'] || [],
      partialValues: partialFilters?.['visa_country_ids'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('visa_country_ids', values, isPartial);
      },
      isDisabled: false,
      tooltipContent:
        'These are people who have confirmed that they have work authorization in the countries below.',
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Gender',
      options: genderOptions,
      values: filters?.['genders'] || [],
      partialValues: partialFilters?.['genders'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('genders', values, isPartial);
      },
      isDisabled: isMultiselectDisabled,
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Ethnicity',
      options: ethnicityOptions,
      values: filters?.['ethnicity_ids'] || [],
      partialValues: partialFilters?.['ethnicity_ids'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('ethnicity_ids', values, isPartial);
      },
      isDisabled: isMultiselectDisabled,
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Disability status',
      options: disabilityFilterOptions,
      values: filters?.['disabilityOptions'] || [],
      partialValues: partialFilters?.['disabilityOptions'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('disabilityOptions', values, isPartial);
      },
      isDisabled: isMultiselectDisabled,
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Socioeconomic status',
      options: socioEconomicStatusFilterOptions,
      values: filters?.['socio_economic_status'] || [],
      partialValues: partialFilters?.['socio_economic_status'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        onChangeFilter('socio_economic_status', values, isPartial);
      },
      isDisabled: isMultiselectDisabled,
      showFilter: isRecruiter ? true : false,
    },
    {
      title: 'Other',
      options: otherOptions,
      values: filters?.['cv_available'] || [],
      partialValues: partialFilters?.['cv_available'],
      isSearchable: false,
      onChange: (values: Array<string>, isPartial?: boolean) => {
        const value = values?.includes('cv_available');
        onChangeFilter(
          'cv_available',
          value ? ['cv_available'] : [],
          isPartial
        );
      },
      isDisabled: false,
      showFilter: true,
    },
  ];

  const handlePrevClick = () => {
    if (filtersListRef.current) {
      const { current } = filtersListRef;

      const newScrollPosition = Math.max(
        0,
        current.scrollLeft - current.clientWidth
      );
      current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleNextClick = () => {
    if (filtersListRef.current) {
      const { current } = filtersListRef;

      const newScrollPosition =
        current.scrollWidth - (current.scrollLeft + current.clientWidth) < 50
          ? current.scrollWidth - current.clientWidth
          : current.scrollLeft + current.clientWidth - 50;
      current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  const assignRefs = ({
    el,
    isSearchable,
  }: {
    el: MultiRefs | null;
    isSearchable: boolean;
  }) => {
    if (!el || !el.buttonRef?.current || !el.listRef?.current) return;
    const filterContainerPadding = isInvitePopup ? 0 : 48;
    const listOptionsMaxHeight = 292;

    const windowDistanceBoundingClientRect =
      el.buttonRef.current.getBoundingClientRect();
    const parentDistanceBoundingClientRect =
      el.buttonRef.current.offsetParent?.getBoundingClientRect();
    if (!windowDistanceBoundingClientRect || !parentDistanceBoundingClientRect)
      return;

    const listRefLeftDistance =
      windowDistanceBoundingClientRect.left -
      parentDistanceBoundingClientRect.left;
    const listRefWidth = el.listRef.current.offsetWidth;
    const buttonRefWidth = el.buttonRef.current.offsetWidth;
    // 300 is the height of the footer, button and space bottom the modal
    const listRefMaxHeight =
      window.innerHeight -
      parentDistanceBoundingClientRect.top -
      (isSearchable ? 300 : 240);

    if (
      listRefWidth + listRefLeftDistance >
      parentDistanceBoundingClientRect.width
    ) {
      const rightDistance =
        parentDistanceBoundingClientRect.width -
        listRefLeftDistance -
        buttonRefWidth;
      el.listRef.current.style.right =
        rightDistance > filterContainerPadding
          ? `${rightDistance}px`
          : `${filterContainerPadding}px`;
    } else {
      el.listRef.current.style.left =
        listRefLeftDistance > filterContainerPadding
          ? `${listRefLeftDistance}px`
          : `${filterContainerPadding}px`;
    }
    try {
      const listElement = el.listRef.current.getElementsByTagName('ul')[0];
      if (
        isInvitePopup &&
        listElement &&
        listRefMaxHeight < listOptionsMaxHeight
      ) {
        el.listRef.current.getElementsByTagName(
          'ul'
        )[0].style.maxHeight = `${listRefMaxHeight}px`;
      }
      if (listRefWidth) {
        el.listRef.current.style.width = `${listRefWidth}px`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderFilterList = useMemo(() => {
    return filtersList
      .filter((item) => item && item.showFilter)
      .map((item, index) => {
        if (!item) return null;

        const {
          title,
          options,
          values = [],
          onChange,
          onGroupChange,
          isSearchable,
          isDisabled,
          isGroupedMultiSelect,
          subOptions,
          subValues = [],
          partialValues,
          partialSubValues,
          showSocietyUniversityFilter,
        } = item;

        if (showSocietyUniversityFilter) {
          return (
            <SocietyUniversityFilter
              key={title}
              title={title}
              options={options}
              values={values}
              onChange={onChange}
              ref={(el) => assignRefs({ el, isSearchable })}
              partialCount={partialCount}
              isPartialLoading={isPartialLoading}
              partialValues={partialValues as string[]}
              onPartialChange={(values) =>
                onChange ? onChange(values as string[], true) : noop
              }
              setNestedFilterParams={item.setNestedFilterParams}
              onFetchMoreData={item.fetchMoreData}
              showLoader={item.showLoader}
              isSocietyFilter={item.isSocietyFilter}
            />
          );
        }

        return isDisabled ? (
          <Tooltip
            key={index}
            content={'Select at least 1 other non-demographic filter first'}
            color="dark"
            position={isScroll || !isTalentSource ? 'bottom' : 'top'}
            containerClassName={styles.tooltipContainer}
            className={cn(styles.tooltip, {
              [styles.tooltipScrolled]: isScroll,
            })}
          >
            <Row
              wrap={false}
              columnGap={8}
              align="center"
              className={cn(
                styles.ToolFiltersMultiselect,
                styles.ToolFiltersMultiselectDisabled
              )}
            >
              <p>{title}</p>
              <Icon iconName="icon_arrow-down" size="xsmall" />
            </Row>
          </Tooltip>
        ) : isGroupedMultiSelect ? (
          <GroupedMultiSelect
            key={title}
            ref={(el) => assignRefs({ el, isSearchable })}
            placeholder={tc('search')}
            className={styles['multi-select-wrapper']}
            dropdownClassName={styles['multi-select-dropdown']}
            triggerClassName={styles.ToolFiltersMultiselectOpen}
            showCountWithLabel
            changeOnShow
            showSingleValueLabel
            title={title}
            options={options}
            subOptions={subOptions}
            values={values}
            subValues={subValues}
            partialValues={partialValues}
            partialSubValues={partialSubValues}
            onChange={onGroupChange}
            onPartialChange={({ values, subValues }) =>
              onGroupChange({
                values: values as string[],
                subValues: subValues as string[],
                isPartial: true,
              })
            }
            isSearchable={isSearchable}
            partialCount={partialCount}
            partialLoading={isPartialLoading}
          />
        ) : (
          <Tooltip
            key={index}
            content={item?.tooltipContent}
            disabled={!item?.tooltipContent}
            color="dark"
            position={isScroll || !isTalentSource ? 'bottom' : 'top'}
            containerClassName={styles.tooltipContainer}
            contentPositionClassName={styles['tooltip-container']}
            className={cn(styles.tooltip, {
              [styles.tooltipScrolled]: isScroll,
            })}
          >
            <MultiSelect
              key={title}
              title={title}
              placeholder={tc('search')}
              options={options as Array<Option>}
              className={styles['multi-select-wrapper']}
              dropdownClassName={styles['multi-select-dropdown']}
              alignment="static"
              values={values}
              onChange={onChange ? onChange : noop}
              buttonTitleClassName={styles.ToolFiltersMultiselectButtonTitle}
              triggerOpenClassName={styles.ToolFiltersMultiselectOpen}
              triggerClassName={styles['multiselect-button']}
              isSearchable={isSearchable}
              ref={(el) => assignRefs({ el, isSearchable })}
              optionLabelFirst
              showCountWithLabel
              changeOnShow
              showSingleValueLabel
              isEllipsisLabel={false}
              showCount={false}
              showClearButton={false}
              partialCount={partialCount}
              partialLoading={isPartialLoading}
              partialValues={partialValues as string[]}
              onPartialChange={(values) =>
                onChange ? onChange(values as string[], true) : noop
              }
            />
          </Tooltip>
        );
      });
  }, [filtersList, assignRefs, isMultiselectDisabled]);

  return (
    <div className={styles.ToolFiltersWrapper}>
      <div
        className={cn(styles.ToolFilters, className, {
          [styles.ToolFiltersScrolled]: isScroll,
          [styles.ToolFiltersHidden]: !open,
        })}
      >
        {showPrevArrow ? (
          <div
            className={cn(
              styles.ToolFiltersSlideShowNavigation,
              styles.ToolFiltersSlideShowNavigationLeft,
              {
                [styles.ToolFiltersSlideShowNavigationLeftScrolled]: isScroll,
              }
            )}
            style={{
              left: scrollPosition ? `${scrollPosition}` : undefined,
            }}
          >
            <IconButton
              iconName="chevron-left"
              size={screens.sm ? 'large' : 'xlarge'}
              onClick={handlePrevClick}
              className={styles.ToolFiltersSlideShowNavigationButton}
              type="button"
            />
          </div>
        ) : null}
        <div
          className={cn(styles.ToolFiltersList, {
            [styles.ToolFiltersListScrolled]: isScroll,
          })}
          ref={filtersListRef}
        >
          {renderFilterList}
        </div>

        {showNextArrow ? (
          <div
            className={cn(
              styles.ToolFiltersSlideShowNavigation,
              styles.ToolFiltersSlideShowNavigationRight,
              {
                [styles.ToolFiltersSlideShowNavigationRightScrolled]: isScroll,
              }
            )}
            style={{
              right: scrollPosition ? `${scrollPosition}` : undefined,
            }}
          >
            <IconButton
              size={screens.sm ? 'large' : 'xlarge'}
              iconName="chevron-right"
              onClick={handleNextClick}
              className={styles.ToolFiltersSlideShowNavigationButton}
              type="button"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ToolFilters;
