import React, { memo, useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import ActionMenu from 'components/ActionMenu';
import { ButtonV3 } from 'components/ComponentV2';
import { PAGE_ROUTES } from 'lib/page-routes';
import { AddLinkTreeModal } from './AddLinkTreeModal/AddLinkTreeModal.component';

import styles from './AddExternalLinks.module.scss';

interface AddExternalLinksProps {
  buttonColor?: 'primary' | 'secondary';
  className?: string;
  setShouldAllowPopoverClose?: (shouldAllow: boolean) => void;
}

export const AddExternalLinks: React.FC<AddExternalLinksProps> = memo(
  (props: AddExternalLinksProps) => {
    const {
      buttonColor = 'primary',
      className: classes,
      setShouldAllowPopoverClose,
    } = props;
    const router = useRouter();

    const [showLinkTreeModal, setShowLinkTreeModal] = useState<boolean>(false);

    const toggleLinkTreeModal = () => {
      setShowLinkTreeModal(!showLinkTreeModal);
    };

    useEffect(() => {
      if (showLinkTreeModal) {
        setShouldAllowPopoverClose && setShouldAllowPopoverClose(false);
      } else {
        setShouldAllowPopoverClose && setShouldAllowPopoverClose(true);
      }
    }, [showLinkTreeModal]);

    const actionMenuOptions = useMemo(() => {
      const externalLinksOptions = [
        {
          id: '1',
          label: 'Add manually',
          onClick: () =>
            router.push(
              {
                pathname: PAGE_ROUTES.SOCIETY_PROFILE_EDIT,
                query: {
                  id: 'links',
                },
              },
              PAGE_ROUTES.SOCIETY_PROFILE_EDIT
            ),
        },
        {
          id: '2',
          label: 'Import instantly from Linktree',
          onClick: toggleLinkTreeModal,
        },
      ];
      return externalLinksOptions;
    }, []);

    return (
      <>
        <ActionMenu
          className={cn(styles.AddExternalLinks, classes)}
          options={actionMenuOptions}
          actionElement={
            <ButtonV3
              endIcon="icon_arrow-down"
              color={buttonColor}
              className={styles.externalLinksCta}
            >
              Add external links
            </ButtonV3>
          }
          listClassName={styles.AddExternalLinksMenu}
        />
        <AddLinkTreeModal
          open={showLinkTreeModal}
          onClose={toggleLinkTreeModal}
        />
      </>
    );
  }
);

AddExternalLinks.displayName = 'AddExternalLinks';
