import React, {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { noop } from 'lodash';

import { Icon, IconButton, Spinner } from 'components';
import { Checkbox, Input } from 'components/form';
import { ButtonV3 } from 'components/ComponentV2';
import useDebounce from 'hooks/useDebounce';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { useCommonList } from 'lib/contexts/common-list-context';
import { Option } from 'lib/models/option';
import { formatToK } from 'utils/format';

import styles from './SocietyUniversityFilter.module.scss';

export interface SocietyUniversityFilterProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  title: string;
  options?: Array<Option>;
  values: Array<string>;
  onChange: (values: Array<string>) => void;
  partialValues: Array<string> | null;
  onPartialChange: (value?: string[] | null) => void;
  partialCount: number;
  isPartialLoading: boolean;
  onFetchMoreData?: () => void;
  setNestedFilterParams: React.Dispatch<
    React.SetStateAction<{
      search_text?: string;
      university_ids?: string[];
      country_ids?: string[];
      city_ids?: string[];
    }>
  >;
  isSocietyFilter?: boolean;
  showLoader?: boolean;
}

type FilterRefs = {
  listRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

type FilterType =
  | {
      id: string;
      value: string;
      label: string;
      cities?: {
        id: string;
        value: string;
        label: string;
        parent_id: string;
      }[];
    }[]
  | undefined;

const SocietyUniversityFilterComponent: ForwardRefRenderFunction<
  FilterRefs,
  SocietyUniversityFilterProps
> = (props, ref) => {
  const {
    className = '', // custom class name
    style, // custom style
    title,
    options,
    values,
    onChange,
    partialValues,
    onPartialChange,
    partialCount,
    isPartialLoading,
    onFetchMoreData,
    setNestedFilterParams,
    isSocietyFilter,
    showLoader,
  } = props;
  const [tc] = useTranslation('common');
  const {
    countries = [],
    cityCountryList,
    universitiesOptions,
    isUniversitiesDataLoading,
    fetchMoreUniversities,
    setUniversityFilterParams,
    setSocietyIds,
    societyIdsData,
    setUniversityId,
    universityIdData,
  } = useCommonList();

  const cityList = useMemo(() => {
    return cityCountryList.map((item) => ({
      id: item.id,
      value: item.id,
      label: item.label,
      parent_id: item.country_id,
    }));
  }, [cityCountryList]);

  const locationList = useMemo(() => {
    return countries.map(({ id, value, label }) => {
      const subOptions = cityList.filter((item) => item.parent_id === id);
      return {
        id,
        value,
        label,
        cities: subOptions,
      };
    });
  }, [countries, cityList]);

  const SocietyUniversityFilterRef = useRef(null);
  const bottomListRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    listRef: listRef,
    buttonRef: buttonRef,
  }));

  // search
  const [search, setSearch] = useState<string>('');
  const trimmedSearch = useMemo(() => search.trim(), [search]);
  const debouncedSearch = useDebounce(trimmedSearch, 500) as string;

  // Dropdown
  const [open, setOpen] = useState(false);
  const selectedValues = useMemo(() => {
    return typeof partialValues === 'undefined' ? values : partialValues || [];
  }, [values, partialValues]);

  const handleDropdownToggle = () => {
    if (open) {
      onClose();
    } else {
      setOpen(true);
    }
  };

  // Location
  const [openLocationFilter, setOpenLocationFilter] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<Array<string>>([]);
  const [selectedCities, setSelectedCities] = useState<Array<string>>([]);

  const handleCityToggle = () => {
    closeCurrentFilter();
    if (!openLocationFilter) {
      setOpenLocationFilter(true);
    }
  };

  // University
  const [openUniversityFilter, setOpenUniversityFilter] = useState(false);
  const [selectedUniversities, setSelectedUniversities] = useState<
    Array<string>
  >([]);

  const handleUniversityToggle = () => {
    closeCurrentFilter();
    if (!openUniversityFilter) {
      setOpenUniversityFilter(true);
    }
  };

  const closeCurrentFilter = () => {
    setSearch('');
    if (openLocationFilter) setOpenLocationFilter(false);
    if (openUniversityFilter) setOpenUniversityFilter(false);
  };

  const isNestedFilterOpen = useMemo(() => {
    if (openLocationFilter || openUniversityFilter) {
      return true;
    } else return false;
  }, [openLocationFilter, openUniversityFilter]);

  // functions for options
  const filteredOptions: FilterType = useMemo(() => {
    if (trimmedSearch) {
      if (openLocationFilter) {
        const filteredCountriesId: string[] = [];
        countries.map((item) => {
          if (item.label.toLocaleLowerCase().includes(trimmedSearch)) {
            filteredCountriesId.push(item.id);
          }
          return;
        });
        const filteredCityList = cityList.filter((item) => {
          if (item.label.toLocaleLowerCase().includes(trimmedSearch)) {
            filteredCountriesId.push(item.parent_id);
            return true;
          } else return false;
        });

        return countries
          .map(({ id, value, label }) => {
            if (filteredCountriesId.includes(id)) {
              const subOptions = filteredCityList.filter(
                (item) => item.parent_id === id
              );
              return {
                id,
                value,
                label,
                cities: subOptions,
              };
            } else return [];
          })
          .flat();
      } else if (openUniversityFilter) {
        return universitiesOptions?.filter((item) =>
          item.label.toLocaleLowerCase().includes(trimmedSearch)
        );
      } else
        return options?.filter((item) =>
          item.label.toLocaleLowerCase().includes(trimmedSearch)
        );
    } else {
      if (openLocationFilter) {
        return locationList;
      } else if (openUniversityFilter) {
        return universitiesOptions;
      } else return options;
    }
  }, [
    trimmedSearch,
    options,
    countries,
    cityList,
    locationList,
    cityCountryList,
    universitiesOptions,
    openLocationFilter,
    openUniversityFilter,
  ]);

  const isOptionChecked = (value: string) => {
    if (openLocationFilter) {
      return (
        selectedCities.includes(value) || selectedCountries.includes(value)
      );
    } else if (openUniversityFilter) {
      return selectedUniversities.includes(value);
    } else return selectedValues.includes(value);
  };

  const toggleSelectOption = (value: string) => {
    if (openLocationFilter) {
      const cityIds = cityList
        .filter(({ parent_id }) => value === parent_id)
        .map(({ id }) => id);
      const newSelectedCities = selectedCities.filter(
        (item) => !cityIds.includes(item)
      );

      if (selectedCountries.includes(value)) {
        const values = selectedCountries.filter((item) => item !== value);
        setSelectedCountries(values);
        setSelectedCities(newSelectedCities);
      } else {
        if (selectedCities.filter((item) => cityIds.includes(item)).length) {
          setSelectedCities(newSelectedCities);
        } else {
          setSelectedCountries((oldValues) => [...oldValues, value]);
        }
      }
    } else if (openUniversityFilter) {
      if (selectedUniversities.includes(value)) {
        const values = selectedUniversities.filter((item) => item !== value);
        setSelectedUniversities(values);
      } else setSelectedUniversities((oldValues) => [...oldValues, value]);
    } else {
      const newValues = selectedValues.includes(value)
        ? selectedValues.filter((item) => item !== value)
        : [...selectedValues, value];
      onPartialChange(newValues);
    }
  };

  const toggleSelectSubOption = (value: string) => {
    let newSelectedCities: string[];
    const selectedCity = cityList.filter(({ id }) => id === value);
    const parentCountryId = selectedCity[0].parent_id;
    const allCitiesInParentCountry = cityList
      .filter(({ parent_id }) => parent_id === parentCountryId)
      .map(({ id }) => id);

    if (selectedCities.includes(value)) {
      newSelectedCities = selectedCities.filter((item) => item !== value);
      setSelectedCities(newSelectedCities);
    } else if (selectedCountries.includes(parentCountryId)) {
      const values = selectedCountries.filter(
        (item) => item !== parentCountryId
      );
      setSelectedCountries(values);
      newSelectedCities = allCitiesInParentCountry.filter(
        (item) => item !== value
      );
      setSelectedCities(newSelectedCities);
    } else {
      newSelectedCities = [...selectedCities, value];
      if (
        allCitiesInParentCountry.every((item) =>
          newSelectedCities.includes(item)
        )
      ) {
        setSelectedCities((oldValues) =>
          oldValues.filter((item) => !allCitiesInParentCountry.includes(item))
        );
        setSelectedCountries((oldValues) => [...oldValues, parentCountryId]);
      } else {
        setSelectedCities(newSelectedCities);
      }
    }
  };

  const selectedOptionsLength = useMemo(() => {
    if (openLocationFilter) {
      return selectedCities.length + selectedCountries.length;
    } else if (openUniversityFilter) {
      return selectedUniversities.length;
    } else return selectedValues.length;
  }, [
    selectedCountries,
    selectedCities,
    selectedUniversities,
    selectedValues,
    openLocationFilter,
    openUniversityFilter,
  ]);

  const [buttonSingleValueLabel, setButtonSingleValueLabel] =
    useState<string>(title);

  useEffect(() => {
    if (values.length !== 1) return;

    if (isSocietyFilter) {
      setSocietyIds(values);
    } else {
      setUniversityId(values[0]);
    }

    setButtonSingleValueLabel(title);
    if (isSocietyFilter && societyIdsData.length) {
      setButtonSingleValueLabel(societyIdsData[0].label);
    } else if (!isSocietyFilter && universityIdData.length) {
      setButtonSingleValueLabel(universityIdData[0].label);
    }
  }, [values, societyIdsData, universityIdData]);

  // to fetch more data
  useVisibleOnIntersect({
    elRef: bottomListRef,
    threshold: 0,
    onIntersect: () =>
      isNestedFilterOpen
        ? openUniversityFilter &&
          fetchMoreUniversities &&
          fetchMoreUniversities()
        : onFetchMoreData && onFetchMoreData(),
    rootMargin: '50px',
  });

  // to apply selected nested filters
  useEffect(() => {
    if (isNestedFilterOpen) return;

    setNestedFilterParams({
      university_ids: selectedUniversities,
      country_ids: selectedCountries,
      city_ids: selectedCities,
    });
  }, [
    isNestedFilterOpen,
    selectedUniversities,
    selectedCountries,
    selectedCities,
  ]);

  // to send API request for searched query
  const onSearch = () => {
    if (openUniversityFilter) {
      setUniversityFilterParams({
        search_text: debouncedSearch,
      });
    }
    if (isNestedFilterOpen) return;

    setNestedFilterParams({
      search_text: debouncedSearch,
      university_ids: selectedUniversities,
      country_ids: selectedCountries,
      city_ids: selectedCities,
    });
  };

  useEffect(() => {
    onSearch();
  }, [debouncedSearch]);

  // functions for button
  const onReset = () => {
    if (openLocationFilter) {
      setSelectedCountries([]);
      setSelectedCities([]);
    } else if (openUniversityFilter) {
      setSelectedUniversities([]);
    } else {
      onPartialChange(null);
      setSelectedCountries([]);
      setSelectedCities([]);
      setSelectedUniversities([]);
    }
  };

  const onClose = () => {
    setOpen(false);
    closeCurrentFilter();
    setSelectedCountries([]);
    setSelectedCities([]);
    setSelectedUniversities([]);
    setNestedFilterParams({
      search_text: '',
      university_ids: [],
      country_ids: [],
      city_ids: [],
    });
    onPartialChange();
  };

  const onShow = () => {
    onChange(selectedValues);
    onClose();
  };

  const showOk = useMemo(() => {
    if (partialValues?.length) {
      return false;
    } else if (values.length && partialValues === undefined) {
      return false;
    } else return true;
  }, [partialValues]);

  useOnClickOutside({
    ref: SocietyUniversityFilterRef,
    onOutsideClick: open ? () => onShow() : noop,
  });

  // to expand city list
  const [expandedOptionId, setExpandedOptionId] = useState<string | null>(null);
  const onToggleSubOptions = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: string
  ) => {
    event.stopPropagation();
    setExpandedOptionId((prevId) => (prevId === id ? null : id));
  };

  // to change placeholder
  const placeholder = useMemo(() => {
    return openLocationFilter
      ? `${tc('search')} ${tc('filterable-fields.location')}`
      : openUniversityFilter || !isSocietyFilter
      ? `${tc('search')} ${tc('university')}`
      : `${tc('search')} ${tc('society')}`;
  }, [openLocationFilter, openUniversityFilter, isSocietyFilter]);

  return (
    <section
      className={cn(styles.filter, className)}
      style={style}
      data-testid="SocietyUniversityFilter"
      ref={SocietyUniversityFilterRef}
    >
      {/* filter button */}
      <button
        className={cn(styles.trigger, {
          [styles['trigger-selected']]: values.length,
        })}
        onClick={handleDropdownToggle}
        type="button"
        ref={buttonRef}
      >
        <p>
          {values.length === 1
            ? buttonSingleValueLabel
            : values.length > 1
            ? `${title} (${values.length})`
            : title}
        </p>
        <Icon
          iconName="icon_arrow-down"
          size="xsmall"
          className={cn(styles['dropdown-icon'], {
            [styles['dropdown-icon-reverse']]: open,
          })}
        />
      </button>
      {/* dropdown */}
      {open ? (
        <div className={styles.dropdown} ref={listRef}>
          {/* searchbar */}
          <div className={styles['dropdown-search']}>
            <Input
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              className={styles['search-input']}
              startIconClassName={styles['search-icon']}
              placeholder={placeholder}
              startIcon="search"
              startIconSize="small"
              endIconSize="xsmall"
              endIcon={search ? 'cancel' : undefined}
              onEndIconClick={() => setSearch('')}
              autoFocus={true}
            />
          </div>

          {/* nested filters */}
          <div className={styles['nested-filters']}>
            {/* Location filter */}
            <button
              className={cn(styles.trigger, {
                [styles['trigger-open']]: openLocationFilter,
                [styles['trigger-selected']]:
                  selectedCities.length || selectedCountries.length,
              })}
              onClick={handleCityToggle}
              type="button"
            >
              <p>
                {selectedCities.length + selectedCountries.length > 0
                  ? `Location (${
                      selectedCities.length + selectedCountries.length
                    })`
                  : 'Location'}
              </p>
              <Icon
                iconName="icon_arrow-down"
                size="xxsmallplus"
                className={cn(styles['dropdown-icon'], {
                  [styles['dropdown-icon-reverse']]: openLocationFilter,
                })}
              />
            </button>
            {/* University Filter */}
            {isSocietyFilter ? (
              <button
                className={cn(styles.trigger, {
                  [styles['trigger-open']]: openUniversityFilter,
                  [styles['trigger-selected']]: selectedUniversities.length,
                })}
                onClick={handleUniversityToggle}
                type="button"
              >
                <p>
                  {selectedUniversities.length > 0
                    ? `${tc('university')} (${selectedUniversities.length})`
                    : tc('university')}
                </p>
                <Icon
                  iconName="icon_arrow-down"
                  size="xxsmallplus"
                  className={cn(styles['dropdown-icon'], {
                    [styles['dropdown-icon-reverse']]: openUniversityFilter,
                  })}
                />
              </button>
            ) : null}
          </div>

          {/* options */}
          <ul className={styles['dropdown-options']}>
            {filteredOptions?.map(({ id, label, cities }) => {
              const checked = isOptionChecked(id);
              const partialChecked = cities?.filter((item) =>
                selectedCities.includes(item.id)
              );
              return (
                <Fragment key={id}>
                  <li
                    key={id}
                    className={cn(styles['dropdown-option'], {
                      [styles['dropdown-option-expanded']]:
                        expandedOptionId === id,
                    })}
                    onClick={() => toggleSelectOption(id)}
                  >
                    {cities?.length ? (
                      <IconButton
                        iconName="icon_arrow-down"
                        size="xsmall"
                        onClick={(event) => onToggleSubOptions(event, id)}
                        className={cn(styles['option-expand-icon'], {
                          [styles['expanded-icon']]: expandedOptionId === id,
                        })}
                      />
                    ) : null}
                    <Checkbox
                      className={cn(styles['checkbox'], {
                        [styles['has-cities']]: cities,
                        [styles['no-filtered-cities']]:
                          cities && cities.length === 0,
                      })}
                      labelFirst={true}
                      readOnly
                      checked={checked}
                      halfChecked={!!partialChecked?.length}
                    >
                      {label}
                    </Checkbox>
                  </li>
                  {expandedOptionId === id &&
                    cities?.map((item) => {
                      const subOptionChecked = isOptionChecked(item.id);
                      return (
                        <li
                          key={item.id}
                          className={cn(
                            styles['dropdown-option'],
                            styles['dropdown-sub-option'],
                            {
                              [styles['last-sub-option']]:
                                cities[cities.length - 1].id === item.id,
                            }
                          )}
                          onClick={() => toggleSelectSubOption(item.id)}
                        >
                          <Checkbox
                            className={styles['checkbox']}
                            labelFirst={true}
                            readOnly
                            checked={subOptionChecked || checked}
                          >
                            {item.label}
                          </Checkbox>
                        </li>
                      );
                    })}
                </Fragment>
              );
            })}
            {onFetchMoreData && (
              <div ref={bottomListRef}>
                {((!isNestedFilterOpen && showLoader) ||
                  (openUniversityFilter && isUniversitiesDataLoading)) &&
                  typeof filteredOptions !== 'undefined' && (
                    <Spinner className={styles.spinner} size="small" />
                  )}
              </div>
            )}
          </ul>

          {!(showLoader || isUniversitiesDataLoading) &&
          filteredOptions?.length === 0 ? (
            <p className={styles.noResults}>{tc('no-results-found')}</p>
          ) : null}
          {typeof filteredOptions === 'undefined' && (
            <Spinner className={styles.spinner} size="small" />
          )}

          {/* footer */}
          <footer className={styles['dropdown-footer']}>
            {selectedOptionsLength ? (
              <ButtonV3 color="secondary" size="small" onClick={onReset}>
                {tc('reset')}
              </ButtonV3>
            ) : (
              <ButtonV3
                color="secondary"
                size="small"
                onClick={
                  isNestedFilterOpen
                    ? () => closeCurrentFilter()
                    : () => onClose()
                }
              >
                {tc('cancel')}
              </ButtonV3>
            )}
            <ButtonV3
              size="small"
              className={styles['show-button']}
              onClick={
                isNestedFilterOpen ? () => closeCurrentFilter() : () => onShow()
              }
              isLoading={isPartialLoading}
            >
              {isNestedFilterOpen || showOk
                ? tc('OK')
                : `${tc('show')} ${formatToK(partialCount)}`}
            </ButtonV3>
          </footer>
        </div>
      ) : null}
    </section>
  );
};

export const SocietyUniversityFilter = memo(
  forwardRef(SocietyUniversityFilterComponent)
);

SocietyUniversityFilter.displayName = 'SocietyUniversityFilter';
