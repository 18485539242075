import React, { memo } from 'react';
import cn from 'classnames';

import styles from './ProgressBar.module.scss';

export interface ProgressBarProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children */
  children?: React.ReactNode;
  /** percent completed */
  percentCompleted: number;
  color: 'Primary' | 'White' | 'Yellow';
  height?: string;
  showProgressWhen0?: boolean;
}

export const ProgressBar: React.FC<ProgressBarProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    percentCompleted,
    color,
    height,
    showProgressWhen0 = true,
  }: ProgressBarProps) => {
    const contentClassNames = cn(
      {
        [styles.container]: true,
      },
      className
    );

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="ProgressBar"
      >
        <div
          className={cn(
            styles.progress,
            { [styles.progressWhite]: color === 'White' },
            { [styles.progressPrimary]: color === 'Primary' },
            { [styles.progressYellow]: color === 'Yellow' }
          )}
          style={{
            width:
              percentCompleted > 0
                ? `${percentCompleted}%`
                : showProgressWhen0
                ? `20px`
                : `0`,
            height,
          }}
        ></div>
      </div>
    );
  }
);

ProgressBar.displayName = 'ProgressBar';
