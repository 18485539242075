import React, { memo, Dispatch, SetStateAction, useState, useEffect, useMemo, Fragment } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

import { IconButton } from 'components';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { Row, Col } from 'components/layout';
import { Avatar } from 'components/Avatar/Avatar.component';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { useCommon } from 'hooks/useCommon';
import { useChatRoom } from 'hooks/useChatRoom';
import { SidebarOption } from 'hooks/useToolFilters';
import { TalentPoolAttributes } from 'lib/models/talent-pool';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { ApplicationAttributes } from 'lib/models/application';
import { InviteCandidate } from 'lib/models/candidate';

import styles from './CandidateList.module.scss';

export interface CandidateListProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  talentPool: Array<TalentPoolAttributes | ApplicationAttributes>;
  candidateSelected: Array<InviteCandidate>;
  setCandidateSelected: Dispatch<SetStateAction<Array<InviteCandidate>>>;
  inviteLimit: number;
  onModalResolve: () => void;
  sidebarOptionSelected: SidebarOption;
  showPopover?: boolean;
  showCheckboxonExplore?: boolean;
}

export const CandidateList: React.FC<CandidateListProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    talentPool,
    candidateSelected,
    setCandidateSelected,
    inviteLimit,
    onModalResolve,
    sidebarOptionSelected,
    showPopover = true,
    showCheckboxonExplore,
  }: CandidateListProps) => {
    const contentClassNames = cn(
      {
        [styles.CandidateList]: true,
      },
      className
    );

    const [t] = useTranslation('invite-popup');
    const { onShare } = useCommon();
    const { createMessageChat } = useChatRoom();

    const [isSelectionDisabled, setSelectionDisabled] = useState(false);

    /** Checking if the limit for the particular option has been reached*/
    const noOfCandidateSelectedForTheOption = useMemo(() => {
      return candidateSelected.filter((item) => item.sidebarOptionSelected === sidebarOptionSelected).length;
    }, [candidateSelected, sidebarOptionSelected]);

    const onCandidateSelect = ({ id, name, email, avatar_url }: { id: string; name: string; email: string; avatar_url: string }) => {
      const index = candidateSelected?.findIndex((candidate) => candidate.id === id);

      if (
        index === -1 &&
        ((noOfCandidateSelectedForTheOption < inviteLimit && showCheckboxonExplore) ||
          (sidebarOptionSelected !== SidebarOption.EXPLORE_HUZZLE && sidebarOptionSelected !== SidebarOption.COMMUNITY))
      ) {
        setCandidateSelected([
          ...candidateSelected,
          {
            id,
            name,
            email,
            avatar_url,
            sidebarOptionSelected,
          },
        ]);
      } else {
        setCandidateSelected(candidateSelected && candidateSelected.filter((item) => item.id !== id));
      }
    };

    useEffect(() => {
      if ((noOfCandidateSelectedForTheOption === inviteLimit || inviteLimit < 1) && sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE) {
        setSelectionDisabled(true);
      } else {
        setSelectionDisabled(false);
      }
    }, [candidateSelected, sidebarOptionSelected, noOfCandidateSelectedForTheOption, inviteLimit]);

    function calculatePreferences(pref: Array<string>, totalWidth: number) {
      const labelPadding = 2 + 4 * 2; // Padding on both sides + columngap
      const labelsToFit = [];
      let remainingWidth = totalWidth;

      for (const preference of pref) {
        const labelWidth = preference.length * 8 + labelPadding; // Assuming each character is roughly 8 pixels wide
        if (labelWidth <= remainingWidth) {
          labelsToFit.push(preference);
          remainingWidth -= labelWidth;
        } else {
          break;
        }
      }

      if (labelsToFit.length < pref.length) {
        const remainingPrefs = pref.length - labelsToFit.length;
        labelsToFit.push(`+${remainingPrefs}`);
      }

      return labelsToFit;
    }

    const getPreferenceRow = (pref: Array<string>) => {
      const totalWidth = 350; //total content area on the popover
      const fittedPreferences = calculatePreferences(pref, totalWidth);
      return fittedPreferences.map((item, index) => {
        return (
          <div key={index} className={styles.prefChip}>
            {item}
          </div>
        );
      });
    };

    const getPopoverCandidateDetail = (candidate: TalentPoolAttributes | ApplicationAttributes) => {
      const { id, name, avatar_url, linkedin_url, education_histories: educationHistoriesResponse, industries: industriesResponse } = candidate;

      const education_histories = parseArrayResponse(educationHistoriesResponse);

      let universityName, studyYear, degreeType, subjectName;
      if (education_histories.length > 0) {
        const eduDetail = education_histories[0];
        const { current_study_year, university, degree, subject } = eduDetail;
        universityName = parseResponse(university)?.name;
        degreeType = parseResponse(degree)?.name;
        subjectName = parseResponse(subject)?.name;
        studyYear = current_study_year;
      }

      const industries = parseArrayResponse(industriesResponse).map((item) => item.name);

      return (
        <div className={styles['popover-candidate']}>
          <Row align="center" justify="space-between" wrap={false} columnGap={12} className={styles.content}>
            <Col className={styles.title}>
              <Avatar icon={avatar_url} name={name} size="large" />
              <p className={styles.name}>{name}</p>
            </Col>
            <Col className={styles.iconRow}>
              {linkedin_url ? (
                <IconButton
                  iconName="linkedin"
                  onClick={() => {
                    onShare('LinkedIn profile copied', `${window.location.origin}${linkedin_url}`, {
                      title: 'Link copied',
                      message: 'LinkedIn profile of candidate copied to clipboard successfully!!',
                    });
                  }}
                />
              ) : null}
              <IconButton
                iconName="icon_send-message"
                onClick={() => {
                  createMessageChat(id);
                  onModalResolve && onModalResolve();
                }}
              />
            </Col>
          </Row>
          {universityName ? (
            <Row wrap={false} className={styles.detail}>
              <Col span={12}>
                <p className={styles.heading}>University:</p>
              </Col>
              <Col span={12}>
                <p className={styles.headingDetail}>{universityName}</p>
              </Col>
            </Row>
          ) : null}
          {studyYear ? (
            <Row wrap={false} className={styles.detail}>
              <Col span={12}>
                <p className={styles.heading}>Study Year:</p>
              </Col>
              <Col span={12}>
                <p className={styles.headingDetail}>{studyYear}</p>
              </Col>
            </Row>
          ) : null}
          {degreeType ? (
            <Row wrap={false} className={styles.detail}>
              <Col span={12}>
                <p className={styles.heading}>Degree Type:</p>
              </Col>
              <Col span={12}>
                <p className={styles.headingDetail}>{degreeType}</p>
              </Col>
            </Row>
          ) : null}
          {subjectName ? (
            <Row wrap={false} className={styles.detail}>
              <Col span={12}>
                <p className={styles.heading}>Subject of Study:</p>
              </Col>
              <Col span={12}>
                <p className={styles.headingDetail}>{subjectName}</p>
              </Col>
            </Row>
          ) : null}
          {industries.length > 0 ? (
            <>
              <p className={styles.heading}>Industrial Preference:</p>
              <Row align="center" columnGap={4} wrap={false}>
                {getPreferenceRow(industries)}
              </Row>
            </>
          ) : null}
        </div>
      );
    };

    return (
      <div className={contentClassNames} style={style} data-testid="CandidateList">
        {talentPool.map((candidate) => {
          const { id, name, email, avatar_url, in_touchpoint_applications, invited_for_touchpoint } = candidate;
          const isChecked = candidateSelected?.some((candidate) => id === candidate.id);

          return (
            <Fragment key={id}>
              {in_touchpoint_applications || invited_for_touchpoint ? (
                <Row align="center" justify="space-between" columnGap={12} className={styles['in-event-candidate']}>
                  <Row align="center" columnGap={12} wrap={false}>
                    <Avatar icon={avatar_url} name={name || email} size="small" />
                    <div>{name ? <p className={styles.name}>{name}</p> : <p className={styles.email}>{email}</p>}</div>
                  </Row>
                  <p className={styles['in-event']}>{in_touchpoint_applications ? t('in-event') : t('invited')}</p>
                </Row>
              ) : (
                <SelectionTickMark
                  isTickSquare
                  className={styles.selection}
                  checked={candidateSelected?.some((candidate) => id === candidate.id)}
                  onChange={() => onCandidateSelect({ id, name, email, avatar_url })}
                  unTickClassName={cn({
                    [styles.untickDisabled]: !isChecked && isSelectionDisabled,
                    [styles['hide-check-box']]: sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE && !showCheckboxonExplore,
                  })}
                >
                  <Row align="center" columnGap={12} wrap={false}>
                    <Avatar icon={avatar_url} name={name || email} size="small" />
                    <Tooltip
                      key={id}
                      position="right"
                      yAlign="middle"
                      disabled={!name || in_touchpoint_applications || isSelectionDisabled || invited_for_touchpoint || !showPopover}
                      content={getPopoverCandidateDetail(candidate)}
                      className={styles['popover-candidate-container']}
                      contentPositionClassName={styles['popover-candidate-container-position']}
                    >
                      <div>
                        {name ? <p className={styles.name}>{name}</p> : <p className={styles.email}>{email}</p>}
                        {(sidebarOptionSelected === SidebarOption.PAST_EVENT || sidebarOptionSelected === SidebarOption.EVENT_VIEWERS) && (
                          <p className={styles.email}>{email}</p>
                        )}
                      </div>
                    </Tooltip>
                  </Row>
                  {!isChecked && isSelectionDisabled && showCheckboxonExplore ? (
                    <Tooltip
                      content="You reached the invitation limit."
                      position="left"
                      xAlign="right"
                      color="dark"
                      containerClassName={styles.tooltip}
                      className={styles.tooltipContainer}
                      childrenClassName={styles.tooltipChildren}
                    >
                      <></>
                    </Tooltip>
                  ) : null}
                </SelectionTickMark>
              )}
            </Fragment>
            // </Popover>
          );
        })}
      </div>
    );
  }
);

CandidateList.displayName = 'CandidateList';
