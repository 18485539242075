import React, { memo, useEffect, useMemo, useState, useRef, Dispatch, SetStateAction } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import useSWR from 'swr';
import { Trans, useTranslation } from 'next-i18next';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import { Icon, IconButton, Spinner, Tooltip } from 'components';
import { Row } from 'components/layout';
import { CircularProgressBar } from 'components/CircularProgressBar/CircularProgressBar.component';
import { ButtonV3, DropdownV3 } from 'components/ComponentV2';
import ToolFilters from 'components/ToolFilters';
import { Input } from 'components/form';
import { ProgressBar } from 'components/ComponentV2/ProgressBar/ProgressBar.component';
import CandidateList from 'components/InvitePopup/CandidateList';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { SidebarOption, useToolFilters } from 'hooks/useToolFilters';
import { API_ROUTES } from 'lib/api-routes';
import { apiInstance } from 'lib/utils/axios';
import { useCampaigns } from 'lib/contexts/campaigns-context';
import { PAGE_ROUTES } from 'lib/page-routes';
import { TalentPoolsResponse } from 'lib/models/talent-pool';
import { InviteCandidate } from 'lib/models/candidate';
import { useAuth } from 'lib/providers/AuthProvider';
import { formatToK } from 'utils/format';
import { MatchingPreference } from '../MatchingPreference/MatchingPreference.component';
import InviteViaManualEmail from '../InviteViaManualEmail';

import styles from '../InviteCommunity.module.scss';

const PER_PAGE_COUNT = 20;

export interface InviteCommunityProps {
  /** Custom class name */
  className?: string;
  candidateSelected: Array<InviteCandidate>;
  setCandidateSelected: Dispatch<SetStateAction<Array<InviteCandidate>>>;
  importedCandidateList: Array<InviteCandidate>;
  setImportedCandidateList: Dispatch<SetStateAction<Array<InviteCandidate>>>;
  onClose: () => void;
  onModalResolve: () => void;
  onNextClick: () => void;
}

export const InviteCommunity: React.FC<InviteCommunityProps> = memo(
  ({
    className = '', // custom class name
    candidateSelected,
    setCandidateSelected,
    importedCandidateList,
    setImportedCandidateList,
    onClose,
    onModalResolve,
    onNextClick,
  }: InviteCommunityProps) => {
    const contentClassNames = cn(
      {
        [styles.Invite]: true,
      },
      className
    );
    const router = useRouter();
    const [t] = useTranslation('common');

    const { past_event_id } = router.query;

    const { eventsList, isAPILoading } = useCampaigns();

    const { managerSociety } = useAuth();
    const {
      available_invite_limit = 0,
      is_huzzle_verified,
      city_ids: selectedCityPref,
      country_ids: selectedCountryPref,
      industry_ids: selectedIndustryPref,
    } = managerSociety || {};

    const [showPreferences, setShowPreference] = useState(false);

    const [sidebarOptionSelected, setSidebarOptionSelected] = useState<SidebarOption>(SidebarOption.EXPLORE_HUZZLE);

    const [pastEventSelected, setPastEventSelected] = useState<{
      id: string;
      title: string;
      applications: number;
    } | null>(null);

    const useBottomRef = useRef<HTMLDivElement | null>(null);
    const topRef = useRef<HTMLDivElement | null>(null);

    const {
      talentPool,
      isLoading,
      isLoadingMore,
      hasMoreRecords,
      filters,
      setFilters,
      search,
      setSearch,
      fetchMoreData,
      onChangeFilter,
      partialFilters,
      partialCount,
      isPartialLoading,
      onChangeGroupedFilter,
      mutate: mutateTalentPool,
    } = useToolFilters({
      transaction: 'invite_community',
      sidebarOptionSelected,
    });

    useVisibleOnIntersect({
      elRef: useBottomRef,
      threshold: 0,
      onIntersect: fetchMoreData,
      rootMargin: '100px',
    });

    const resetHandler = () => {
      setSearch('');
      setFilters(null);
      topRef?.current?.scrollIntoView({ behavior: 'auto' });
      setFilterOpen(false);
    };

    const onExploreHuzzleClick = () => {
      resetHandler();
      setSidebarOptionSelected(SidebarOption.EXPLORE_HUZZLE);
    };
    const onProfileViewerClick = () => {
      resetHandler();
      setSidebarOptionSelected(SidebarOption.PROFILE_VIEWERS);
    };
    const onManualEmailClick = () => {
      resetHandler();
      setSidebarOptionSelected(SidebarOption.MANUAL_EMAIL);
    };

    const isEventSelected = useMemo(
      () => sidebarOptionSelected === SidebarOption.PAST_EVENT || sidebarOptionSelected === SidebarOption.EVENT_VIEWERS,
      [sidebarOptionSelected]
    );

    useEffect(() => {
      if (past_event_id && !isEventSelected) {
        router.replace(router.pathname, undefined, {
          shallow: true,
        });
        setPastEventSelected(null);
      }
    }, [past_event_id, sidebarOptionSelected]);

    const renderEventsList = useMemo(() => {
      return eventsList.slice(0, 20).map((event) => {
        const { id, title, start_date, applications } = event;
        const startDateValue = format(new Date(start_date), 'dd MMM');

        return (
          <div
            key={id}
            className={cn(styles.pastEvents, {
              [styles.pastEventsActive]: isEventSelected && id === past_event_id,
            })}
            onClick={() => {
              setSearch('');
              setPastEventSelected({
                id,
                title,
                applications,
              });
              router.replace(`${router.pathname}?past_event_id=${id}`, undefined, {
                shallow: true,
              });
              setSidebarOptionSelected(SidebarOption.PAST_EVENT);
            }}
          >
            <p className={styles.pastEventsName}>{title}</p>
            <p className={styles.pastEventsDetail}>
              {startDateValue}
              {' · '}
              {applications} Guests
            </p>
          </div>
        );
      });
    }, [eventsList, past_event_id, sidebarOptionSelected]);

    const [isFilterOpen, setFilterOpen] = useState<boolean>(false);

    const { data: exploreResponse, mutate: mutateExploreResponse } = useSWR<AxiosResponse<TalentPoolsResponse>>(
      `${API_ROUTES.SOCIETY_TALENT_POOL}?page=1&per_page=1`,
      apiInstance.get,
      {
        revalidateOnFocus: false,
      }
    );

    const { data: profileViewerResponse, mutate: mutateProfileResponse } = useSWR<AxiosResponse<TalentPoolsResponse>>(
      `${API_ROUTES.SOCIETY_PROFILE_VIEWERS}?page=1&per_page=1`,
      apiInstance.get,
      {
        revalidateOnFocus: false,
      }
    );

    const onDataUpdate = () => {
      mutateTalentPool();
      mutateExploreResponse();
      mutateProfileResponse();
    };

    const exploreHuzzleCount = useMemo(() => exploreResponse?.data?.meta?.total || 0, [exploreResponse]);
    const profileViewerCount = useMemo(() => profileViewerResponse?.data?.meta?.total || 0, [profileViewerResponse]);

    const { totalInviteLeft, percentInviteLeft } = useMemo(() => {
      const totalInvites = available_invite_limit;
      /** candidate Selected From Explore Huzzle  */
      const reqdCandidateSelected = candidateSelected.filter((item) => item.sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE);
      const totalInviteLeft = totalInvites - reqdCandidateSelected.length;
      const percentInviteLeft = (totalInviteLeft / totalInvites) * 100;

      return { totalInviteLeft, percentInviteLeft };
    }, [available_invite_limit, candidateSelected]);

    const noOfMembersSelected = useMemo(() => {
      return candidateSelected.filter(
        (item) => item.sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE || item.sidebarOptionSelected === SidebarOption.PROFILE_VIEWERS
      ).length;
    }, [candidateSelected]);

    const invitePopover = (
      <div className={styles['popover-invite-left']}>
        <p className={styles.title}>{t('invite-community-members-modal.header.invite-popover.title')}</p>
        <div className={styles.inviteCountContainer}>
          <p className={styles.inviteCountContainerTitle}>
            <Trans t={t} i18nKey="common">
              Invites per month from <span>Huzzle users</span>
            </Trans>
          </p>
          <Row align="center" justify="space-between">
            <p className={styles.inviteCount}>
              <span className={styles.count}>{noOfMembersSelected} </span>
              <span className={styles.countText}>{t('invite-community-members-modal.header.invite-popover.selected')}</span>
            </p>
            <p className={styles.inviteCount}>
              <span className={styles.count}>{available_invite_limit} </span>
              <span className={styles.countText}>{t('invite-community-members-modal.header.invite-popover.available')}</span>
            </p>
          </Row>
          <ProgressBar
            color="Primary"
            percentCompleted={(noOfMembersSelected / available_invite_limit) * 100}
            showProgressWhen0={false}
            className={styles.progressBar}
          />
        </div>

        {is_huzzle_verified ? null : (
          <>
            <p className={styles.subtitle}>{t('invite-community-members-modal.header.invite-popover.subtitle2')}</p>
            <p className={styles.subtitleDesc}>{t('invite-community-members-modal.header.invite-popover.desc')}</p>
            <ButtonV3 size="small" onClick={() => window.open(PAGE_ROUTES.DASHBOARD, '_blank')}>
              {t('invite-community-members-modal.header.invite-popover.button-text')}
            </ButtonV3>
          </>
        )}
      </div>
    );

    const isPreferenceSelected = useMemo(() => {
      return !!(selectedCityPref?.length || (selectedCountryPref?.length && selectedIndustryPref?.length));
    }, [selectedCityPref, selectedCountryPref, selectedIndustryPref]);

    const renderCandidateList = useMemo(() => {
      return (
        <CandidateList
          talentPool={talentPool}
          candidateSelected={candidateSelected}
          setCandidateSelected={setCandidateSelected}
          onModalResolve={onModalResolve}
          sidebarOptionSelected={sidebarOptionSelected}
          inviteLimit={available_invite_limit}
          showPopover={false}
          showCheckboxonExplore={isPreferenceSelected}
        />
      );
    }, [talentPool, candidateSelected, setCandidateSelected, available_invite_limit, onModalResolve, sidebarOptionSelected, isPreferenceSelected]);

    const searchBoxPlaceholder = useMemo(() => {
      if (isEventSelected && pastEventSelected) {
        return t('invite-community-members-modal.search-box.past-event-placeholder', {
          eventTitle: pastEventSelected.title,
        });
      }
    }, [isEventSelected, pastEventSelected]);

    const searchBox = (
      <Input
        className={styles['search-box']}
        inputClassName={styles['search-box-input']}
        placeholder={searchBoxPlaceholder}
        startIcon="icon_search"
        startIconSize="medium"
        startIconClassName={styles.searchIcon}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
      />
    );

    const notificationStub = (
      <Row align="center" justify="space-between" columnGap={12} isFullWidthRow wrap={false} className={styles.notificationStub}>
        <p className={styles.title}>{t('invite-community-members-modal.notification-stub.title')}</p>
        <p className={styles.desc}>{t('invite-community-members-modal.notification-stub.desc')}</p>
        <ButtonV3 size="medium" className={styles.button} borderless onClick={() => setShowPreference(true)}>
          {t('invite-community-members-modal.notification-stub.button-text')}
        </ButtonV3>
      </Row>
    );

    const renderPastEventDropdownMenu = useMemo(() => {
      const pastEventMenuOptions = [
        {
          id: '1',
          value: t('invite-community-members-modal.dropdown-value.attendees'),
          label: t('invite-community-members-modal.dropdown-value.attendees'),
        },
        {
          id: '2',
          value: t('invite-community-members-modal.dropdown-value.event-viewers'),
          label: t('invite-community-members-modal.dropdown-value.event-viewers'),
        },
      ];
      const getSelectedValueLabel = () => {
        const label =
          sidebarOptionSelected === SidebarOption.EVENT_VIEWERS
            ? t('invite-community-members-modal.dropdown-value.event-viewers')
            : t('invite-community-members-modal.dropdown-value.attendees');
        const count = talentPool.length ? `(${talentPool.length})` : '';
        return `${label} ${count}`;
      };

      return (
        <DropdownV3
          options={pastEventMenuOptions}
          onChange={(value) => {
            if (value === t('invite-community-members-modal.dropdown-value.attendees')) setSidebarOptionSelected(SidebarOption.PAST_EVENT);
            else setSidebarOptionSelected(SidebarOption.EVENT_VIEWERS);
          }}
          label={''}
          selectedValueLabel={getSelectedValueLabel()}
          value={t('invite-community-members-modal.attendees', {
            applications: talentPool.length,
          })}
          isValid={true}
          showCheckMark={true}
          className={styles.dropdown}
          dropdownBtnClassName={styles['dropdown-trigger']}
          dropdownClassName={styles['dropdown-options']}
          triggerButtonClassName={cn(styles['action-menu'], { [styles['action-menu-event']]: sidebarOptionSelected === SidebarOption.EVENT_VIEWERS })}
          isDefaultTriggerClass={false}
          iconClassName={styles.icon}
        />
      );
    }, [sidebarOptionSelected, talentPool]);

    return (
      <section className={contentClassNames} data-testid="Invite">
        {showPreferences ? (
          <MatchingPreference
            onClose={() => {
              onClose();
            }}
            onBack={() => setShowPreference(false)}
            onDataUpdate={onDataUpdate}
          />
        ) : (
          <>
            <header className={styles.header}>
              <p>{t('invite-community-members-modal.header.title')}</p>
              <Row columnGap={8} align="center" wrap={false} className={styles.headerIcons}>
                {sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE && isPreferenceSelected ? (
                  <Tooltip
                    content={invitePopover}
                    yAlign="top"
                    xAlign="right"
                    hasWhitespaceNoWrap={false}
                    className={styles.InvitePopover}
                    containerClassName={styles.InvitePopoverContainer}
                  >
                    <div className={styles.headerDetails}>
                      {percentInviteLeft ? (
                        <CircularProgressBar
                          percentCompleted={percentInviteLeft}
                          size="xxSmall"
                          showChildren={false}
                          showIcon={false}
                          shouldStartFromTop
                          pathClassName={styles.progressCircle}
                        />
                      ) : null}
                      <p>
                        {t('invite-community-members-modal.header.invitation-left', {
                          invite_limit: totalInviteLeft,
                        })}
                      </p>
                    </div>
                  </Tooltip>
                ) : null}
                <IconButton iconName="close" size="small" onClick={onClose} />
              </Row>
            </header>
            <div className={styles.content}>
              <div className={styles.sidebar}>
                <div className={styles.sidebarTop}>
                  <Row
                    columnGap={10}
                    className={cn(styles.sidebarRow, {
                      [styles.sidebarRowActive]: sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE,
                    })}
                    onClick={() => onExploreHuzzleClick()}
                  >
                    <Icon
                      iconName={sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE ? 'icon_compass-filled' : 'icon_compass'}
                      className={cn({
                        [styles.sidebarIconActive]: sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE,
                      })}
                    />
                    <p
                      className={cn(styles.sidebarText, {
                        [styles.sidebarTextActive]: sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE,
                      })}
                    >
                      {t('invite-community-members-modal.sidebar.explore-huzzle')}
                    </p>
                    {exploreHuzzleCount ? <p className={styles.sidebarCount}>{formatToK(exploreHuzzleCount)}</p> : null}
                  </Row>

                  <Row
                    columnGap={10}
                    className={cn(styles.sidebarRow, {
                      [styles.sidebarRowActive]: sidebarOptionSelected === SidebarOption.PROFILE_VIEWERS,
                    })}
                    onClick={() => onProfileViewerClick()}
                  >
                    <Icon
                      key={sidebarOptionSelected}
                      iconName={sidebarOptionSelected === SidebarOption.PROFILE_VIEWERS ? 'eye-filled' : 'eye-black'}
                      className={cn({
                        [styles.sidebarIconActive]: sidebarOptionSelected === SidebarOption.PROFILE_VIEWERS,
                      })}
                    />
                    <p
                      className={cn(styles.sidebarText, {
                        [styles.sidebarTextActive]: sidebarOptionSelected === SidebarOption.PROFILE_VIEWERS,
                      })}
                    >
                      {t('invite-community-members-modal.sidebar.profile-viewers')}
                    </p>
                    {profileViewerCount ? <p className={styles.sidebarCount}>{formatToK(profileViewerCount)}</p> : null}
                  </Row>

                  <Row
                    columnGap={10}
                    className={cn(styles.sidebarRow, {
                      [styles.sidebarRowActive]: sidebarOptionSelected === SidebarOption.MANUAL_EMAIL,
                    })}
                    onClick={() => onManualEmailClick()}
                  >
                    <Icon
                      key={sidebarOptionSelected}
                      iconName={sidebarOptionSelected === SidebarOption.MANUAL_EMAIL ? 'icon_mail_filled' : 'icon_mail'}
                      className={cn({
                        [styles.sidebarIconActive]: sidebarOptionSelected === SidebarOption.MANUAL_EMAIL,
                      })}
                    />
                    <p
                      className={cn(styles.sidebarText, {
                        [styles.sidebarTextActive]: sidebarOptionSelected === SidebarOption.MANUAL_EMAIL,
                      })}
                    >
                      {t('invite-community-members-modal.sidebar.enter-emails')}
                    </p>
                  </Row>
                  <div className={styles.separatorSidebar}></div>
                  <p className={styles.pastEventsTitle}>{t('invite-community-members-modal.sidebar.events')}</p>
                </div>
                <div className={styles.sidebarBottom}>
                  {eventsList.length === 0 && !isAPILoading ? (
                    <p className={styles.pastEventsText}>{t('invite-community-members-modal.sidebar.no-past-event')}</p>
                  ) : (
                    renderEventsList
                  )}
                </div>
              </div>
              <div className={styles.separator}></div>
              <div className={styles.main}>
                {sidebarOptionSelected === SidebarOption.MANUAL_EMAIL ? (
                  <InviteViaManualEmail
                    className={styles['invite-via-manual']}
                    candidateSelected={candidateSelected}
                    setCandidateSelected={setCandidateSelected}
                    importedCandidateList={importedCandidateList}
                    setImportedCandidateList={setImportedCandidateList}
                  />
                ) : (
                  <>
                    <div ref={topRef}></div>
                    <div className={styles.fixContainer}>
                      {sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE && !isPreferenceSelected ? notificationStub : null}
                      {isEventSelected ? searchBox : null}
                      {isEventSelected ? (
                        renderPastEventDropdownMenu
                      ) : sidebarOptionSelected !== SidebarOption.EXPLORE_HUZZLE ||
                        (sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE && isPreferenceSelected) ? (
                        <Row align="center" justify="space-between" isFullWidthRow className={styles.buttonRow}>
                          <Row align="center" className={styles.buttonContainer}>
                            <ButtonV3
                              color="secondary"
                              size="small"
                              borderless
                              onClick={() => setFilterOpen((isFilterOpen) => !isFilterOpen)}
                              startIcon="filter-2"
                              className={cn({
                                [styles.filterSelected]: isFilterOpen,
                              })}
                            >
                              {t('invite-community-members-modal.filters')}
                            </ButtonV3>

                            {sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE && (
                              <Tooltip
                                className={styles['action-tooltip']}
                                content={t('invite-community-members-modal.preferences')}
                                color="dark"
                                position="top"
                              >
                                <IconButton
                                  className={styles['settings-icon-button']}
                                  size="large"
                                  iconClassName={styles.settingIcon}
                                  onClick={() => setShowPreference(true)}
                                  iconName="setting"
                                />
                              </Tooltip>
                            )}
                            {filters && (
                              <div className={styles.clear} onClick={() => resetHandler()}>
                                {t('clear-all')}
                              </div>
                            )}
                          </Row>
                        </Row>
                      ) : null}
                      {isFilterOpen && !isEventSelected ? (
                        <ToolFilters
                          className={styles.filtersRow}
                          open={isFilterOpen}
                          filters={filters}
                          onChangeFilter={onChangeFilter}
                          isScroll={false}
                          isInvitePopup
                          showFirstFilteredEntityName={false}
                          showWhiteMultiselects
                          isMultiselectDisabled={false}
                          partialFilters={partialFilters}
                          partialCount={partialCount}
                          isPartialLoading={isPartialLoading}
                          onChangeGroupedFilter={onChangeGroupedFilter}
                        />
                      ) : null}
                      <div className={styles.separatorContent}></div>
                    </div>
                    {isLoading ? (
                      <Spinner wrapperClassname={styles.spinner} />
                    ) : (
                      <>
                        <div>
                          {talentPool?.length === 0 ? (
                            <p className={styles.candidateListEmpty}>{t('invite-community-members-modal.no-users-found')}</p>
                          ) : null}
                          <div
                            className={cn(
                              styles.candidateList,
                              {
                                [styles['no-scroll']]: sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE && !isPreferenceSelected,
                              },
                              {
                                [styles['candidate-list-past-events']]:
                                  sidebarOptionSelected === SidebarOption.PAST_EVENT ||
                                  sidebarOptionSelected === SidebarOption.EVENT_VIEWERS ||
                                  isFilterOpen,
                              }
                            )}
                          >
                            {renderCandidateList}
                            {hasMoreRecords && !isLoadingMore && <div ref={useBottomRef}></div>}
                            {isLoadingMore ? <Spinner wrapperClassname={styles.spinner} /> : null}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <Row align="center" justify={candidateSelected?.length ? 'space-between' : 'end'} isFullWidthRow className={styles.footer}>
              {candidateSelected?.length ? (
                <Row align="center" className={styles.buttonRow}>
                  <p>
                    {candidateSelected?.length} {t('selected')}
                  </p>
                  <ButtonV3 variant="text" className={styles.buttonText} onClick={() => setCandidateSelected([])}>
                    {t('deselect')}
                  </ButtonV3>
                </Row>
              ) : null}
              <ButtonV3
                onClick={onNextClick}
                disabled={candidateSelected?.length === 0 || candidateSelected.every((candidate) => candidate.invited || candidate.subscribed)}
              >
                {t('next')}
              </ButtonV3>
            </Row>
          </>
        )}
      </section>
    );
  }
);

InviteCommunity.displayName = 'InviteCommunity';
