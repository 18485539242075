import { serialize } from 'object-to-formdata';
import { useRecoilValue } from 'recoil';
import { useNotification } from 'hooks/useNotification';
import { apiInstance } from 'lib/utils/axios';

import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import {
  InviteViaEmailCandidateAttributes,
  InviteViaEmailSubscriberAttributes,
} from 'lib/models/candidate';
import { API_ROUTES } from 'lib/api-routes';
import { OpportunitiesResponse } from 'lib/models/opportunity';
import { parseAxiosErrorAlert } from 'lib/utils/parser';

export const useInviteGuest = () => {
  const notificationInstance = useNotification();
  const { plainHeaders } = useRecoilValue(httpHeadersState);

  const uploadGuestEmail = async (
    emailEntries: Array<string>,
    touchpointId: string
  ): Promise<Record<string, InviteViaEmailCandidateAttributes> | undefined> => {
    const body = {
      touchpoint_applications: emailEntries.map((email) => ({ email })),
    };

    try {
      const { data } = await apiInstance.post(
        `${API_ROUTES.SOCIETY_TOUCHPOINTS}/${touchpointId}/check`,
        body
      );

      return data;
    } catch (error) {
      // @ts-ignore
      const message = parseAxiosErrorAlert(error);
      notificationInstance.error({
        title: 'Error',
        message,
      });
      throw message;
    }
  };

  const uploadGuestEmailCSV = async (
    file: File,
    touchpointId: string
  ): Promise<Record<string, InviteViaEmailCandidateAttributes> | undefined> => {
    const body = {
      file,
    };

    const formData = serialize(body, {
      indices: true,
      allowEmptyArrays: true,
    });

    try {
      const { data } = await apiInstance.post(
        `${API_ROUTES.SOCIETY_TOUCHPOINTS}/${touchpointId}/check`,
        formData
      );

      return data;
    } catch (error) {
      // @ts-ignore
      const message = error.response.data;
      notificationInstance.error({
        title: 'Error',
        message,
      });
      throw message;
    }
  };

  const updateNotificationViewed = async (touchpointId: string) => {
    try {
      await apiInstance.put<OpportunitiesResponse>(
        `${API_ROUTES.SOCIETY_TOUCHPOINTS}/${touchpointId}${API_ROUTES.EVENT_GUIDE_VIEWED}`
      );
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const uploadSubscribersDataCSV = async (
    file: File
  ): Promise<Record<string, InviteViaEmailSubscriberAttributes>> => {
    const body = {
      file,
    };

    const formData = serialize(body, {
      indices: true,
      allowEmptyArrays: true,
    });

    try {
      const { data } = await apiInstance.post(
        `${API_ROUTES.ADD_SOCIETY_SUBSCRIBERS}/check_multiple`,
        formData
      );

      return data;
    } catch (error) {
      // @ts-ignore
      const message = error.response.data;
      notificationInstance.error({
        title: 'Error',
        message,
      });
      throw message;
    }
  };

  const uploadSubscribersData = async (
    emailEntries: Array<string>
  ): Promise<Record<string, InviteViaEmailSubscriberAttributes>> => {
    const body = {
      student_society_registrations: emailEntries.map((email) => ({ email })),
    };

    try {
      const { data } = await apiInstance.post(
        `${API_ROUTES.ADD_SOCIETY_SUBSCRIBERS}/check_multiple`,
        body
      );

      return data;
    } catch (error) {
      // @ts-ignore
      const message = parseAxiosErrorAlert(error);
      notificationInstance.error({
        title: 'Error',
        message,
      });
      throw message;
    }
  };

  const onDownloadSampleFileHandler = async () => {
    const { NEXT_PUBLIC_API_HOST_URL } = process.env;
    plainHeaders.set('Content-Type', 'text/csv');
    plainHeaders.set('Accept', 'text/csv');
    plainHeaders.set('Response-Type', 'text/csv');
    const path = `${NEXT_PUBLIC_API_HOST_URL}${API_ROUTES.SOCIETY_TOUCHPOINTS}/invite_sample_csv.csv`;
    try {
      const response = await fetch(path, { headers: plainHeaders });
      const data = await response.text();
      const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8,' });
      const blobURL = window.URL.createObjectURL(blob);
      const fileName = `Invite_guest_sample.csv`;
      saveAs(blobURL, fileName);
    } catch (error) {
      notificationInstance.handleExceptionError(error as Error);
    }
  };

  return {
    uploadGuestEmail,
    uploadGuestEmailCSV,
    updateNotificationViewed,
    uploadSubscribersData,
    uploadSubscribersDataCSV,
    onDownloadSampleFileHandler,
  };
};
