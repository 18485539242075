import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  Fragment,
} from 'react';
import cn from 'classnames';
import { noop } from 'lodash';

import { Icon, Image, IconName, IconButton, Spinner } from 'components';
import { Checkbox, Input } from 'components/form';
import { ButtonV3 } from 'components/ComponentV2';
import { useResponsive } from 'hooks/useResponsive';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Option } from 'lib/models/option';
import { formatToK } from 'utils/format';
import styles from './GroupedMultiSelect.module.scss';

export type Position = 'top' | 'bottom';

export interface GroupedMultiSelectCommonProps {
  className?: string;
  title: string;
  options?: Array<Option>;
  values: Array<string>;
  subOptions: Array<Option>;
  subValues: Array<string>;
  isSearchable?: boolean;
  alignment?: 'left' | 'right' | 'center';
  placeholder?: string;
  dropdownClassName?: string;
  position?: Position;
  fullWidth?: boolean;
  triggerClassName?: string;
  hasBorder?: boolean;
  showCountWithLabel?: boolean;
  iconClassName?: IconName;
  showSingleValueLabel?: boolean;
  onChange: ({ values, subValues }: { values: Array<string>; subValues: Array<string> }) => void;
}

type MultiRefs = {
  listRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

type ApplyOnChangeProps = {
  changeOnShow?: false;
};

type ApplyOnShowProps = {
  changeOnShow: true;
  partialCount: number;
  partialLoading: boolean;
  partialValues?: Array<string> | null;
  partialSubValues?: Array<string> | null;
  onPartialChange: ({ values, subValues }: { values?: string[] | null; subValues?: string[] | null }) => void;
};

type NonPartialProps = GroupedMultiSelectCommonProps & ApplyOnChangeProps;
type PartialProps = GroupedMultiSelectCommonProps & ApplyOnShowProps;
export type GroupedMultiSelectProps = NonPartialProps | PartialProps;

const isApplyOnShow = (props: GroupedMultiSelectProps): props is PartialProps => (props as PartialProps).changeOnShow;

const GroupedMultiSelectComponent: ForwardRefRenderFunction<MultiRefs, GroupedMultiSelectProps> = (props, ref) => {
  const {
    className = '',
    title,
    options,
    subOptions,
    values,
    subValues,
    alignment = 'left',
    placeholder,
    isSearchable,
    dropdownClassName,
    position = 'bottom',
    fullWidth = false,
    triggerClassName,
    hasBorder = false,
    showCountWithLabel = true,
    showSingleValueLabel = false,
    iconClassName,
    onChange,
  } = props;

  const selectedValues = isApplyOnShow(props) ? (typeof props.partialValues !== 'undefined' ? props.partialValues || [] : values) : values;
  const selectedSubValues = isApplyOnShow(props)
    ? typeof props.partialSubValues !== 'undefined'
      ? props.partialSubValues || []
      : subValues
    : subValues;
  const onChangeHandler = isApplyOnShow(props) ? props.onPartialChange : onChange;

  const contentClassNames = cn(
    {
      [styles.GroupedMultiSelect]: true,
      [styles.GroupedMultiSelectFullWidth]: fullWidth,
    },
    className
  );
  const listRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({
    listRef: listRef,
    buttonRef: buttonRef,
  }));

  const screens = useResponsive();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [expandedOptionId, setExpandedOptionId] = useState<string | null>(null);

  const trimmedSearch = search.trim();

  const dropdownClassNames = cn(
    styles.dropdown,
    {
      [styles['align-left']]: alignment === 'left',
      [styles['align-right']]: alignment === 'right',
      [styles['align-center']]: alignment === 'center',
      [styles['position-top']]: position === 'top',
      [styles['position-bottom']]: position === 'bottom',
      [styles['full-width']]: fullWidth,
    },
    dropdownClassName
  );

  const multiSelectRef = useRef(null);

  const onClose = useCallback(() => {
    setOpen(false);

    if (open) {
      setExpandedOptionId(null);
    }

    if (open && isApplyOnShow(props)) {
      props.onPartialChange({ values: undefined, subValues: undefined });
    }
  }, [open, props]);

  const onValueChange = (value: string) => {
    const newValues = selectedValues.includes(value) ? selectedValues.filter((val) => val !== value) : [...selectedValues, value];
    onChangeHandler({
      values: newValues,
      subValues: selectedSubValues,
    });
  };

  const onSubValueChange = (subValue: string, parent_id: string) => {
    let newValues = selectedValues;
    let newSubValues = selectedSubValues.includes(subValue) ? selectedSubValues.filter((val) => val !== subValue) : [...selectedSubValues, subValue];
    if (newSubValues.length > selectedSubValues.length) {
      const itemSubOptions = subOptions.filter((subOption) => subOption.parent_id === parent_id);

      // When all subOptions are selected, select the parent option and remove the all related subOption valaues from subValues
      const isAllSubValuesSelected = itemSubOptions.every((subOption) => newSubValues.includes(subOption.value));
      newValues = isAllSubValuesSelected ? [...newValues, parent_id] : newValues;
      newSubValues = isAllSubValuesSelected
        ? newSubValues.filter((selectedSubValue) => !itemSubOptions.some((itemSubOption) => itemSubOption.value === selectedSubValue))
        : newSubValues;
    }
    onChangeHandler({
      values: newValues,
      subValues: newSubValues,
    });
  };

  const onDeselectAllChildren = (parentValue: string) => {
    const subValuesToRemove = subOptions.filter((subOption) => subOption.parent_id === parentValue).map((subOption) => subOption.value);
    const newSubValues = selectedSubValues.filter((subValue) => !subValuesToRemove.includes(subValue));
    onChangeHandler({ values: selectedValues, subValues: newSubValues });
  };

  const handleDeselectChild = (parentValue: string, childValue: string) => {
    const parentChildrenValues = subOptions
      .filter((subOption) => subOption.parent_id === parentValue && subOption.value !== childValue)
      .map((subOption) => subOption.value);
    const newValues = selectedValues.filter((value) => value !== parentValue);
    onChangeHandler({ values: newValues, subValues: parentChildrenValues });
  };

  const onReset = () => {
    if (isApplyOnShow(props)) {
      props.onPartialChange({ values: null, subValues: null });
    }
  };

  const onShow = useCallback(() => {
    onChange({
      values: selectedValues,
      subValues: selectedSubValues,
    });
    onClose();
  }, [selectedValues, selectedSubValues, onChange, onClose]);

  useOnClickOutside({ ref: multiSelectRef, onOutsideClick: open ? (isApplyOnShow(props) ? onShow : onClose) : noop });

  useEffect(() => {
    if (open === false) {
      setSearch('');
      buttonRef.current?.setAttribute('aria-expanded', 'false');
    } else {
      buttonRef.current?.setAttribute('aria-expanded', 'true');
    }
  }, [open]);

  const searchString = trimmedSearch.toLowerCase();

  const filteredSubOptions = useMemo(() => {
    return isSearchable && searchString ? subOptions.filter((option) => option.label.toLowerCase().indexOf(searchString) >= 0) : subOptions;
  }, [isSearchable, searchString, subOptions]);

  const filteredOptions = useMemo(() => {
    if (!options) return null;

    if (isSearchable && searchString) {
      return options.filter(
        (option) =>
          option.label.toLowerCase().indexOf(searchString) >= 0 || filteredSubOptions.some((subOption) => subOption.parent_id === option.value)
      );
    } else {
      return options;
    }
  }, [isSearchable, searchString, options, filteredSubOptions]);

  const handleToggle = () => {
    if (open) {
      onShow();
    } else {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      screens.sm && updateDropdownMaxHeight();
    };
    window?.addEventListener('scroll-top', handleScroll);

    return () => {
      window?.removeEventListener('scroll-top', handleScroll);
    };
  }, [screens.sm]);

  const updateDropdownMaxHeight = () => {
    if (listRef.current && screens.sm) {
      const dropdownRect = listRef.current.getBoundingClientRect();
      const maxHeight = window.innerHeight - dropdownRect.top - 12;
      listRef.current.style.maxHeight = `${maxHeight}px`;
      listRef.current.style.overflow = 'auto';
    }
  };

  const singleValueLabel = useMemo(() => {
    if (!showSingleValueLabel || !options) return '';

    if (values.length === 1 && subValues.length === 0) {
      const option = options.find((option) => option.value === values[0]);
      return option?.label;
    } else if (values.length === 0 && subValues.length === 1) {
      const subOption = subOptions.find((subOption) => subOption.value === subValues[0]);
      return subOption?.label;
    }
    return '';
  }, [showSingleValueLabel, values, options, subValues, subOptions]);

  const onToggleSubOptions = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, optionId: string) => {
    event.stopPropagation();
    setExpandedOptionId((prevId) => (prevId === optionId ? null : optionId));
  }, []);

  const selectedSubOptions = useMemo(() => {
    if (!expandedOptionId) {
      return [];
    }

    return filteredSubOptions.filter((option) => option.parent_id === expandedOptionId);
  }, [expandedOptionId, filteredSubOptions]);

  const hasSubOptions = useCallback(
    (optionId: string) => {
      return filteredSubOptions.some((option) => option.parent_id === optionId);
    },
    [filteredSubOptions]
  );

  const totalSelectedValues = values.length + subValues.length;

  const showReset = useMemo(() => {
    if (!isApplyOnShow(props)) return false;
    const partialValues = props.partialValues || [];
    const partialSubValues = props.partialSubValues || [];

    return typeof props.partialValues !== 'undefined' || typeof props.partialSubValues !== 'undefined'
      ? partialValues.length > 0 || partialSubValues.length > 0
      : values.length || subValues.length;
  }, [props, values, subValues]);

  return (
    <section className={contentClassNames} data-testid="GroupedMultiSelect" ref={multiSelectRef}>
      <button
        className={cn(
          styles.trigger,
          {
            [styles['full-width']]: fullWidth,
            [styles['has-border']]: hasBorder,
            [styles.selected]: values.length || subValues.length,
          },
          triggerClassName
        )}
        onClick={handleToggle}
        type="button"
        ref={buttonRef}
      >
        <p>{singleValueLabel ? singleValueLabel : showCountWithLabel && totalSelectedValues ? `${title} (${totalSelectedValues})` : title}</p>
        <Icon
          iconName="icon_arrow-down"
          size="xsmall"
          className={cn(styles.icon, iconClassName, {
            [styles.reverse]: open,
          })}
        />
      </button>
      {open ? (
        <div className={dropdownClassNames} ref={listRef}>
          {isSearchable ? (
            <div className={styles.search}>
              <Input
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                className={styles.input}
                startIconClassName={styles['search-icon']}
                placeholder={placeholder}
                startIcon="search"
                startIconSize="small"
                endIconSize="xsmall"
                endIcon={search ? 'cancel' : undefined}
                onEndIconClick={() => setSearch('')}
                autoFocus={true}
              />
            </div>
          ) : null}
          <ul className={styles.options}>
            {filteredOptions?.map(({ id, value, label, avatar }) => {
              const checked = selectedValues.includes(value);
              const itemSubOptions = subOptions.filter((subOption) => subOption.parent_id === value);
              const areAllChildrenSelected =
                itemSubOptions.length > 0 && itemSubOptions.every((subOption) => selectedSubValues.includes(subOption.value));
              const isSomeChildrenSelected =
                itemSubOptions.length > 0 && itemSubOptions.some((subOption) => selectedSubValues.includes(subOption.value));
              const isChecked = checked || areAllChildrenSelected;
              const isHalfChecked = isSomeChildrenSelected && !areAllChildrenSelected;

              return (
                <Fragment key={id}>
                  <li
                    key={id}
                    className={cn(styles.option, {
                      [styles.selected]: isChecked,
                    })}
                    onClick={() => (areAllChildrenSelected || isSomeChildrenSelected ? onDeselectAllChildren(value) : onValueChange(value))}
                  >
                    <IconButton
                      iconName="icon_arrow-down"
                      size="xsmall"
                      onClick={(event) => onToggleSubOptions(event, id)}
                      className={cn(styles['option-icon'], {
                        [styles.expanded]: expandedOptionId === id,
                        [styles.invisible]: !hasSubOptions(id),
                      })}
                    />
                    <Checkbox checked={isChecked} halfChecked={isHalfChecked} labelFirst={true} readOnly className={styles.checkbox}>
                      {avatar ? <Image src={avatar} alt={label} /> : null}
                      <span>{label}</span>
                    </Checkbox>
                  </li>
                  {expandedOptionId === id &&
                    selectedSubOptions.map(({ id: childId, value, label, avatar, parent_id }) => {
                      const checked = selectedSubValues.includes(value);
                      const parentChecked = parent_id ? selectedValues.includes(parent_id) : false;
                      const isChecked = checked || parentChecked;

                      return (
                        <li
                          key={childId}
                          className={cn(styles.option, styles['sub-option'], {
                            [styles.selected]: isChecked,
                          })}
                          onClick={() =>
                            parent_id ? (parentChecked ? handleDeselectChild(parent_id, value) : onSubValueChange(value, parent_id)) : null
                          }
                        >
                          <Checkbox checked={isChecked} labelFirst={true} readOnly className={styles.checkbox}>
                            {avatar ? <Image src={avatar} alt={label} /> : null}
                            <span>{label}</span>
                          </Checkbox>
                        </li>
                      );
                    })}
                </Fragment>
              );
            })}
          </ul>
          {filteredOptions?.length === 0 ? <p className={styles['no-results']}>No results found.</p> : null}
          {typeof options === 'undefined' && (
            <div className={styles.spinner}>
              <Spinner size="small" />
            </div>
          )}
          {isApplyOnShow(props) && (
            <footer className={styles.actions}>
              {showReset ? (
                <ButtonV3 color="secondary" size="small" onClick={onReset}>
                  Reset
                </ButtonV3>
              ) : (
                <ButtonV3 color="secondary" size="small" onClick={onClose}>
                  Cancel
                </ButtonV3>
              )}
              <ButtonV3 size="small" className={styles['show-button']} onClick={onShow} isLoading={props.partialLoading}>{`Show ${formatToK(
                props.partialCount
              )}`}</ButtonV3>
            </footer>
          )}
        </div>
      ) : null}
    </section>
  );
};

export const GroupedMultiSelect = memo(forwardRef(GroupedMultiSelectComponent));

GroupedMultiSelect.displayName = 'GroupedMultiSelect';
