import { useMemo } from 'react';
import useSWR, { KeyedMutator } from 'swr';

import { AxiosResponse } from 'axios';
import { useNotification } from 'hooks/useNotification';
import { API_ROUTES } from 'lib/api-routes';
import { apiInstance } from 'lib/utils/axios';
import {
  SponsorCompanyAttributes,
  SponsorCompanyInviteResponse,
  sponsorCompaniesResponse,
} from 'lib/models/sponsor-companies';
import { useAuth } from 'lib/providers/AuthProvider';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';

export interface SponsorshipInviteReturnType {
  companySponsorInviteList: Array<SponsorCompanyAttributes>;
  mutateSponsorInviteList: KeyedMutator<
    AxiosResponse<sponsorCompaniesResponse>
  >;
  onAcceptRejectSponsorInvite: (
    api_pathname: string
  ) => Promise<SponsorCompanyInviteResponse | undefined>;
  updateCompanySeenInvite: (
    api_pathname: string
  ) => Promise<SponsorCompanyInviteResponse | undefined>;
  getInviteInfo: (
    invite_id: string
  ) => Promise<SponsorCompanyAttributes | null | undefined>;
}

export const useSponsorshipInvite = (): SponsorshipInviteReturnType => {
  const notificationInstance = useNotification();
  const { isRecruiter } = useAuth();

  const {
    data: companySponsorInviteListResponse,
    mutate: mutateSponsorInviteList,
  } = useSWR<AxiosResponse<sponsorCompaniesResponse>>(
    isRecruiter
      ? API_ROUTES.COMPANY_SPONSORSHIP_INVITE_LIST
      : API_ROUTES.SOCIETY_INVITE_SPONSORS,
    apiInstance.get,
    {
      revalidateOnFocus: false,
    }
  );

  const companySponsorInviteList = useMemo(() => {
    if (!companySponsorInviteListResponse) return [];
    const data = parseArrayResponse<SponsorCompanyAttributes>(
      companySponsorInviteListResponse.data
    );
    return data;
  }, [companySponsorInviteListResponse]);

  const onAcceptRejectSponsorInvite = async (api_pathname: string) => {
    try {
      const response = await apiInstance.post(api_pathname);
      return response;
    } catch (error) {
      notificationInstance.error(error as Error);
    }
  };

  const updateCompanySeenInvite = async (api_pathname: string) => {
    const body = { sponsorship_invitation: { company_seen: true } };
    try {
      const response = await apiInstance.patch(api_pathname, body);
      return response;
    } catch (error) {
      notificationInstance.error(error as Error);
    }
  };

  const getInviteInfo = async (invite_id: string) => {
    try {
      const pathname = isRecruiter
        ? `${API_ROUTES.COMPANY_SPONSORSHIP_INVITE_LIST}/${invite_id}`
        : `${API_ROUTES.SOCIETY_INVITE_SPONSORS}/${invite_id}`;

      const response = await apiInstance.get(pathname);
      const invite = parseResponse<SponsorCompanyAttributes>(response?.data);
      return invite;
    } catch (error) {
      notificationInstance.error(error as Error);
    }
  };

  return {
    companySponsorInviteList,
    mutateSponsorInviteList,
    onAcceptRejectSponsorInvite,
    updateCompanySeenInvite,
    getInviteInfo,
  };
};
