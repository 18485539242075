import React, { memo, useState, Dispatch, SetStateAction, useMemo, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'next-i18next';

import { IconButton } from 'components/IconButton/IconButton.component';
import { ButtonV3 } from 'components/ComponentV2';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';
import { Row } from 'components/layout';
import { Avatar } from 'components/Avatar/Avatar.component';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { SidebarOption } from 'hooks/useToolFilters';
import { useInviteGuest } from 'hooks/useInviteGuests';
import { REGEX } from 'lib/utils/regex';
import { InviteSubscriber, InviteViaEmailSubscriberAttributes } from 'lib/models/candidate';

import styles from './InviteViaManualEmail.module.scss';

export interface InviteViaManualEmailProps {
  className?: string;
  candidateSelected: Array<InviteSubscriber>;
  setCandidateSelected: Dispatch<SetStateAction<Array<InviteSubscriber>>>;
  importedCandidateList: Array<InviteSubscriber>;
  setImportedCandidateList: Dispatch<SetStateAction<Array<InviteSubscriber>>>;
}

export const InviteViaManualEmail = memo(
  ({ className = '', candidateSelected, setCandidateSelected, importedCandidateList, setImportedCandidateList }: InviteViaManualEmailProps) => {
    const [t] = useTranslation('common');

    const [emailEntries, setEmailEntries] = useState<string[]>([]);
    const [isLastEmailFocused, setIsLastEmailFocused] = useState<boolean>(false);
    const [currentEmail, setCurrentEmail] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [successfullyImportedCount, setSuccessfullyImportedCount] = useState(-1);

    useEffect(() => {
      const emails = importedCandidateList.map((candidate) => candidate.email);
      setEmailEntries(emails);
    }, [importedCandidateList]);

    const { uploadSubscribersData, uploadSubscribersDataCSV, onDownloadSampleFileHandler } = useInviteGuest();

    const transformApiData = (data: Record<string, InviteViaEmailSubscriberAttributes>) => {
      const candidates = Object.entries(data).map(([email, details]) => ({
        email,
        ...details,
      }));

      const huzzleCandidates = candidates.map((candidate) => {
        const { email, name, avatar_url, subscribed, invited } = candidate;
        return {
          id: candidate.email,
          name,
          email,
          avatar_url,
          sidebarOptionSelected: SidebarOption.MANUAL_EMAIL,
          subscribed,
          invited,
        };
      });

      const selectedCandidate = huzzleCandidates.filter((candidate) => !candidate.subscribed && !candidate.invited);

      setSuccessfullyImportedCount(selectedCandidate.length);

      // Create a Set to track unique emails
      const uniqueEmails = new Set(candidateSelected.map((candidate) => candidate.email));

      // Filter the selected candidates to only include those with unique emails
      const distinctSelectedCandidates = selectedCandidate.filter((candidate) => {
        if (!uniqueEmails.has(candidate.email)) {
          uniqueEmails.add(candidate.email);
          return true;
        }
        return false;
      });

      setCandidateSelected([...candidateSelected, ...distinctSelectedCandidates]);
      setImportedCandidateList(huzzleCandidates);
    };

    const onDrop = async (acceptedFiles: Array<File>) => {
      const file = acceptedFiles[0];
      if (!file) return;

      setIsLoading(true);
      try {
        const response = await uploadSubscribersDataCSV(file);
        if (response) {
          transformApiData(response);
          setError(false);
        }
      } catch (error) {
        console.error('Error uploading CSV:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const handleAddEmail = async () => {
      setIsLoading(true);
      try {
        const response = await uploadSubscribersData(emailEntries);
        if (response) {
          transformApiData(response);
          setError(false);
        }
      } catch (error) {
        console.error('Error adding emails:', error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const onCandidateSelect = ({ name, email, avatar_url }: { name: string; email: string; avatar_url: string }) => {
      const index = candidateSelected?.findIndex((candidate) => candidate.email === email);

      if (index === -1) {
        setCandidateSelected([
          ...candidateSelected,
          {
            id: '',
            name,
            email,
            avatar_url,
            sidebarOptionSelected: SidebarOption.MANUAL_EMAIL,
          },
        ]);
      } else {
        setCandidateSelected(candidateSelected && candidateSelected.filter((item) => item.email !== email));
      }
    };

    const renderCandidateList = useMemo(
      () =>
        importedCandidateList.map((candidate, index) => {
          const { name, avatar_url, email, subscribed, invited } = candidate;
          return (
            <React.Fragment key={index}>
              {subscribed || invited ? (
                <Row align="center" justify="space-between" columnGap={12} className={styles['in-event-candidate']}>
                  <Row align="center" columnGap={12} wrap={false}>
                    <Avatar icon={avatar_url} name={name || email} size="small" />
                    <div>
                      <p className={styles.name}>{name}</p>
                      <p className={styles.email}>{email}</p>
                    </div>
                  </Row>
                  <p className={styles['in-event']}>{subscribed ? t('member') : t('invited')}</p>
                </Row>
              ) : (
                <SelectionTickMark
                  isTickSquare
                  className={styles.selection}
                  checked={candidateSelected?.some((candidate) => email === candidate.email)}
                  onChange={() => onCandidateSelect({ name, email, avatar_url })}
                >
                  <Row align="center" columnGap={12} wrap={false}>
                    <Avatar icon={avatar_url} name={name || email} size="small" />
                    <div>
                      <p className={styles.name}>{name}</p>
                      <p className={styles.email}>{email}</p>
                    </div>
                  </Row>
                </SelectionTickMark>
              )}
            </React.Fragment>
          );
        }),
      [importedCandidateList, candidateSelected]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentEmail(e.target.value);
      setIsLastEmailFocused(false);
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === ',') {
        e.preventDefault();
        const trimmedEmail = currentEmail.trim();
        const isValidEmail = REGEX.EMAIL.test(trimmedEmail) || trimmedEmail.length === 0;
        setIsValidEmail(isValidEmail);

        if (trimmedEmail && isValidEmail) {
          setEmailEntries((emails) => [...emails, trimmedEmail]);
          setCurrentEmail('');
        }
      }
      if (e.key === 'Backspace') {
        if ((e.target as HTMLInputElement).value === '' && emailEntries.length) {
          e.preventDefault();
          if (isLastEmailFocused) {
            const newEmailList = emailEntries;
            newEmailList.pop();
            setEmailEntries(newEmailList);
            setIsLastEmailFocused(false);
          } else setIsLastEmailFocused(true);
        }
      }
    };

    const handleRemoveEmail = (emailToDelete: string) => {
      setEmailEntries((prevEmails) => prevEmails.filter((email) => email !== emailToDelete));
    };

    const focusOnInput = () => {
      inputRef.current?.focus();
    };

    const { getRootProps, getInputProps, open } = useDropzone({
      onDrop,
      noClick: true,
      accept:
        '.csv,text/csv,application/vnd.ms-excel,application/csv,text/x-csv,application/x-csv,text/comma-separated-values,text/x-comma-separated-values,text/plain',
      maxFiles: 1,
    });

    return (
      <div className={cn(styles.InviteManually, className)} data-testid="CandidateList">
        <section className={styles.TextArea} data-testid="TextAreaContainer">
          <div className={styles.TextAreaLabel}>
            <label>{t('invite-via-manual-email.add-emails')}</label>
          </div>
          <div className={cn(styles.TextAreaContainer)}>
            <div className={cn(styles.TextAreaElement)} onClick={focusOnInput}>
              {emailEntries &&
                emailEntries.map((email, index) => {
                  return (
                    <div
                      className={cn(styles.emailChip, {
                        [styles['is-focused']]: isLastEmailFocused && emailEntries.length === index + 1,
                      })}
                      key={index}
                    >
                      <p className={styles.emailChipText}>{email}</p>
                      <IconButton
                        iconName="close"
                        size="small"
                        className={cn(styles.closeIcon)}
                        onClick={() => {
                          handleRemoveEmail(email);
                        }}
                      />
                    </div>
                  );
                })}

              <input
                value={currentEmail}
                data-testid="Input"
                ref={inputRef}
                placeholder={emailEntries.length ? '' : 'Enter emails, comma separated'}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                className={styles.inputElement}
              />
            </div>
            <ButtonV3 className={styles.submitButton} disabled={(!isValidEmail && emailEntries.length === 0) || isLoading} onClick={handleAddEmail}>
              {t('invite-via-manual-email.add')}
            </ButtonV3>
          </div>
        </section>
        {isLoading ? (
          <Spinner className={styles.loader} size="medium" wrapper />
        ) : importedCandidateList.length > 0 ? (
          <>
            {successfullyImportedCount >= 0 ? (
              <Row align="center" columnGap={8} className={styles['import-count-message']}>
                <Icon size="small" iconName="icon_info" />
                <p>
                  {t('invite-via-manual-email.import-message', {
                    successfullyImportedCount,
                    importedCandidateList: importedCandidateList?.length,
                  })}
                </p>
                <IconButton size="xsmall" iconName="close" className={styles.closeIcon} onClick={() => setSuccessfullyImportedCount(-1)} />
              </Row>
            ) : null}
            <div className={styles.candidateList}>{renderCandidateList}</div>
          </>
        ) : (
          <div {...getRootProps()} className={styles.uploadCSV}>
            <div onClick={open}>
              <input {...getInputProps()} />
              <p className={styles.uploadCSVLabel}>{t('invite-via-manual-email.import-csv')}</p>
              <div className={styles.uploadCSVContent}>
                <Icon iconName="file-icon" className={styles.fileIcon} />
                <p className={styles.uploadCSVHeading}>{t('invite-via-manual-email.import-csv')}</p>
                <p className={styles.uploadCSVText}>{t('invite-via-manual-email.drop-file-text')}</p>
                <Row align="center" columnGap={4}>
                  <p className={styles.uploadCSVText}>{t('invite-via-manual-email.sample-file')}</p>

                  <ButtonV3
                    variant="text"
                    color="blue"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onDownloadSampleFileHandler();
                    }}
                    className={styles.textbutton}
                  >
                    {t('invite-via-manual-email.here')}
                  </ButtonV3>
                </Row>
              </div>
            </div>
            {error && (
              <div className={styles.uploadCSVErrorContainer}>
                <div className={styles.errorSection}>
                  <Icon iconName="info" size="small" className={styles.infoIcon} />

                  <p className={styles.infoText}>{t('invite-via-manual-email.import-failure')}</p>
                </div>
                <IconButton iconName="close" size="xsmall" className={styles.cancelIcon} onClick={() => setError(false)} />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

InviteViaManualEmail.displayName = 'InviteViaManualEmail';
