import React from 'react';
import cn from 'classnames';
import Icon from 'components/Icon';
import styles from './ProgressReferral.module.scss';

export interface ProgressReferralsProps {
  rewardCount: number;
}

interface Step {
  title: string;
  description: string;
  isCompleted?: boolean;
  isCurrent?: boolean;
}

export const ProgressReferrals: React.FC<ProgressReferralsProps> = ({
  rewardCount,
}) => {
  const steps: Step[] = [
    {
      title: '1 Referral',
      description: 'Have an article written about your society and committee.',
      isCompleted: rewardCount > 1,
      isCurrent: rewardCount === 1,
    },
    {
      title: '2 Referrals',
      description:
        'Receive a free custom-branded banner for your freshers fair and in-person events.',
      isCompleted: rewardCount > 2,
      isCurrent: rewardCount === 2,
    },
    {
      title: '3 Referrals',
      description: 'Get 1000 custom-branded flyers printed for your society.',
      isCompleted: rewardCount > 3,
      isCurrent: rewardCount >= 3 && rewardCount < 5,
    },
    {
      title: '5 Referrals',
      description:
        'Access our exclusive database of 130+ speakers from 50+ of the top companies in the UK.',
      isCompleted: rewardCount > 5,
      isCurrent: rewardCount >= 5 && rewardCount < 10,
    },
    {
      title: '10 Referrals',
      description:
        'Your entire committee gets free tickets to the next Huzzle Summit.',
      isCompleted: rewardCount > 10,
      isCurrent: rewardCount >= 10 && rewardCount < 15,
    },
    {
      title: '15 Referrals',
      description:
        'Get branded Huzzle X (your society) merch for your whole committee.',
      isCompleted: rewardCount >= 15,
    },
  ];

  return (
    <div className={styles.ProgressReferral}>
      <h3>Your rewards</h3>
      <ul className={styles.StepProgress}>
        {steps.map(({ title, description, isCompleted, isCurrent }, index) => (
          <div
            key={index}
            className={cn(styles.StepProgressItem, {
              [styles.isDone]: !isCurrent && isCompleted,
              [styles.current]: isCurrent,
            })}
          >
            <h4>{title}</h4>
            <p>{description}</p>
            {!isCurrent && isCompleted && (
              <Icon
                iconName="tick-mark-circle"
                size="large"
                className={cn(styles.tick)}
              />
            )}
            {isCurrent && <div className={styles.newCircle}></div>}
          </div>
        ))}
      </ul>
    </div>
  );
};
