import React, { memo, useState, useEffect } from 'react';
import cn from 'classnames';
import { IconButton, Spinner } from 'components';
import { Icon } from 'components/Icon/Icon.component';
import Popover from 'components/Popover';
import { useNotification } from 'hooks/useNotification';
import { useSocietyProfile } from 'pages/society-profile/UseSocietyProfile.hook';
import { GetHuzzleVerified } from 'pages/dashboard/GetHuzzleVerified/GetHuzzleVerified.component';
import { EditShortLinkModal } from 'pages/dashboard/EditShortLinkModal/EditShortLinkModal.component';
import { PAGE_ROUTES } from 'lib/page-routes';
import { parseErrorResponse } from 'lib/utils/parser';

import styles from './SocietyProgressBar.module.scss';

export interface SocietyProgressBarProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  steps: Array<{ id: string; isComplete?: boolean | number }>;
  completedSteps?: number;
}

export const SocietyProgressBar: React.FC<SocietyProgressBarProps> = memo(
  ({
    className = '', // custom class name
    steps,
    completedSteps = 0,
  }: SocietyProgressBarProps) => {
    const [isVerified, setIsVerified] = useState(false);
    const [shouldAllowPopoverClose, setShouldAllowPopoverClose] =
      useState(true);
    const [showProgressBar, setShowProgressBar] = useState(true);
    const [showEditLinkModal, setShowEditLinkModal] = useState<boolean>(false);
    const [shortLinkError, setShortLinkError] = useState<string>('');
    const [updateSlugLoading, setUpdateSlugLoading] = useState<boolean>(false);

    const notificationInstance = useNotification();

    const contentClassNames = cn(
      {
        [styles.SocietyProgressBar]: true,
      },
      className
    );
    const {
      societyDashboard,
      societyProfile,
      onSocietyProfileUpdate,
      mutateSocietyDashboard,
    } = useSocietyProfile();
    const { profile_complete_percentage, profile_complete, huzzle_verified } =
      societyDashboard || {};

    useEffect(() => {
      if (
        (profile_complete && profile_complete_percentage === 100) ||
        huzzle_verified
      ) {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    }, [societyDashboard]);

    const onClose = async () => {
      setShowProgressBar(false);
      await onSocietyProfileUpdate(
        { remove_huzzle_verified_banner: true },
        false,
        true
      );
    };

    const toggleEditShortLinkModal = () => {
      setShortLinkError('');
      setShowEditLinkModal(!showEditLinkModal);
    };

    const copyInviteLink = () => {
      navigator.clipboard.writeText(
        `${window.location.origin}${PAGE_ROUTES.MANAGER_SIGN_UP}`
      );
      notificationInstance.success({
        title: 'Link copied',
        message: 'Committee invitation link has been copied.',
      });
    };

    const changeShortSlug = async (short_slug: string) => {
      setShortLinkError('');
      try {
        setUpdateSlugLoading(true);
        await onSocietyProfileUpdate({ short_slug }, true);
        toggleEditShortLinkModal();
        setUpdateSlugLoading(false);
        mutateSocietyDashboard();
        notificationInstance.success({
          title: 'Success',
          message: 'Short link updated.',
        });
      } catch (error) {
        setUpdateSlugLoading(false);
        setShortLinkError(parseErrorResponse(error as Error));
      }
    };

    const copyProfileLink = () => {
      navigator.clipboard.writeText(
        societyProfile.short_link || societyProfile.public_url
      );
      notificationInstance.success({
        title: 'Link copied',
        message: 'A link to society page has been copied to your clipboard!',
      });
    };

    if (!societyDashboard || !showProgressBar) {
      return null;
    }

    const renderPopoverContent = () => {
      return (
        <>
          {societyDashboard && (
            <GetHuzzleVerified
              openShortLinkModal={toggleEditShortLinkModal}
              copyInviteLink={copyInviteLink}
              dashboardData={societyDashboard}
              copyProfileLink={copyProfileLink}
              setShouldAllowPopoverClose={setShouldAllowPopoverClose}
              renderInPopover
            />
          )}
        </>
      );
    };

    const completedStepsPercents = (
      (completedSteps / steps.length) *
      100
    )?.toFixed();

    return (
      <>
        {!isVerified ||
        completedSteps !== steps.length ||
        societyProfile?.remove_huzzle_verified_banner == false ? (
          <Popover
            content={
              updateSlugLoading ? (
                <Spinner showBackDrop size="small" />
              ) : (
                renderPopoverContent()
              )
            }
            triggerClassName={contentClassNames}
            menuLeftOffset={5}
            shouldAllowClose={shouldAllowPopoverClose}
          >
            <div className={styles.progressContainer}>
              <div className={styles.progressInfo}>
                <Icon
                  iconName="icon_medal"
                  size="large"
                  className={styles.medalIcon}
                />
                <span className={styles.text}>
                  {isVerified && completedSteps === steps.length
                    ? 'Huzzle Verified! 🎉'
                    : 'Get Huzzle Verified'}
                </span>
                {!isVerified || completedSteps !== steps.length ? (
                  <p className={styles.percentage}>
                    {`${completedStepsPercents}%`}
                    <span>{`(${completedSteps}/${steps.length})`}</span>
                  </p>
                ) : (
                  <IconButton
                    iconName="close"
                    size="small"
                    className={styles.closeIcon}
                    onClick={onClose}
                  />
                )}
              </div>
              <div
                className={styles.progressBar}
                style={{ width: `${completedStepsPercents}%` }}
              />
            </div>
          </Popover>
        ) : null}
        <EditShortLinkModal
          open={showEditLinkModal}
          onClose={toggleEditShortLinkModal}
          slug={societyProfile?.short_slug}
          changeShortSlug={changeShortSlug}
          error={shortLinkError}
        />
      </>
    );
  }
);

SocietyProgressBar.displayName = 'SocietyProgressBar';
