import React, { memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components';
import styles from './CircularProgressBar.module.scss';

export interface CircularProgressBarProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** children */
  children?: React.ReactNode;
  /** size */
  size: 'xxxSmall' | 'xxSmall' | 'extraSmall' | 'small' | 'medium' | 'large';
  /** percent completed */
  percentCompleted: number;
  /**show icon below for percent completed */
  showIcon?: boolean;
  /**Progress to start form top */
  shouldStartFromTop?: boolean;
  /** To show percent inside the circle */
  showChildren?: boolean;
  strokeWidth?: string;
  showBackgroundPath?: boolean;
  pathClassName?: string;
  viewBox?: string;
}

export const CircularProgressBar: React.FC<CircularProgressBarProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    children,
    size,
    percentCompleted,
    showIcon = true,
    shouldStartFromTop = false,
    showChildren = true,
    strokeWidth = '1.5',
    showBackgroundPath = false,
    pathClassName = '',
    viewBox = '',
  }: CircularProgressBarProps) => {
    const contentClassNames = cn(
      {
        [styles.container]: true,
        [styles[size]]: true,
      },
      className
    );

    const percent =
      percentCompleted === Math.floor(percentCompleted)
        ? percentCompleted
        : percentCompleted.toFixed(1);

    const strokeDasharray = `${percentCompleted}, 100`;

    const isCompleted = percentCompleted === 100;

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="CircularProgressBar"
      >
        <svg
          viewBox={viewBox || '0 0 36 36'}
          className={cn({
            [styles.complete]: isCompleted,
            [styles.incomplete]: !isCompleted,
            [styles.top]: shouldStartFromTop,
            [styles.bottom]: !shouldStartFromTop,
          })}
        >
          {showBackgroundPath && (
            <path
              d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
              fill="none"
              strokeWidth={strokeWidth}
              className={styles.backgroundPath}
            ></path>
          )}
          <path
            d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            fill="none"
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
            className={cn({
              [pathClassName]: true,
              [styles.topPath]: !pathClassName,
            })}
          ></path>
        </svg>
        {children ? children : showChildren ? `${percent}%` : ''}
        {showIcon ? (
          isCompleted ? (
            <div className={cn(styles.icon, styles.complete)}>
              <Icon iconName="tick-mark" size="xxsmall" />
            </div>
          ) : (
            <div className={cn(styles.icon, styles.incomplete)}>
              <Icon iconName="caution" size="xsmall" />
            </div>
          )
        ) : null}
      </div>
    );
  }
);

CircularProgressBar.displayName = 'CircularProgressBar';
