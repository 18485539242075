import React, { memo, useMemo, useState } from 'react';
import Head from 'next/head';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

import { Icon, IconButton } from 'components';
import { Col, Row } from 'components/layout';
import { ButtonV3 } from 'components/ComponentV2';

import Avatar from 'components/Avatar';
import { Checkbox, TextArea } from 'components/form';
import { useNotification } from 'hooks/useNotification';
import { useAttendeesFilter } from 'pages/campaigns/[id]/useAttendeesFilter';
import { OpportunityAttributes } from 'lib/models/opportunity';
import { apiInstance } from 'lib/utils/axios';
import { API_ROUTES } from 'lib/api-routes';
import { InviteCandidate } from 'lib/models/candidate';
import { useAuth } from 'lib/providers/AuthProvider';
import { getOpportunityPublicPagePathname } from 'utils/urls';

import styles from './InviteEmail.module.scss';

export interface InviteEmailProps {
  /** Custom class name */
  className?: string;
  candidateSelected: Array<InviteCandidate>;
  onClose: () => void;
  onModalResolve: () => void;
  onBackClick: () => void;
  touchpoint?: OpportunityAttributes;
  isCommunityMemberInvitePop?: boolean;
}

export const InviteEmail: React.FC<InviteEmailProps> = memo(
  ({
    className = '', // custom class name
    candidateSelected,
    onClose,
    onModalResolve,
    onBackClick,
    touchpoint,
    isCommunityMemberInvitePop = false,
  }: InviteEmailProps) => {
    const contentClassNames = cn(
      {
        [styles.InviteEmail]: true,
      },
      className
    );
    const notificationInstance = useNotification();
    const [tCommon] = useTranslation('common');
    const { managerSociety } = useAuth();
    const { mutateInvitedGuests } = useAttendeesFilter();

    const [message, setMessage] = useState<string>('');
    const [isPermissionEnabled, setPermissionEnabled] = useState(false);

    const { id: touchpointId, title } = touchpoint || {};

    const onSendInvites = async () => {
      const body = {
        touchpoint_applications: candidateSelected.map((candidate) => ({
          email: candidate.email,
        })),
        notes: message,
      };

      try {
        const response = await apiInstance.post(`${API_ROUTES.SOCIETY_TOUCHPOINTS}/${touchpointId}/${API_ROUTES.INVITE_TOUCHPOINT_GUEST}`, body);
        if (response) {
          notificationInstance.information({
            message:
              candidateSelected?.length > 1
                ? tCommon('mail-invite-content.notification-success-multiple')
                : tCommon('mail-invite-content.notification-success'),
            timeout: 5000,
            icon: 'icon_check',
            showClose: true,
          });
          mutateInvitedGuests();
          onModalResolve();
        }
      } catch (error) {
        // @ts-ignore
        const message = error.response.data.data;
        notificationInstance.error({
          title: 'Error',
          message,
        });
      }
    };

    const onSendInviteToCommunityMember = async () => {
      const body = {
        student_society_registrations: candidateSelected.map((candidate) => ({
          email: candidate.email,
        })),
      };

      try {
        const response = await apiInstance.post(`${API_ROUTES.ADD_SOCIETY_SUBSCRIBERS}/create_multiple`, body);
        if (response) {
          notificationInstance.information({
            message:
              candidateSelected?.length > 1
                ? tCommon('mail-invite-content.notification-success-multiple')
                : tCommon('mail-invite-content.notification-success'),
            timeout: 5000,
            icon: 'icon_check',
            showClose: true,
          });
          onModalResolve();
        }
      } catch (error) {
        // @ts-ignore
        const message = error.response.data.data;
        notificationInstance.error({
          title: 'Error',
          message,
        });
      }
    };

    const invitedCandidateList = useMemo(() => {
      return candidateSelected.map((candidate) => {
        const { id, name, email, avatar_url } = candidate;
        return (
          <Row key={id} align="center" columnGap={12} wrap={false} className={styles.candidateRow}>
            <Avatar icon={avatar_url} name={name || email} size="small" />
            <Col span={20}>
              <p className={styles.name}>{name}</p>
              <p className={styles.email}>{email}</p>
            </Col>
          </Row>
        );
      });
    }, [candidateSelected]);

    const publicPathname = useMemo(() => {
      if (!touchpoint) return;

      const { touchpointable_type, slug } = touchpoint;
      const pathname = getOpportunityPublicPagePathname(touchpointable_type, slug);
      return `${window.location.origin}${pathname}`;
    }, [touchpoint]);

    return (
      <>
        <Head>
          <title>Invite | Huzzle</title>
          <meta name="description" content="Huzzle" />
        </Head>
        <section className={contentClassNames} data-testid="InviteEmail">
          <header className={styles.header}>
            <p>
              {isCommunityMemberInvitePop
                ? tCommon('mail-invite-content.invite-community-member.header-title')
                : tCommon('mail-invite-content.invite-event-guest.header-title')}
            </p>
            <IconButton iconName="close" size="small" className={styles.headerIcons} onClick={onClose} />
          </header>
          <div className={styles.content}>
            <div className={styles.sidebar}>
              <p className={styles.title}>
                {tCommon(
                  isCommunityMemberInvitePop
                    ? 'mail-invite-content.invite-community-member.sidebar-mail-title'
                    : 'mail-invite-content.invite-event-guest.sidebar-mail-title',
                  {
                    numberOfGuest: candidateSelected?.length,
                  }
                )}
              </p>
              <div className={styles.candidateList}>{invitedCandidateList}</div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.main}>
              <div className={styles['email-section']}>
                <p className={styles.subject}>
                  {isCommunityMemberInvitePop
                    ? tCommon('mail-invite-content.invite-community-member.email-subject', {
                        societyName: managerSociety?.name,
                      })
                    : tCommon('mail-invite-content.invite-event-guest.email-subject', {
                        societyName: managerSociety?.name,
                        touchpointTitle: title,
                      })}
                </p>
                {isCommunityMemberInvitePop ? null : (
                  <>
                    <TextArea
                      rows={5}
                      value={message}
                      placeholder={tCommon('mail-invite-content.invite-event-guest.email-placeholder')}
                      isDynamic
                      onChange={(e) => setMessage(e.target.value)}
                      textAreaClassName={styles.textarea}
                    />
                    <p className={styles.rsvp}>{tCommon('mail-invite-content.invite-event-guest.rsvp', { publicPathname })}</p>
                  </>
                )}
              </div>
              <Checkbox checked={isPermissionEnabled} onChange={() => setPermissionEnabled((flag) => !flag)} className={cn(styles.checkbox)}>
                {candidateSelected?.length > 1
                  ? tCommon('mail-invite-content.mandatory-checkbox-multiple')
                  : tCommon('mail-invite-content.mandatory-checkbox-single')}
              </Checkbox>
              <Row align="center" columnGap={8} className={cn(styles.desc, { [styles['desc-invite-popup']]: isCommunityMemberInvitePop })}>
                <Icon iconName="icon_info" />
                <p>{isCommunityMemberInvitePop ? tCommon('mail-invite-content.email-info-invite-pop') : tCommon('mail-invite-content.email-info')}</p>
              </Row>
            </div>
          </div>
          <Row align="center" justify="space-between" isFullWidthRow className={styles.footer}>
            <ButtonV3 variant="text" className={styles.buttonText} onClick={onBackClick}>
              <Row align="center">
                <Icon iconName="chevron-left" size="xlarge" className={styles.buttonIcon} />
                {tCommon('back')}
              </Row>
            </ButtonV3>
            <ButtonV3
              startIcon="icon_send-message"
              onClick={isCommunityMemberInvitePop ? onSendInviteToCommunityMember : onSendInvites}
              disabled={candidateSelected?.length === 0 || !isPermissionEnabled}
            >
              {tCommon('mail-invite-content.send-invites')}
            </ButtonV3>
          </Row>
        </section>
      </>
    );
  }
);

InviteEmail.displayName = 'InviteEmail';
