import React, { memo, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';

import { isEqual } from 'lodash';
import { IconButton } from 'components';
import Locations from 'components/candidate/Onboarding/Locations';
import { Industries } from 'components/candidate/Onboarding/Industries/Industries.component';
import RemotePreference from 'components/candidate/Onboarding/RemotePreference';
import { useNotification } from 'hooks/useNotification';
import { useSocietyProfile } from 'pages/society-profile/UseSocietyProfile.hook';
import { useCommonList } from 'lib/contexts/common-list-context';
import { MANAGER_MATCHING_PREFERENCE_STEPS } from 'lib/models/student-society';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from '../InviteCommunity.module.scss';

export interface MatchingPreferenceProps {
  /** Custom class name */
  className?: string;
  onClose: () => void;
  onBack: () => void;
  onDataUpdate: () => void;
}
export interface Inputs {
  interestable_id: string;
  interestable_type: string;
  weight: number;
}

export const MatchingPreference: React.FC<MatchingPreferenceProps> = memo(
  ({
    className = '', // custom class name
    onClose,
    onBack,
    onDataUpdate,
  }: MatchingPreferenceProps) => {
    const contentClassNames = cn(
      {
        [styles.Invite]: true,
      },
      className
    );
    const [t] = useTranslation('common');
    const notificationInstance = useNotification();
    const { updateManagerInfo } = useSocietyProfile();
    const { managerSociety } = useAuth();
    const { industry_ids, city_ids, country_ids } = managerSociety || {};
    const societiesIndustries = useMemo(() => {
      return (industry_ids || []).map((id) => ({
        interestable_id: id,
        interestable_type: 'Industry',
        weight: 1,
      }));
    }, [industry_ids]);

    const [remote_opportunity, setRemoteOpportunity] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [currentStep, setCurrentStep] = useState(MANAGER_MATCHING_PREFERENCE_STEPS.LOCATION);
    const [isBackLoading, setIsBackLoading] = useState(false);

    const { cityCountryList, countries, industryOptions } = useCommonList();
    const onBackClick = () => {
      if (currentStep == MANAGER_MATCHING_PREFERENCE_STEPS.INDUSTRIES && remote_opportunity) {
        setCurrentStep(MANAGER_MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE);
      } else if (currentStep == MANAGER_MATCHING_PREFERENCE_STEPS.INDUSTRIES && !remote_opportunity) {
        setCurrentStep(MANAGER_MATCHING_PREFERENCE_STEPS.LOCATION);
      } else if (currentStep == MANAGER_MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE) {
        setCurrentStep(MANAGER_MATCHING_PREFERENCE_STEPS.LOCATION);
      } else if (currentStep == MANAGER_MATCHING_PREFERENCE_STEPS.LOCATION) {
        onBack();
      }
      setIsBackLoading(false);
    };

    useEffect(() => {
      if (country_ids?.length == 0) {
        setRemoteOpportunity(false);
      } else {
        setRemoteOpportunity(true);
      }
    }, [country_ids]);

    const onIndustriesSubmitClick = async ({ selectedInterests, isBackClicked }: { selectedInterests: Array<Inputs>; isBackClicked?: boolean }) => {
      const selectedIndustriesId = selectedInterests.map((industries) => industries.interestable_id);
      try {
        if (isBackClicked) {
          setIsBackLoading(true);
        } else setIsLoading(true);

        const isEqualSelection = isEqual(selectedIndustriesId, industry_ids);
        const moveStep = () => {
          if (isBackClicked) {
            onBackClick();
          } else {
            setIsLoading(true);
            onBack();
          }
        };
        if (!isEqualSelection) {
          await updateManagerInfo({
            partialManagerAttribute: {
              industry_ids: selectedIndustriesId,
            },
          });
        }
        moveStep();
        onDataUpdate();
      } catch (error) {
        setIsLoading(false);
        setIsBackLoading(false);
        notificationInstance.handleExceptionError(error);
      }
    };
    const onLocationsSubmitClick = async (isRemoteSelected: boolean, locationsSelected: Array<string>, isBackClicked?: boolean) => {
      try {
        if (isBackClicked) {
          onDataUpdate();
          setIsBackLoading(true);
        } else setIsLoading(true);
        const isEqualSelection = isEqual(locationsSelected?.sort(), city_ids?.sort()) && isRemoteSelected === remote_opportunity;
        const moveStep = () => {
          if (isBackClicked) {
            if (isRemoteSelected) {
              setRemoteOpportunity(true);
            } else {
              setRemoteOpportunity(false);
            }
            onBackClick();
          } else {
            setIsLoading(false);
            if (isRemoteSelected) {
              setRemoteOpportunity(true);
              setCurrentStep(MANAGER_MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE);
            } else {
              setRemoteOpportunity(false);

              setCurrentStep(MANAGER_MATCHING_PREFERENCE_STEPS.INDUSTRIES);
            }
          }
        };
        if (!isEqualSelection) {
          await updateManagerInfo({
            partialManagerAttribute: {
              city_ids: locationsSelected,
            },
          });
          if (isRemoteSelected) {
            setRemoteOpportunity(true);
          } else {
            await updateManagerInfo({
              partialManagerAttribute: {
                country_ids: [],
              },
            });
            setRemoteOpportunity(false);
          }
        }
        moveStep();
      } catch (error) {
        setIsLoading(false);
        setIsBackLoading(false);
        notificationInstance.handleExceptionError(error);
      }
    };

    const onRemotePreferenceSubmitClick = async (countrySelected: Array<string>, isBackClicked?: boolean) => {
      try {
        if (isBackClicked) {
          setIsBackLoading(true);
        } else setIsLoading(true);

        const moveStep = () => {
          if (isBackClicked) {
            onBackClick();
          } else {
            setCurrentStep(MANAGER_MATCHING_PREFERENCE_STEPS.INDUSTRIES);
            setIsLoading(false);
          }
        };

        const isEqualSelection = isEqual(countrySelected?.sort(), country_ids?.sort());
        if (!isEqualSelection) {
          await updateManagerInfo({
            partialManagerAttribute: {
              country_ids: countrySelected,
            },
          });
        }
        moveStep();
      } catch (error) {
        setIsLoading(false);
        setIsBackLoading(false);
        notificationInstance.handleExceptionError(error);
      }
    };

    const matchingPreferencesQuestion = () => {
      switch (currentStep) {
        case MANAGER_MATCHING_PREFERENCE_STEPS.LOCATION:
          return (
            <Locations
              cityCountryList={cityCountryList}
              countries={countries}
              candidateLocations={city_ids || []}
              remote_opportunity={remote_opportunity}
              onNextClick={onLocationsSubmitClick}
              isLoading={isLoading}
              isBackLoading={isBackLoading}
              isMatchingPreferences={true}
              isSocietyMatchingPreference={true}
            />
          );
        case MANAGER_MATCHING_PREFERENCE_STEPS.REMOTE_PREFERENCE:
          return (
            <RemotePreference
              candidateChoice={country_ids || []}
              countries={countries}
              onNextClick={onRemotePreferenceSubmitClick}
              isLoading={isLoading}
              isBackLoading={isBackLoading}
              isSocietyMatchingPreference={true}
              isMatchingPreferences={true}
            />
          );
        case MANAGER_MATCHING_PREFERENCE_STEPS.INDUSTRIES:
          return (
            <Industries
              candidateIndustries={societiesIndustries}
              industries={industryOptions}
              onNextClick={onIndustriesSubmitClick}
              isLoading={isLoading}
              isBackLoading={isBackLoading}
              isMatchingPreferences={true}
              isSocietyMatchingPreference={true}
              remote_opportunity={remote_opportunity}
            />
          );
      }
    };

    return (
      <section className={contentClassNames} data-testid="Invite">
        <header className={styles.header}>
          <p>{t('invite-community-members-modal.header.title')}</p>
          <IconButton className={styles.headerIcons} iconName="close" size="small" onClick={onClose} />
        </header>
        <div className={cn(styles.content, styles['content-mp'])}>{matchingPreferencesQuestion()}</div>
      </section>
    );
  }
);

MatchingPreference.displayName = 'MatchingPreference';
