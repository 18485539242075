import React from 'react';
import dynamic from 'next/dynamic';

import cn from 'classnames';
import { IEmojiData, IEmojiPickerProps } from 'emoji-picker-react';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { useOnClickOutside } from 'hooks/useOnClickOutside';

import styles from './CustomLinkCard.module.scss';

interface LinkCardProps {
  onCardClick: (index: number) => void;
  index: number;
  link: { title: string; link: string; emoji?: string; selected: boolean };
  changeLinkData: (index: number, selected: boolean, emoji?: string) => void;
  className?: string;
}

const EmojiPickerNoSSRWrapper = dynamic<IEmojiPickerProps>(
  () => import('emoji-picker-react'),
  {
    ssr: false,
  }
);

export const LinkCard: React.FC<LinkCardProps> = ({
  onCardClick,
  index,
  link,
  changeLinkData,
  className: classes,
}) => {
  const pickerRef = React.useRef(null);

  const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false);

  const onEmojiClick = (
    event: React.MouseEvent<Element, MouseEvent>,
    emojiObject: IEmojiData,
    index: number
  ) => {
    event.preventDefault();
    event.stopPropagation();
    changeLinkData(index, link.selected, emojiObject.emoji);
    setEmojiPickerOpen(false);
  };

  useOnClickOutside({
    ref: pickerRef,
    onOutsideClick: () => setEmojiPickerOpen(false),
  });

  return (
    <div
      key={index}
      onClick={() => {
        onCardClick(index);
      }}
      className={cn(styles.customLink, classes)}
    >
      <div className={styles.customLinkEmoji}>
        <>
          <Tooltip
            content={link.emoji ? 'Change emoji' : 'Add emoji'}
            position="top"
            xAlign="center"
            color="dark"
            className={cn(styles.tooltipContainer, {
              [styles.shortTooltip]: !link.emoji,
            })}
          >
            <div
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setEmojiPickerOpen(true);
              }}
            >
              {link.emoji ? (
                <>
                  <div className={styles.savedEmoji}>{link.emoji}</div>
                  <Icon
                    iconName="icon_pencil"
                    size="large"
                    className={styles.hoverClassIcon}
                  />
                </>
              ) : (
                <Icon iconName="icon_add-emoji" size="large" />
              )}
            </div>
          </Tooltip>

          {emojiPickerOpen ? (
            <div
              className={styles.customLinkEmojiPicker}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              ref={pickerRef}
            >
              <EmojiPickerNoSSRWrapper
                onEmojiClick={(event, data) => onEmojiClick(event, data, index)}
                disableAutoFocus={true}
                groupNames={{ smileys_people: 'PEOPLE' }}
                native
              />
            </div>
          ) : null}
        </>
      </div>
      <div className={styles.customLinkText}>
        <div className={styles.customLinkTextTitle}>{link.title}</div>
        <div className={styles.customLinkTextLink}>{link.link}</div>
      </div>

      <button
        className={styles.customLinkSelection}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          changeLinkData(index, !link.selected, link.emoji);
        }}
      >
        <Icon
          iconName={
            link.selected
              ? 'icon_checkbox-selected'
              : 'icon_checkbox-unselected'
          }
          size="large"
          className={styles.customLinkCheckbox}
        />
      </button>
    </div>
  );
};
