import { Option } from 'lib/models/option';
import { TagAttributes, TagsResponse, TagType } from 'lib/models/tag';
import { HighlightsResponse, HighlightAttributes } from 'lib/models/highlight';
import { FlagshipEventAttributes } from 'lib/models/flagship-event';
import { CommitteeAttributes, CommitteeResponse } from 'lib/models/committee';
import { StatsAttributes } from 'lib/models/stats';
import { CustomLinksAttributes } from 'lib/models/custom-links';
import { MultiLinksResponse } from './custom-links';
import { FollowingResponse } from './following';
import { MetaTagsResponse } from './meta-tag';
import { MultiStatsResponse } from './stats';
import { sponsorCompaniesResponse } from './sponsor-companies';
import { FlagshipEventsResponse } from './flagship-event';
import { UniversityResponse } from './university';
import { TagResponse } from './tag';
import { TouchpointType } from './touchpoint';
import { SponsorShipPlanResponseData } from './sponsorship-plans';
import { BasicCompaniesResponse } from './company';

export enum TPostParticipant {
  ANYONE = 'anyone',
  TEAM_ONLY = 'team_only',
  MEMBERS_ONLY = 'members_only',
  NO_ONE = 'no_one',
}

export interface StudentSocietyAttributes {
  unseen_count: number;
  id: string;
  name: string;
  description: string;
  available_invite_limit: number;
  icon_url: string;
  banner_url: string;
  instagram_url: string;
  facebook_url: string;
  linkedin_url: string;
  twitter_url: string;
  website: string;
  student_union_url: string;
  slug: string;
  email: string;
  phone_number: string;
  qr_code_url: string;
  university: UniversityResponse;
  topics: TagsResponse<TagType.Topic>;
  offerings: TagsResponse<TagType.Offering>;
  flagship_events: FlagshipEventsResponse;
  sponsorships: sponsorCompaniesResponse;
  managers: CommitteeResponse;
  student_societies_highlights: HighlightsResponse;
  stats: MultiStatsResponse;
  is_verified: boolean;
  primary_topic: TagResponse<TagType.Topic>;
  meta_title: string;
  meta_description: string;
  meta_tags: MetaTagsResponse;
  following: FollowingResponse | null;
  address: string;
  entityId?: string;
  short_description: string;
  following_count: number;
  custom_links: MultiLinksResponse;
  subscribers_count: number;
  subscribed_avatar_urls: Array<string>;
  subscribed: boolean;
  authenticated?: boolean;
  public_url: string;
  short_link_qr_code_url: string;
  short_slug: string;
  remove_huzzle_verified_banner: boolean;
  short_link: string;
  huzzle_verified: boolean;
  is_huzzle_verified: boolean;
  subscribers_require_approval: boolean;
  invite_later_banner_hidden: boolean;
  touchpoints_count: number;
  sponsorship_plans: SponsorShipPlanResponseData;
  total_views: number;
  attendees_count: number;
  awaiting_invitation_acceptance: boolean;
  awaiting_approval: boolean;
  who_can_post: Exclude<TPostParticipant, TPostParticipant.NO_ONE>;
  who_can_reply_to_posts: TPostParticipant;
  show_media_uploads_from_posts: TPostParticipant;
  is_post_approval_required: boolean;
  industry_ids: Array<string>;
  city_ids: Array<string>;
  country_ids: Array<string>;
  fill_data_email_sent_at: string;
  sponsor_companies: BasicCompaniesResponse;
}
export enum MANAGER_MATCHING_PREFERENCE_STEPS {
  LOCATION = 1,
  REMOTE_PREFERENCE = 2,
  INDUSTRIES = 3,
}

export type TBasicStudentSociety = {
  id: string;
  name: string;
  slug: string;
  short_slug: string;
  icon_url: string;
  university_name: string;
  is_huzzle_verified: boolean;
};

export type TStudentSociety = StudentSocietyAttributes;

export interface StudentSociety {
  id: string;
  type: 'student_society';
  attributes: StudentSocietyAttributes;
}

export interface BasicStudentSociety {
  id: string;
  type: 'basic_student_society';
  attributes: TBasicStudentSociety;
}

export interface BasicStudentSocietiesResponse {
  data: Array<BasicStudentSociety>;
  meta: {
    total: number;
  };
}

export interface StudentSocietiesResponse {
  data: Array<StudentSociety>;
  meta: {
    total: number;
  };
}

export interface StudentSocietyResponse {
  data: StudentSociety;
  meta: { total_views: number; attendees_count: number };
}

export interface StudentSocietyRequest {
  name?: string;
  description?: string;
  short_description?: string;
  university_id?: string;
  instagram_url?: string;
  facebook_url?: string;
  linkedin_url?: string;
  website?: string;
  logo?: FileList | string;
  banner?: FileList | [] | string;
  short_slug?: string;
  remove_huzzle_verified_banner?: boolean;
}

export interface SocietyProfileDefaultValuesType {
  logo: string;
  banner: string;
  name: string;
  description: string;
  short_description: string;
  university_id: string;
  address: string;
  instagram_url: string;
  facebook_url: string;
  linkedin_url: string;
  website: string;
  student_union_url: string;
  custom_links_attributes: Array<CustomLinksAttributes>;
  topic_ids:
    | Array<TagAttributes<TagType.Topic>>
    | Array<Option & { isPrimary?: boolean }>;
  stats_attributes: Array<StatsAttributes>;
  offering_ids: Array<TagAttributes<TagType.Offering>> | Array<Option>;
  flagship_events_attributes: Array<FlagshipEventAttributes>;
  managers_attributes: Array<CommitteeAttributes>;
  student_societies_highlights_attributes: Array<HighlightAttributes>;
}

export const PER_PAGE_COUNT = 20;

export interface SocietyDashboardAttributes {
  huzzle_verified: boolean;
  profile_complete: boolean;
  basic_info_present: boolean;
  details_info_present: boolean;
  external_link_present: boolean;
  social_media_links_present: boolean;
  topic_present: boolean;
  managers_present: boolean;
  profile_complete_percentage: number;
  short_link_claimed: boolean;
  touchpoints_count: number;
  managers_count: number;
  messaged_to_other_society: boolean;
  touchpoints_with_live_application: TouchpointsDetails[];
  unread_messages_count: number;
  event_hightlighted_count: number;
  live_events_touchpoint_applications_avatar_url: Array<{
    avatar_url: string;
    email: string;
    name: string;
  }>;
  live_touchpoints_details: TouchpointsDetails[];
  total_count: number;
  event_status_count_mapping: {
    draft: number;
    published: number;
    expired: number;
    ready_for_approval: number;
  };
  live_touchpoints_count: number;
  closed_touchpoint_count: number;
  live_touchpoint_applications_count: number;
  subscribers_count: number;
  this_month_subscribers_count: number;
  views_total: number;
  views_today: number;
  views_in_last_7: number;
  views_in_last_30: number;
  clicks_total: number;
  clicks_today: number;
  clicks_in_last_7: number;
  clicks_in_last_30: number;
  society_referrals_count: number;
  manager_referrals_count: number;
}

export interface InviteAttendeesResponse {
  message: string;
  invited_count: number;
}

export interface StudentSocietyRegistrationStats {
  event_views: number;
  profile_views: number;
  invited_count: number;
  attendees_count: number;
  saved_count: number;
  subscribers_count: number;
  subscription_requests_count: number;
  sponsorship_request_count: number;
}

export interface TouchpointsDetails {
  id: string;
  title: string;
  touchpointable_type: TouchpointType;
  application_count: number;
}
