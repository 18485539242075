import React, { memo } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import Popover from 'components/Popover';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Row from 'components/layout/Row';
import { Col } from 'components/layout/Grid';
import { ActionMenuButton } from 'components/ActionMenuButton/ActionMenuButton.component';
import Logo from 'components/Logo';
import CircularProgressBar from 'components/CircularProgressBar';
import Tooltip from 'components/Tooltip';
import { useCommon } from 'hooks/useCommon';
import { useSocietyProfile } from 'pages/society-profile/UseSocietyProfile.hook';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';
import SocietyProgressBar from '../SocietyProgressBar';
import { ReferralPopup } from '../ReferralPopup/ReferralPopup.component';

import styles from './SidebarManager.module.scss';

export interface SidebarManagerProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
}

export const SidebarManager: React.FC<SidebarManagerProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
  }: SidebarManagerProps) => {
    const { onShare } = useCommon();
    const router = useRouter();
    const { societyDashboard, societyRegistrationStats } = useSocietyProfile();
    const { user, userName, managerSociety: societyProfile } = useAuth();

    const contentClassNames = cn(
      {
        [styles.Sidebar]: true,
      },
      className
    );

    const referral_code = user?.referral_code || null;

    const {
      unread_messages_count = 0,
      event_hightlighted_count = 0,
      society_referrals_count = 0,
      manager_referrals_count = 0,
      profile_complete,
      short_link_claimed,
      managers_count,
      messaged_to_other_society,
      touchpoints_count,
      subscribers_count,
      external_link_present,
    } = societyDashboard || {};

    const { sponsorship_request_count = 0 } = societyRegistrationStats || {};

    const handleCopyReferralLink = () => {
      onShare(
        'Referral Signup',
        `${window.location.origin}${PAGE_ROUTES.MANAGER_SIGN_UP}?referrer=${referral_code}`,
        {
          title: 'Link copied',
          message:
            'Society signup link with referral code has been copied to your clipboard!',
        }
      );
    };

    const referalPercentage = (society_referrals_count / 15) * 100;
    const inviteCommittee = managers_count && managers_count > 1;

    const steps = [
      {
        id: 'claim_shortlink',
        isComplete: short_link_claimed,
      },
      {
        id: 'complete_profile',
        isComplete: profile_complete,
      },
      {
        id: 'invite_committee',
        isComplete: inviteCommittee,
      },
      {
        id: 'post_event',
        isComplete: touchpoints_count && touchpoints_count > 0,
      },
      {
        id: 'message_society',
        isComplete: messaged_to_other_society,
      },
      {
        id: 'add_subscribers',
        isComplete: subscribers_count && subscribers_count >= 10,
      },
      {
        id: 'add_external_links',
        isComplete: external_link_present,
      },
    ];

    const completedSteps = steps.filter((step) => step.isComplete)?.length;

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="SidebarManager"
      >
        <div className={styles.header}>
          {societyProfile && (
            <ActionMenuButton
              className={styles.profile}
              menuButtonClass={styles['dropdown-menu']}
              menuButtonOpenClassName={styles['dropdown-open']}
              buttonElement={
                <Row
                  align="center"
                  justify="start"
                  columnGap={8}
                  wrap={false}
                  className={styles.title}
                >
                  <Avatar
                    className={styles.avatar}
                    icon={societyProfile.icon_url}
                    name={societyProfile.name}
                    size="small"
                  ></Avatar>

                  {steps.length === completedSteps && (
                    <Tooltip
                      content="Huzzle Verified"
                      position="bottom"
                      xAlign="left"
                      color="dark"
                      className={styles.tooltip}
                      containerClassName={styles['tooltip-container']}
                      disabled={steps.length !== completedSteps}
                    >
                      <div className={styles['verified-icon']}>
                        <Icon
                          iconName="icon_star"
                          size="auto"
                          className={styles.starIcon}
                        />
                      </div>
                    </Tooltip>
                  )}
                  <Col>
                    <div className={styles.row}>
                      <p className={styles.name}>{societyProfile.name}</p>
                      <Icon
                        iconName="icon_arrow-down"
                        size="xsmall"
                        className={styles.icon}
                      />
                    </div>
                    <p className={styles.description}>{userName}</p>
                  </Col>
                </Row>
              }
              options={[
                {
                  id: '1',
                  label: 'Logout',
                  onClick: () => {
                    router.push(`${PAGE_ROUTES.LOGOUT}?type=manager`);
                  },
                },
              ]}
              listClassName={styles.options}
              optionClassName={styles.logout}
            />
          )}
          <SocietyProgressBar steps={steps} completedSteps={completedSteps} />
        </div>

        <div className={styles.section}>
          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.DASHBOARD)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.DASHBOARD,
            })}
          >
            <Icon
              iconName={'icon_new_layout-11'}
              className={cn(styles.sectionIcon, {
                [styles.iconActive]: router.pathname === PAGE_ROUTES.DASHBOARD,
              })}
            />
            <p
              className={cn({
                [styles.textActive]: router.pathname === PAGE_ROUTES.DASHBOARD,
              })}
            >
              Dashboard
            </p>
          </Row>

          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.SOCIETY_PROFILE_EDIT)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.SOCIETY_PROFILE_EDIT,
            })}
          >
            <Icon
              iconName={
                router.pathname === PAGE_ROUTES.SOCIETY_PROFILE_EDIT
                  ? 'icon_paint-up_filled'
                  : 'icon_paint-up'
              }
              className={cn(styles.sectionIcon, {
                [styles.iconActive]:
                  router.pathname === PAGE_ROUTES.SOCIETY_PROFILE_EDIT,
              })}
            />
            <p
              className={cn({
                [styles.textActive]:
                  router.pathname === PAGE_ROUTES.SOCIETY_PROFILE_EDIT,
              })}
            >
              Profile
            </p>
          </Row>

          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.COMMITTEE)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.COMMITTEE,
            })}
          >
            <Icon
              iconName={'badge'}
              className={cn(styles.sectionIcon, {
                [styles.iconActive]: router.pathname === PAGE_ROUTES.COMMITTEE,
              })}
            />
            <p
              className={cn({
                [styles.textActive]: router.pathname === PAGE_ROUTES.COMMITTEE,
              })}
            >
              Committee
            </p>
          </Row>

          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.COMMUNITY)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.COMMUNITY,
            })}
          >
            <Icon
              iconName={
                router.pathname === PAGE_ROUTES.COMMUNITY
                  ? 'icon_users-wm-fill-regular-small-fill'
                  : 'icon_users-wm-fill-regular-small'
              }
              className={cn(styles.sectionIcon, {
                [styles.iconActive]: router.pathname === PAGE_ROUTES.COMMUNITY,
              })}
            />
            <p
              className={cn({
                [styles.textActive]: router.pathname === PAGE_ROUTES.COMMUNITY,
              })}
            >
              Community
            </p>
          </Row>

          <Row
            align="center"
            justify="space-between"
            onClick={() => router.push(PAGE_ROUTES.CAMPAIGNS)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.CAMPAIGNS,
            })}
          >
            <Row columnGap={10}>
              <Icon
                iconName={
                  router.pathname === PAGE_ROUTES.CAMPAIGNS
                    ? 'icon_party_filled'
                    : 'icon_party'
                }
                className={cn(styles.sectionIcon, {
                  [styles.iconActive]:
                    router.pathname === PAGE_ROUTES.CAMPAIGNS,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]:
                    router.pathname === PAGE_ROUTES.CAMPAIGNS,
                })}
              >
                Events
              </p>
            </Row>
            {event_hightlighted_count > 0 ? (
              <p className={styles.new}>{event_hightlighted_count}</p>
            ) : null}
          </Row>

          <Row
            align="center"
            justify="space-between"
            onClick={() => router.push(PAGE_ROUTES.MESSAGES)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.MESSAGES,
            })}
          >
            <Row columnGap={10}>
              <Icon
                iconName={
                  router.pathname === PAGE_ROUTES.MESSAGES
                    ? 'icon_chat-filled'
                    : 'icon_chat'
                }
                className={cn(styles.sectionIcon, {
                  [styles.iconActive]: router.pathname === PAGE_ROUTES.MESSAGES,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]: router.pathname === PAGE_ROUTES.MESSAGES,
                })}
              >
                Messages
              </p>
            </Row>
            {unread_messages_count > 0 ? (
              <p className={styles.new}>{unread_messages_count}</p>
            ) : null}
          </Row>

          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.NEWSLETTERS)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.NEWSLETTERS,
            })}
          >
            <Icon
              iconName={
                router.pathname === PAGE_ROUTES.NEWSLETTERS
                  ? 'icon_mail_filled'
                  : 'icon_mail'
              }
              className={cn(styles.sectionIcon, {
                [styles.iconActive]:
                  router.pathname === PAGE_ROUTES.NEWSLETTERS,
              })}
            />
            <p
              className={cn({
                [styles.textActive]:
                  router.pathname === PAGE_ROUTES.NEWSLETTERS,
              })}
            >
              Newsletters
            </p>
          </Row>

          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.DISCUSSIONS)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.DISCUSSIONS,
            })}
          >
            <Icon
              iconName={
                router.pathname === PAGE_ROUTES.DISCUSSIONS
                  ? 'discussion-filled'
                  : 'discussion'
              }
              className={cn(styles.sectionIcon, {
                [styles.iconActive]:
                  router.pathname === PAGE_ROUTES.DISCUSSIONS,
              })}
            />
            <p
              className={cn({
                [styles.textActive]:
                  router.pathname === PAGE_ROUTES.DISCUSSIONS,
              })}
            >
              Discussions
            </p>
          </Row>

          <Row
            align="center"
            justify="space-between"
            onClick={() => router.push(PAGE_ROUTES.SOCIETY_SPONSORSHIPS)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.SOCIETY_SPONSORSHIPS,
            })}
          >
            <Row columnGap={10}>
              <Icon
                iconName={
                  router.pathname === PAGE_ROUTES.SOCIETY_SPONSORSHIPS
                    ? 'icon_coins_filled'
                    : 'icon_coins'
                }
                className={cn(styles.sectionIcon, {
                  [styles.iconActive]:
                    router.pathname === PAGE_ROUTES.SOCIETY_SPONSORSHIPS,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]:
                    router.pathname === PAGE_ROUTES.SOCIETY_SPONSORSHIPS,
                })}
              >
                Sponsorships
              </p>
            </Row>
            {sponsorship_request_count > 0 && (
              <p className={styles.new}>{sponsorship_request_count}</p>
            )}
          </Row>

          <div className={styles.separator}></div>

          <Popover
            className={styles.popover}
            contentClassName={styles.menu}
            triggerClassName={styles.popoverTrigger}
            menuLeftOffset={5}
            content={
              <ReferralPopup
                title="Referral program"
                message="Share this referral link with other societies. When they sign up for a Huzzle account, you'll earn rewards!"
                onCopyLink={handleCopyReferralLink}
                societyReferralsCount={society_referrals_count}
                managerReferralsCount={manager_referrals_count}
              />
            }
          >
            <Row
              align="center"
              justify="space-between"
              className={cn(styles.popoverRow, styles.sectionRow)}
            >
              <Row columnGap={10}>
                <Icon iconName={'share'} className={styles.sectionIcon} />
                <p>Referral program</p>
              </Row>
              {referalPercentage < 100 && (
                <CircularProgressBar
                  percentCompleted={referalPercentage}
                  size={'xxSmall'}
                  showIcon={false}
                  showChildren={false}
                  showBackgroundPath={true}
                  strokeWidth="5"
                  className={styles.progressCircle}
                  pathClassName={styles.progressCirclePath}
                />
              )}
            </Row>
          </Popover>

          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.STUDENT_SOCIETIES)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES,
            })}
          >
            <Icon
              iconName={
                router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES
                  ? 'icon_multiple-11_filled'
                  : 'icon_multiple-11'
              }
              className={cn(styles.sectionIcon, {
                [styles.iconActive]:
                  router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES,
              })}
            />
            <p
              className={cn({
                [styles.textActive]:
                  router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES,
              })}
            >
              Explore societies
            </p>
          </Row>
          <Row
            columnGap={10}
            onClick={() => router.push(PAGE_ROUTES.CAREER_OPPORTUNITY)}
            className={cn(styles.sectionRow, {
              [styles.sectionRowActive]:
                router.pathname === PAGE_ROUTES.CAREER_OPPORTUNITY,
            })}
          >
            <Icon
              iconName={
                router.pathname === PAGE_ROUTES.CAREER_OPPORTUNITY
                  ? 'icon_briefcase_filled'
                  : 'icon_briefcase'
              }
              className={cn(styles.sectionIcon, {
                [styles.iconActive]:
                  router.pathname === PAGE_ROUTES.CAREER_OPPORTUNITY,
              })}
            />
            <p
              className={cn({
                [styles.textActive]:
                  router.pathname === PAGE_ROUTES.CAREER_OPPORTUNITY,
              })}
            >
              Career opportunities
            </p>
          </Row>
        </div>

        <div className={styles.divForSpacing}></div>

        <div className={styles.footer}>
          <div className={styles.separator}></div>
          <div
            onClick={() => router.push(PAGE_ROUTES.DASHBOARD)}
            className={styles.logo}
          >
            <Logo name="huzzle.society" size="xsmall" color="black" />
          </div>
        </div>
      </div>
    );
  }
);

SidebarManager.displayName = 'SidebarManager';
