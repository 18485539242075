import { useRecoilValue } from 'recoil';
import useSWR, { KeyedMutator } from 'swr';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useAttendeesFilter } from 'pages/campaigns/[id]/useAttendeesFilter';
import { useCampaigns } from 'lib/contexts/campaigns-context';
import { post, remove, get, patch } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';

import { EventAttributes, EventResponse } from 'lib/models/event';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';

import { parseResponse } from 'lib/utils/parser';
import {
  CohostApplications,
  CohostAttributes,
  CohostResponse,
  CohostsResponse,
} from 'lib/models/cohost';
import { Notice } from 'lib/models/notice';
import { PAGE_ROUTES } from 'lib/page-routes';
import { TouchpointState } from 'lib/models/opportunity';
import { useAuth } from 'lib/providers/AuthProvider';
import { useNotification } from './useNotification';

interface ReturnType {
  addCohost: (
    touchpointId: string,
    cohostId: string
  ) => Promise<CohostAttributes | undefined>;
  removeCohost: (cohostId: string) => Promise<Notice | undefined>;
  getTouchpointCohosts: (
    touchpointId: string
  ) => Promise<EventAttributes | undefined>;
  receivedCohostInvites: CohostsResponse | null;
  mutateCohostApplications: KeyedMutator<CohostApplications>;
  updateCohostApplicationState: ({
    id,
    status,
  }: {
    id: string;
    status: TouchpointState;
  }) => Promise<CohostAttributes | undefined>;
  onAcceptInvite: (id: string) => void;
  onDeclineInvite: (id: string) => void;
  resendInviteMail: (id: string) => Promise<Notice | undefined>;
}

export const useCohost = (): ReturnType => {
  const notificationInstance = useNotification();
  const { headers } = useRecoilValue(httpHeadersState);
  const router = useRouter();
  const { isManager } = useAuth();

  const { mutateEvents } = useCampaigns();
  const { mutateTouchpoint } = useAttendeesFilter();

  const cohostApplicationsRoute = isManager
    ? API_ROUTES.SOCIETY_COHOST_APPLICATIONS
    : API_ROUTES.COMPANY_COHOST_APPLICATIONS;

  const touchpointCohostRoute = isManager
    ? API_ROUTES.SOCIETY_TOUCHPOINTS
    : API_ROUTES.COMPANY_TOUCHPOINTS;

  const addCohost = async (touchpointId: string, cohostId: string) => {
    const body = {
      cohost_application: {
        touchpoint_id: touchpointId,
        cohost_id: cohostId,
      },
    };
    try {
      const response = await post<CohostResponse>(
        cohostApplicationsRoute,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const removeCohost = async (cohostId: string) => {
    try {
      const response = await remove<Notice>(
        `${cohostApplicationsRoute}/${cohostId}`,
        headers
      );
      if (response) {
        notificationInstance.success({
          title: 'Success!',
          message: 'Co-host removed successfully.',
        });
        return response;
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getTouchpointCohosts = async (touchpointId: string) => {
    try {
      const response = await get<EventResponse>(
        `${touchpointCohostRoute}/${touchpointId}`,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const { data: cohostResponse, mutate: mutateCohostApplications } =
    useSWR<CohostApplications>([cohostApplicationsRoute, headers], get, {
      revalidateOnFocus: false,
    });

  const receivedCohostInvites = useMemo(
    () => (cohostResponse ? cohostResponse.received : null),
    [cohostResponse]
  );

  const updateCohostApplicationState = async ({
    id,
    status,
  }: {
    id: string;
    status: TouchpointState;
  }) => {
    const body = {
      cohost_application: {
        status,
      },
    };
    try {
      const response = await patch<CohostResponse>(
        `${cohostApplicationsRoute}/${id}`,
        body,
        headers
      );
      return parseResponse(response);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onAcceptInvite = async (id: string) => {
    try {
      const response = await updateCohostApplicationState({
        id,
        status: TouchpointState.ACCEPTED,
      });
      if (response) {
        mutateCohostApplications();
        if (
          router.pathname === PAGE_ROUTES.EVENT_MANAGEMENT_DASHBOARD &&
          mutateTouchpoint
        ) {
          mutateTouchpoint();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onDeclineInvite = async (id: string) => {
    try {
      const response = await updateCohostApplicationState({
        id,
        status: TouchpointState.REJECTED,
      });
      if (response) {
        mutateCohostApplications();
        if (
          router.pathname === PAGE_ROUTES.EVENT_MANAGEMENT_DASHBOARD &&
          mutateTouchpoint
        ) {
          mutateTouchpoint();
        } else if (router.pathname === PAGE_ROUTES.CAMPAIGNS) {
          mutateEvents();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resendInviteMail = async (id: string) => {
    try {
      const pathname = `${cohostApplicationsRoute}/${id}/resend_invitation`;
      const body = {};
      return await post<Notice>(pathname, body, headers);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    addCohost,
    getTouchpointCohosts,
    removeCohost,
    receivedCohostInvites,
    mutateCohostApplications,
    updateCohostApplicationState,
    onAcceptInvite,
    onDeclineInvite,
    resendInviteMail,
  };
};
