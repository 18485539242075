import React, { useMemo } from 'react';
import { useRouter } from 'next/router';

import cn from 'classnames';

import { Icon } from 'components';
import { SlideShow } from 'components/SlideShow/SlideShow.component';
import { ButtonV3 } from 'components/ComponentV2';
import { PAGE_ROUTES } from 'lib/page-routes';
import { SocietyDashboardAttributes } from 'lib/models/student-society';
import { AddSubscribers } from '../AddSubscribers/AddSubscribers.component';
import { AddExternalLinks } from '../AddExternalLinks/AddExternalLinks.component';

import styles from './GetHuzzleVerified.module.scss';

interface GetHuzzleVerifiedProps {
  openShortLinkModal: () => void;
  copyInviteLink: () => void;
  copyProfileLink: () => void;
  dashboardData: SocietyDashboardAttributes;
  renderInPopover?: boolean;
  setShouldAllowPopoverClose?: (shouldAllow: boolean) => void;
}

export const GetHuzzleVerified: React.FC<GetHuzzleVerifiedProps> = (
  props: GetHuzzleVerifiedProps
) => {
  const {
    openShortLinkModal,
    copyInviteLink,
    dashboardData,
    renderInPopover,
    setShouldAllowPopoverClose,
  } = props;
  const {
    short_link_claimed,
    profile_complete,
    managers_count,
    messaged_to_other_society,
    touchpoints_count,
    subscribers_count,
    external_link_present,
  } = dashboardData;
  const inviteCommittee = useMemo(() => managers_count > 1, [managers_count]);
  const router = useRouter();

  const editSocietyProfile = () => {
    router.push(PAGE_ROUTES.SOCIETY_PROFILE_EDIT);
  };
  const exploreSocieties = () => {
    router.push(PAGE_ROUTES.STUDENT_SOCIETIES);
  };

  const createNewEvent = () => {
    router.push(`${PAGE_ROUTES.EVENTS_CREATE}?step=1&type=Event`);
  };
  const getHuzzleVerifiedData = useMemo(
    () => [
      {
        id: 'claim_shortlink',
        title: 'Claim your shortlink',
        caption:
          'A single link for your members to engage with your events, links and easily get updates.',
        buttonText: 'Edit shortlink',
        isComplete: short_link_claimed,
        isPrimary: !short_link_claimed,
        action: openShortLinkModal,
      },
      {
        id: 'complete_profile',
        title: 'Fill your society profile',
        caption:
          'Keep your society profile up to date to show off to companies and students.',
        buttonText: 'Complete profile',
        isComplete: profile_complete,
        isPrimary: short_link_claimed,
        action: editSocietyProfile,
      },
      {
        id: 'invite_committee',
        title: 'Add committee members',
        caption: 'Invite your team to make the most of Huzzle.',
        buttonText: 'Copy invite link',
        isComplete: inviteCommittee,
        isPrimary: short_link_claimed && profile_complete,
        action: copyInviteLink,
      },
      {
        id: 'post_event',
        title: 'Post your first event',
        caption:
          'Increase your members attendance with Huzzle and reach new potential members.',
        buttonText: 'Create new',
        isComplete: touchpoints_count > 0,
        isPrimary: short_link_claimed && profile_complete && inviteCommittee,
        action: createNewEvent,
      },
      {
        id: 'message_society',
        title: 'Message other societies',
        caption: 'Expand your reach by co-hosting events with other societies.',
        buttonText: 'Explore societies',
        isComplete: messaged_to_other_society,
        isPrimary:
          short_link_claimed &&
          profile_complete &&
          inviteCommittee &&
          touchpoints_count > 0,
        action: exploreSocieties,
      },
      {
        id: 'add_subscribers',
        title: 'Get 10 subscribers',
        caption: 'Boost your audience! Get more subscribers now.',
        buttonText: 'Add subscribers',
        isComplete: subscribers_count >= 10,
        isPrimary:
          short_link_claimed &&
          profile_complete &&
          inviteCommittee &&
          touchpoints_count > 0 &&
          messaged_to_other_society,
        action: () => {},
      },
      {
        id: 'add_external_links',
        title: 'Add external links',
        caption:
          'Add links to external pages you want to share with your members and track the metrics.',
        buttonText: 'Add external links',
        isComplete: external_link_present,
        isPrimary:
          short_link_claimed &&
          profile_complete &&
          inviteCommittee &&
          touchpoints_count > 0 &&
          messaged_to_other_society &&
          subscribers_count >= 10,
        action: () => {},
      },
    ],
    [dashboardData]
  );

  const renderCardAction = (data: typeof getHuzzleVerifiedData[0]) => {
    if (data.id === 'add_subscribers') {
      return (
        <AddSubscribers
          className={styles['add-subscriber-button']}
          size="medium"
        />
      );
    } else if (data.id === 'add_external_links') {
      return (
        <AddExternalLinks
          className={cn(styles.actionMenuCta, styles.addExternalLinks)}
          buttonColor={
            data.isPrimary || renderInPopover ? 'primary' : 'secondary'
          }
          setShouldAllowPopoverClose={setShouldAllowPopoverClose}
        />
      );
    } else {
      return (
        <ButtonV3
          color={data.isPrimary || renderInPopover ? 'primary' : 'secondary'}
          isFullWidth={!renderInPopover}
          onClick={data.action}
          className={styles.actionCta}
        >
          {data.buttonText}
        </ButtonV3>
      );
    }
  };

  const renderCards = useMemo(
    () =>
      getHuzzleVerifiedData.map((data) => (
        <div
          key={data.id}
          className={cn(styles.verifiedCard, {
            [styles.isComplete]: data.isComplete,
          })}
        >
          <div className={styles.checkIconContainer}>
            <Icon className={styles.checkIcon} iconName="icon_check" />
          </div>
          <div className={styles.infoSection}>
            <div className={styles.verificationStep}>
              <p
                className={cn(styles.stepTile, {
                  [styles.completed]: data.isComplete && renderInPopover,
                })}
              >
                {data.title}
              </p>
              <p
                className={cn(styles.stepDetails, {
                  [styles.completed]: renderInPopover,
                })}
              >
                {data.caption}
              </p>
            </div>
            {data.isComplete
              ? !renderInPopover && (
                  <p className={styles.completeText}>
                    <Icon
                      iconName="icon_check-double"
                      className={styles.doubleCheckIcon}
                    />
                    Looks great
                  </p>
                )
              : renderCardAction(data)}
          </div>
        </div>
      )),
    [getHuzzleVerifiedData]
  );

  return (
    <div
      className={cn(styles.HuzzleVerified, {
        [styles.HuzzleVerifiedPopover]: renderInPopover,
      })}
    >
      <div className={styles.HuzzleVerifiedHeader}>
        <Icon
          className={styles.medalIcon}
          iconName="icon_medal"
          size="xxlarge"
        />
        <div>
          <p className={styles.title}>Get Huzzle Verified</p>
          <p className={styles.caption}>
            Become Huzzle Verified and get more visibility with companies and
            students on Huzzle, a nicer profile page and your events boosted!
          </p>
        </div>
      </div>
      <div className={styles.HuzzleVerifiedContent}>
        {!renderInPopover ? (
          <SlideShow
            slidesPerRow={3}
            gutter={20}
            maxWidth="280px"
            partialSlide={0.5}
            navigationClassName={styles.navigationButton}
          >
            {renderCards}
          </SlideShow>
        ) : (
          renderCards
        )}
      </div>
    </div>
  );
};
