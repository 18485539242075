import React, { useState, Fragment, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import cn from 'classnames';
import { noop } from 'lodash';
import { isValueInBoundary } from 'utils/format';
import styles from './Popover.module.scss';

export type PopoverProps = {
  className?: string;
  contentClassName?: string;
  style?: Record<string, unknown>;
  content: React.ReactNode;
  children?: React.ReactNode;
  menuLeftOffset?: number;
  menuHorizontalOffset?: number;
  placement?: Placement;
  triggerClassName?: string;
  shouldAllowClose?: boolean;
  disabled?: boolean;
  /** Delay to open popover, in millisec */
  delay?: number;
};

export const PopoverComponent = ({
  className = '',
  triggerClassName = '',
  content,
  children,
  menuLeftOffset = 15,
  placement = 'right',
  contentClassName = '',
  menuHorizontalOffset,
  shouldAllowClose = true,
  disabled = false,
  delay,
}: PopoverProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);
  const menuHorizontalOffsetValue = menuHorizontalOffset
    ? menuHorizontalOffset
    : 0;
  const { styles: popperStyles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [menuHorizontalOffsetValue, menuLeftOffset],
          },
        },
      ],
    }
  );

  const [position, setPosition] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    // Check if document is defined before accessing it
    if (typeof document !== 'undefined') {
      const popoverRoot = document.createElement('div');
      popoverRoot.id = 'popover-root';
      document.body.appendChild(popoverRoot);

      // Cleanup function to remove the element on unmount
      return () => {
        popoverRoot.remove();
      };
    }
  }, []);

  useEffect(() => {
    const setFromEvent = (e: MouseEvent) =>
      setPosition({ x: e.clientX, y: e.clientY });

    window.addEventListener('mousemove', setFromEvent);

    return () => {
      window.removeEventListener('mousemove', setFromEvent);
    };
  }, []);

  useEffect(() => {
    if (position) {
      setTimeout(onMouseMove, 10);
    }
  }, [position]);

  const onMouseMove = () => {
    const refRect = referenceElement.current?.getBoundingClientRect();
    const popperRect = popperElement.current?.getBoundingClientRect();

    if (!refRect || !popperRect) {
      setIsPopoverOpen(false);
      return;
    }

    const combinedRect = {
      targetTop: Number(refRect.top.toFixed()) - 1,
      targetBottom: refRect.bottom,
      targetLeft: Number(refRect.left.toFixed()) - 1,
      targetRight: Number(refRect.right.toFixed()),
      popperRight: popperRect.right,
      popperLeft: popperRect.left - 10,
      popperTop: popperRect.top - 10,
      popperBottom: popperRect.bottom,
    };

    if (isPopoverOpen && position) {
      if (
        isValueInBoundary(
          position.x,
          combinedRect.targetLeft,
          combinedRect.targetRight
        ) ||
        isValueInBoundary(
          position.x,
          combinedRect.popperLeft,
          combinedRect.popperRight
        )
      ) {
        if (
          !(
            (isValueInBoundary(
              position.y,
              combinedRect.targetTop,
              combinedRect.targetBottom
            ) &&
              isValueInBoundary(
                position.x,
                combinedRect.targetLeft,
                combinedRect.targetRight
              )) ||
            (isValueInBoundary(
              position.y,
              combinedRect.popperTop,
              combinedRect.popperBottom
            ) &&
              isValueInBoundary(
                position.x,
                combinedRect.popperLeft,
                combinedRect.popperRight
              ))
          )
        ) {
          shouldAllowClose && setIsPopoverOpen(false);
        }
      } else {
        shouldAllowClose && setIsPopoverOpen(false);
      }
    }
  };

  const portalRoot =
    typeof document !== 'undefined'
      ? document.getElementById('popover-root')
      : null;

  const triggerElementClassNames = cn({
    [triggerClassName]: true,
  });

  const menuClassNames = cn({
    [styles.menu]: !contentClassName,
    [contentClassName]: !!contentClassName,
  });

  // todo
  let timeout: NodeJS.Timeout;
  const handleMouseEnter = () => {
    timeout = setTimeout(() => {
      setIsPopoverOpen(true);
    }, 1500); // 1.5 seconds
  };

  const handleMouseLeave = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    // setIsPopoverOpen(false);
  };

  return (
    <div className={styles.Popover}>
      <Menu>
        <div
          ref={referenceElement}
          className={triggerElementClassNames}
          onMouseEnter={() =>
            delay ? handleMouseEnter() : setIsPopoverOpen(true)
          }
          onMouseLeave={delay ? handleMouseLeave : noop}
        >
          {children}
          {isPopoverOpen &&
            !disabled &&
            portalRoot &&
            ReactDOM.createPortal(
              <div
                ref={popperElement}
                style={{ ...popperStyles.popper }}
                {...attributes.popper}
                className={cn({
                  [styles.PopoverContainer]: !className,
                  [className]: !!className,
                })}
              >
                <Transition
                  as={Fragment}
                  show={isPopoverOpen}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items static className={menuClassNames}>
                    {content}
                  </Menu.Items>
                </Transition>
              </div>,
              portalRoot
            )}
        </div>
      </Menu>
    </div>
  );
};
