import React, { memo } from 'react';
import cn from 'classnames';

import { ButtonV3 } from 'components/ComponentV2';
import { inviteCommunityMemberModalInstance } from 'components/InvitePopup/InviteCommunityMemberModal';
import { useCampaigns } from 'lib/contexts/campaigns-context';
import styles from './AddSubscribers.module.scss';

interface AddSubscribersProps {
  size?: 'small' | 'medium' | 'large';
  buttonText?: string;
  className?: string;
}

export const AddSubscribers: React.FC<AddSubscribersProps> = memo(
  (props: AddSubscribersProps) => {
    const { size = 'small', buttonText = 'Invite members', className } = props;
    const { setInvitePopup } = useCampaigns();

    return (
      <>
        <ButtonV3
          size={size}
          className={cn(styles.button, className)}
          onClick={async () => {
            setInvitePopup(true);
            await inviteCommunityMemberModalInstance();
          }}
        >
          {buttonText}
        </ButtonV3>
      </>
    );
  }
);

AddSubscribers.displayName = 'AddSubscribers';
