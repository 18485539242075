import React, { memo } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import Avatar from 'components/Avatar';
import { Icon } from 'components/Icon/Icon.component';
import Row from 'components/layout/Row';
import { Col } from 'components/layout/Grid';
import { ActionMenuButton } from 'components/ActionMenuButton/ActionMenuButton.component';
import Logo from 'components/Logo';
import { useCompanyProfile } from 'hooks/UseCompanyProfile.hook';
import { useSponsorshipInvite } from 'hooks/useSponsorshipInvite';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';

import styles from '../SidebarStyles.module.scss';

export interface SidebarRecruiterProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
}

export const SidebarRecruiter: React.FC<SidebarRecruiterProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
  }: SidebarRecruiterProps) => {
    const router = useRouter();
    const { recruiter, userName } = useAuth();

    const contentClassNames = cn(
      {
        [styles.Sidebar]: true,
      },
      className
    );

    const { companyDashboard } = useCompanyProfile();
    const { companySponsorInviteList } = useSponsorshipInvite();

    const avatar_url = recruiter?.avatar_url;

    const {
      unread_messages_count = 0,
      live_non_event_not_reviewed_touchpoint_applications = 0,
    } = companyDashboard || {};

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="SidebarRecruiter"
      >
        <div className={styles.header}>
          <ActionMenuButton
            className={styles.profile}
            menuButtonClass={styles['dropdown-menu']}
            menuButtonOpenClassName={styles['dropdown-open']}
            buttonElement={
              <Row
                align="center"
                justify="space-between"
                isFullWidthRow
                wrap={false}
              >
                <Row
                  align="center"
                  justify="start"
                  columnGap={8}
                  wrap={false}
                  className={styles.title}
                >
                  <Avatar
                    className={styles.avatar}
                    icon={avatar_url || ''}
                    name={userName}
                    size="small"
                  ></Avatar>
                  <span className={styles.name}>{userName}</span>
                </Row>
                <Col>
                  <Icon
                    iconName="icon_arrow-down"
                    size="xsmall"
                    className={styles.icon}
                  />
                </Col>
              </Row>
            }
            options={[
              {
                id: '1',
                label: 'Logout',
                onClick: () => {
                  router.push(`${PAGE_ROUTES.LOGOUT}?type=recruiter`);
                },
              },
            ]}
            listClassName={styles.options}
            optionClassName={styles.logout}
          />
          <div className={styles.section}>
            <Row
              columnGap={10}
              onClick={() => router.push(PAGE_ROUTES.RECRUITER_DASHBOARD)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.RECRUITER_DASHBOARD,
              })}
            >
              <Icon
                iconName="icon_layout-11"
                className={cn({
                  [styles.iconActive]:
                    router.pathname === PAGE_ROUTES.RECRUITER_DASHBOARD,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]:
                    router.pathname === PAGE_ROUTES.RECRUITER_DASHBOARD,
                })}
              >
                Dashboard
              </p>
            </Row>
            <Row
              align="center"
              justify="space-between"
              onClick={() => router.push(PAGE_ROUTES.HIRING)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.HIRING,
              })}
            >
              <Row columnGap={10}>
                <Icon
                  iconName={
                    router.pathname === PAGE_ROUTES.HIRING
                      ? 'icon_briefcase_filled'
                      : 'icon_briefcase'
                  }
                  className={cn({
                    [styles.iconActive]: router.pathname === PAGE_ROUTES.HIRING,
                  })}
                />
                <p
                  className={cn({
                    [styles.textActive]: router.pathname === PAGE_ROUTES.HIRING,
                  })}
                >
                  Hiring
                </p>
              </Row>
              {live_non_event_not_reviewed_touchpoint_applications > 0 ? (
                <p className={styles.new}>
                  {live_non_event_not_reviewed_touchpoint_applications}
                </p>
              ) : null}
            </Row>
            <Row
              align="center"
              justify="space-between"
              onClick={() => router.push(PAGE_ROUTES.CAMPAIGNS)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.CAMPAIGNS,
              })}
            >
              <Row columnGap={10}>
                <Icon
                  iconName={
                    router.pathname === PAGE_ROUTES.CAMPAIGNS
                      ? 'icon_party_filled'
                      : 'icon_party'
                  }
                  className={cn({
                    [styles.iconActive]:
                      router.pathname === PAGE_ROUTES.CAMPAIGNS,
                  })}
                />
                <p
                  className={cn({
                    [styles.textActive]:
                      router.pathname === PAGE_ROUTES.CAMPAIGNS,
                  })}
                >
                  Events
                </p>
              </Row>
            </Row>
            <Row
              align="center"
              justify="space-between"
              onClick={() => router.push(PAGE_ROUTES.MESSAGES)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.MESSAGES,
              })}
            >
              <Row columnGap={10}>
                <Icon
                  iconName={
                    router.pathname === PAGE_ROUTES.MESSAGES
                      ? 'icon_chat-filled'
                      : 'icon_chat'
                  }
                  className={cn({
                    [styles.iconActive]:
                      router.pathname === PAGE_ROUTES.MESSAGES,
                  })}
                />
                <p
                  className={cn({
                    [styles.textActive]:
                      router.pathname === PAGE_ROUTES.MESSAGES,
                  })}
                >
                  Messages
                </p>
              </Row>
              {unread_messages_count > 0 ? (
                <p className={styles.new}>{unread_messages_count}</p>
              ) : null}
            </Row>
            <Row
              columnGap={10}
              onClick={() => router.push(PAGE_ROUTES.COMPANY_EDIT)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.COMPANY_EDIT,
              })}
            >
              <Icon
                iconName={
                  router.pathname === PAGE_ROUTES.COMPANY_EDIT
                    ? 'icon_paint-up_filled'
                    : 'icon_paint-up'
                }
                className={cn({
                  [styles.iconActive]:
                    router.pathname === PAGE_ROUTES.COMPANY_EDIT,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]:
                    router.pathname === PAGE_ROUTES.COMPANY_EDIT,
                })}
              >
                Company profile
              </p>
            </Row>
            <Row
              align="center"
              justify="space-between"
              //   style={{ display: 'none' }}
              onClick={() => router.push(PAGE_ROUTES.RECRUITER_PARTNERSHIPS)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.RECRUITER_PARTNERSHIPS,
              })}
            >
              <Row columnGap={10}>
                <Icon
                  iconName={
                    router.pathname === PAGE_ROUTES.RECRUITER_PARTNERSHIPS
                      ? 'icon_handshake_filled'
                      : 'icon_handshake'
                  }
                  className={cn({
                    [styles.iconActive]:
                      router.pathname === PAGE_ROUTES.RECRUITER_PARTNERSHIPS,
                  })}
                />
                <p
                  className={cn({
                    [styles.textActive]:
                      router.pathname === PAGE_ROUTES.RECRUITER_PARTNERSHIPS,
                  })}
                >
                  Partnerships
                </p>
              </Row>
              {companySponsorInviteList &&
                companySponsorInviteList.length > 0 && (
                  <p className={styles.new}>
                    {companySponsorInviteList.length}
                  </p>
                )}
            </Row>
            <div className={styles.divider} />
            <Row
              columnGap={10}
              onClick={() => router.push(PAGE_ROUTES.STUDENT_SOCIETIES)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES,
              })}
            >
              <Icon
                iconName={
                  router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES
                    ? 'icon_multiple-11_filled'
                    : 'icon_multiple-11'
                }
                className={cn({
                  [styles.iconActive]:
                    router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]:
                    router.pathname === PAGE_ROUTES.STUDENT_SOCIETIES,
                })}
              >
                Student societies
              </p>
            </Row>
            <Row
              columnGap={10}
              onClick={() => router.push(PAGE_ROUTES.TALENT_SOURCING)}
              className={cn(styles.sectionRow, {
                [styles.sectionRowActive]:
                  router.pathname === PAGE_ROUTES.TALENT_SOURCING,
              })}
            >
              <Icon
                iconName={
                  router.pathname === PAGE_ROUTES.TALENT_SOURCING
                    ? 'icon_users-wm-fill-regular-small-fill'
                    : 'icon_users-wm-fill-regular-small'
                }
                className={cn({
                  [styles.iconActive]:
                    router.pathname === PAGE_ROUTES.TALENT_SOURCING,
                })}
              />
              <p
                className={cn({
                  [styles.textActive]:
                    router.pathname === PAGE_ROUTES.TALENT_SOURCING,
                })}
              >
                Talent sourcing
              </p>
            </Row>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.separator}></div>
          <div
            onClick={() => router.push(PAGE_ROUTES.RECRUITER_DASHBOARD)}
            className={styles.logo}
          >
            <Logo name="icon_huzzlerecruiter" size="xsmall" color="black" />
          </div>
        </div>
      </div>
    );
  }
);

SidebarRecruiter.displayName = 'SidebarRecruiter';
