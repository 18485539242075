import React, { memo, useMemo } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { isPast } from 'date-fns';
import { Link, Image, Icon } from 'components';
import { Col, Row } from 'components/layout';
import { ButtonV2 } from 'components/form';
import Avatar from 'components/Avatar';
import { useCohost } from 'hooks/useCohost';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { StudentSocietyResponse } from 'lib/models/student-society';
import { TouchpointState } from 'lib/models/opportunity';
import { PAGE_ROUTES } from 'lib/page-routes';

import styles from './PendingInviteCard.module.scss';

export interface PendingInviteCardProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  isToaster?: boolean;
  onCloseToaster?: () => void;
}

export const PendingInviteCard: React.FC<PendingInviteCardProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    isToaster = false,
    onCloseToaster,
  }: PendingInviteCardProps) => {
    const contentClassNames = cn({
      [styles.PendingInviteCard]: true,
    });

    const router = useRouter();
    const { id: touchpointId } = router.query;

    const {
      receivedCohostInvites: receivedCohostInvitesResponse,
      onAcceptInvite,
      onDeclineInvite,
    } = useCohost();

    const getInitials = (name: string) => (name ? name.split(' ')[0] : '');

    const pendingInvites = useMemo(() => {
      if (receivedCohostInvitesResponse) {
        const receivedCohostInvites = parseArrayResponse(
          receivedCohostInvitesResponse
        );
        const invites = touchpointId
          ? receivedCohostInvites.filter(
              ({ touchpoint_id }) => touchpoint_id === touchpointId
            )
          : receivedCohostInvites;
        return invites.map((item) => {
          const { id, status, touchpoint: touchpointResponse } = item;
          const touchpoint = parseResponse(touchpointResponse);
          const {
            id: touchpointId,
            title,
            slug: event_slug,
            creatable_for,
            start_date,
          } = touchpoint;
          const { icon_url, name, slug } = parseResponse(
            creatable_for as StudentSocietyResponse
          );
          return {
            id,
            icon_url,
            name,
            slug,
            title,
            event_slug,
            status,
            touchpointId,
            start_date,
          };
        });
      }
    }, [receivedCohostInvitesResponse]);

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="PendingInviteCard"
      >
        {pendingInvites &&
          pendingInvites
            .filter(
              (invite) =>
                invite.status === TouchpointState.PENDING &&
                !isPast(new Date(invite?.start_date))
            )
            .map(({ id, icon_url, name, slug, title, touchpointId }) => (
              <div key={id} className={cn(styles.cohosting, className)}>
                <Row align="center">
                  <Col className={styles.cohostingInfo}>
                    <Row className={styles.cohostingHeader}>
                      <Col>
                        <Icon iconName="warning" size="large" />
                      </Col>
                      <Col>
                        <p className={cn(styles.info, styles.mainTitle)}>
                          You’re invited for Co-hosting
                        </p>
                        <Row
                          align="center"
                          className={cn(styles.cohostingDesc, {
                            [styles.cohostingDescToaster]: isToaster,
                          })}
                        >
                          {icon_url ? (
                            <Image src={icon_url} className={styles.logo} />
                          ) : (
                            <Avatar
                              name={getInitials(name)}
                              size="xxlarge"
                              className={styles.logo}
                            />
                          )}
                          <Link
                            href={`${PAGE_ROUTES.SOCIETIES}/${slug}`}
                            target="_blank"
                            underlineOnHover={false}
                            className={styles.info}
                          >
                            {name}
                          </Link>{' '}
                          invited to Co-host their{' '}
                          <Link
                            href={`${PAGE_ROUTES.CAMPAIGNS}/${touchpointId}`}
                            underlineOnHover={true}
                            className={styles.info}
                          >
                            {title}
                          </Link>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col className={styles.buttons}>
                    <ButtonV2
                      onClick={() => {
                        onAcceptInvite(id);
                        onCloseToaster && onCloseToaster();
                      }}
                      className={styles.cohostingButton}
                    >
                      Accept
                    </ButtonV2>
                    <ButtonV2
                      onClick={() => {
                        onDeclineInvite(id);
                        onCloseToaster && onCloseToaster();
                      }}
                      className={styles.cohostingButton}
                    >
                      Decline
                    </ButtonV2>
                  </Col>
                </Row>
              </div>
            ))}
      </div>
    );
  }
);

PendingInviteCard.displayName = 'PendingInviteCard';
