import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import useSWR, { KeyedMutator } from 'swr';
import { AxiosResponse } from 'axios';

import { useNotification } from 'hooks/useNotification';
import { API_ROUTES } from 'lib/api-routes';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import {
  ApplicationAttributes,
  ApplicationsResponse,
  InvitedGuestAttributes,
  InvitedGuestsRseponse,
} from 'lib/models/application';
import {
  OpportunityAttributes,
  OpportunityResponse,
} from 'lib/models/opportunity';
import { useAuth } from 'lib/providers/AuthProvider';
import { get, post } from 'lib/utils/http';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { apiInstance } from 'lib/utils/axios';

export interface AttendeesFiltersReturnType {
  touchpointId: string;
  applications: Array<ApplicationAttributes>;
  isLoading: boolean;
  onCSVExport: (title?: string) => Promise<string | undefined>;
  mutateTouchpoint: KeyedMutator<OpportunityResponse>;
  mutateApplications: KeyedMutator<ApplicationsResponse>;
  touchpoint: OpportunityAttributes;
  onReleaseTickets: ({
    touchpointId,
    touchpoint_application_ids,
  }: {
    touchpointId: string;
    touchpoint_application_ids: Array<string>;
  }) => Promise<ApplicationsResponse | undefined>;
  setIsWaitlist: Dispatch<SetStateAction<boolean>>;
  setIsInvitedTab: Dispatch<SetStateAction<boolean>>;
  inviteToCommunity: (candidate_ids: Array<string>) => Promise<
    | {
        message: string;
        invited_count: string;
      }
    | undefined
  >;
  isApplicationsLoading: boolean;
  onRejectApplications: (
    touchpoint_application_ids: Array<string>,
    rejection_reason?: string
  ) => Promise<ApplicationsResponse | undefined>;
  isWaitlist: boolean;
  isInvitedTab: boolean;
  setPerPageCount: Dispatch<SetStateAction<number>>;
  setPageIndex: Dispatch<SetStateAction<number>>;
  pageIndex: number;
  perPageCount: number;
  touchpointDashboard?: DashboardStats;
  mutateTouchpointDashboard: KeyedMutator<DashboardStats>;
  applicationListTotalCount: number;
  markApplicantAsSeen: (ids: Array<string>) => Promise<void>;
  invitedGuests: Array<InvitedGuestAttributes>;
  invitedGuestsTotalCount: number;
  mutateInvitedGuests: KeyedMutator<AxiosResponse<InvitedGuestsRseponse>>;
  isInvitedGuestsLoading: boolean;
}

interface DashboardStats {
  talent_pool: number;
  viewed: number;
  saved: number;
  clicked_applied: number;
  applied: number;
  non_reviewed_count: number;
  status_counter: {
    applied: number;
    rejected: number;
  };
  unread_messages_count: number;
  unseen_waitlist_count: number;
  views_per_day_30: Record<string, number>;
  registrations_per_day_30: Record<string, number>;
  waitlist_count: number;
}

export const useAttendeesFilter = (): AttendeesFiltersReturnType => {
  const { headers, plainHeaders } = useRecoilValue(httpHeadersState);
  const { isManager } = useAuth();
  const notificationInstance = useNotification();

  const [isWaitlist, setIsWaitlist] = useState<boolean>(false);
  const [isInvitedTab, setIsInvitedTab] = useState(false);
  const [perPageCount, setPerPageCount] = useState<number>(10);

  // add pagination in applications

  const [pageIndex, setPageIndex] = useState<number>(0);

  const router = useRouter();
  const { id } = router.query;
  const touchpointId = id as string;

  const params = {
    page: pageIndex + 1,
    per_page: perPageCount,
    waitlist: isWaitlist,
  };
  const qs = queryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
  });

  const touchpointCoHostsRoute = isManager
    ? API_ROUTES.SOCIETY_TOUCHPOINTS
    : API_ROUTES.COMPANY_TOUCHPOINTS;
  const eventsRoute = isManager
    ? API_ROUTES.SOCIETY_TOUCHPOINTS
    : API_ROUTES.COMPANY_TOUCHPOINTS;

  const applicationsPathname = touchpointId
    ? `${touchpointCoHostsRoute}/${touchpointId}${API_ROUTES.APPLICATIONS}?${qs}`
    : null;

  const campaignPathname = touchpointId
    ? `${touchpointCoHostsRoute}/${touchpointId}`
    : null;

  const {
    data: applicationsList,
    error,
    mutate: mutateApplications,
    isLoading: isApplicationsLoading,
  } = useSWR<ApplicationsResponse>(
    applicationsPathname
      ? [applicationsPathname, headers]
      : applicationsPathname,
    get
  );

  const isLoading = !(applicationsList || error);

  const applicationListTotalCount = useMemo(() => {
    return applicationsList?.meta?.total || 0;
  }, [applicationsList]);

  const applications = useMemo(() => {
    return parseArrayResponse<ApplicationAttributes>(applicationsList);
  }, [applicationsList, isWaitlist]);

  const { data: touchpointResponse, mutate: mutateTouchpoint } =
    useSWR<OpportunityResponse>(
      campaignPathname ? [campaignPathname, headers] : campaignPathname,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  const { data: touchpointDashboard, mutate: mutateTouchpointDashboard } =
    useSWR<DashboardStats>(
      campaignPathname
        ? [campaignPathname + API_ROUTES.DASHBOARD, headers]
        : campaignPathname,
      get,
      {
        revalidateOnFocus: false,
      }
    );

  const touchpoint = useMemo(() => {
    return parseResponse<OpportunityAttributes>(touchpointResponse);
  }, [touchpointResponse]);

  const onCSVExport = async (title?: string): Promise<string | undefined> => {
    const { NEXT_PUBLIC_API_HOST_URL } = process.env;
    plainHeaders.set('Content-Type', 'text/csv');
    plainHeaders.set('Accept', 'text/csv');
    plainHeaders.set('Response-Type', 'text/csv');
    const applicationsPath = applicationsPathname
      ? applicationsPathname.split('?')
      : '';

    const path = `${NEXT_PUBLIC_API_HOST_URL}${applicationsPath[0]}.csv?${
      isWaitlist ? `waitlist=true` : `waitlist=false`
    }`;

    const fileTitle = title
      ? title.replace(/[^A-Za-z0-9_]/g, '-').replace(/-+/g, '-')
      : 'touchpoint-applicants-report';

    try {
      const response = await fetch(path, { headers: plainHeaders });
      const data = await response.text();
      const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8,' });
      const blobURL = window.URL.createObjectURL(blob);
      const fileName = `${fileTitle}-${
        isWaitlist ? 'Waitlist' : 'Attendees'
      }-${format(new Date(), 'yyyy-MM-dd')}.csv`;
      saveAs(blobURL, fileName);
      return data;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onReleaseTickets = async ({
    touchpointId,
    touchpoint_application_ids,
  }: {
    touchpointId: string;
    touchpoint_application_ids: Array<string>;
  }) => {
    const body = {
      touchpoint_application_ids,
    };
    const pathname = `${eventsRoute}/${touchpointId}${API_ROUTES.APPLICATIONS}${API_ROUTES.RELEASE_TICKETS}`;
    try {
      const response = await post<ApplicationsResponse>(
        pathname,
        body,
        headers
      );
      if (response) {
        mutateApplications();
        mutateTouchpointDashboard();
        return response;
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const onRejectApplications = async (
    touchpoint_application_ids: Array<string>,
    rejection_reason?: string
  ) => {
    const body = {
      touchpoint_application_ids,
      touchpoint_application: {
        ...(rejection_reason && {
          reason: rejection_reason,
        }),
      },
    };
    const pathname = `${eventsRoute}/${touchpointId}${API_ROUTES.APPLICATIONS}${API_ROUTES.REJECT_WAITLIST}`;
    try {
      const response = await post<ApplicationsResponse>(
        pathname,
        body,
        headers
      );
      if (response) {
        mutateApplications();
        mutateTouchpointDashboard();
        return response;
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const markApplicantAsSeen = async (ids: Array<string>) => {
    const body = {
      review_touchpoint_application_ids: ids,
    };
    const pathname = `${eventsRoute}/${touchpointId}${API_ROUTES.APPLICATIONS}${API_ROUTES.REVIEW}`;
    try {
      const response = await post<{ alert: string; unreviewed_count: number }>(
        pathname,
        body,
        headers
      );
      if (response) {
        mutateApplications();
        mutateTouchpointDashboard();
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const inviteToCommunity = async (candidate_ids: Array<string>) => {
    const body = {
      candidate_ids,
    };
    const pathname = API_ROUTES.INVITE_CANDIDATES_TO_COMMUNITY;
    try {
      const response = await post<{ message: string; invited_count: string }>(
        pathname,
        body,
        headers
      );
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const invitedGuestsRoute = isManager
    ? API_ROUTES.SOCIETY_TOUCHPOINTS
    : API_ROUTES.COMPANY_TOUCHPOINTS;

  const {
    data: guestsResponse,
    mutate: mutateInvitedGuests,
    isLoading: isInvitedGuestsLoading,
  } = useSWR<AxiosResponse<InvitedGuestsRseponse>>(
    touchpointResponse
      ? `${invitedGuestsRoute}/${touchpoint.id}/invited?${qs}`
      : null,
    apiInstance.get
  );

  const invitedGuests = useMemo(() => {
    return parseArrayResponse<InvitedGuestAttributes>(guestsResponse?.data);
  }, [guestsResponse]);

  const invitedGuestsTotalCount = useMemo(() => {
    return guestsResponse?.data?.meta?.total || 0;
  }, [guestsResponse]);

  return {
    touchpointId,
    applications,
    isLoading,
    onCSVExport,
    mutateTouchpoint,
    touchpoint,
    onReleaseTickets,
    setIsWaitlist,
    mutateApplications,
    inviteToCommunity,
    isApplicationsLoading,
    onRejectApplications,
    isWaitlist,
    setPerPageCount,
    setPageIndex,
    perPageCount,
    pageIndex,
    touchpointDashboard,
    mutateTouchpointDashboard,
    applicationListTotalCount,
    markApplicantAsSeen,
    invitedGuests,
    mutateInvitedGuests,
    isInvitedGuestsLoading,
    isInvitedTab,
    invitedGuestsTotalCount,
    setIsInvitedTab,
  };
};
