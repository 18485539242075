import React, { useState } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import { useRouter } from 'next/router';
import Modal from 'components/Modal';
import InviteCommunity from 'components/InvitePopup/InviteCommunityComponent';
import { useCampaigns } from 'lib/contexts/campaigns-context';
import { InviteCandidate } from 'lib/models/candidate';
import InviteEmail from '../InviteEmail';

import styles from './InviteCommunityMemberModal.module.scss';

const ModalInstance: React.FC<InstanceProps<void>> = ({ isOpen, onResolve, onReject }) => {
  const router = useRouter();

  const { setInvitePopup } = useCampaigns();

  const [candidateSelected, setCandidateSelected] = useState<Array<InviteCandidate>>([]);
  const [importedCandidateList, setImportedCandidateList] = useState<Array<InviteCandidate>>([]);

  const [showInvitingListandEmail, setShowInvitingListandEmail] = useState(false);

  const resetStates = () => {
    router.replace(`${router.pathname}`, undefined, {
      shallow: true,
    });
    setInvitePopup(false);
    setShowInvitingListandEmail(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        resetStates();
        onReject();
      }}
      closeOnOverlayClick={false}
      contentClassName={styles.container}
    >
      {showInvitingListandEmail ? (
        <InviteEmail
          candidateSelected={candidateSelected}
          onClose={() => {
            resetStates();
            onReject();
          }}
          onModalResolve={() => {
            resetStates();
            onResolve();
          }}
          onBackClick={() => setShowInvitingListandEmail(false)}
          isCommunityMemberInvitePop={true}
        />
      ) : (
        <InviteCommunity
          candidateSelected={candidateSelected}
          setCandidateSelected={setCandidateSelected}
          importedCandidateList={importedCandidateList}
          setImportedCandidateList={setImportedCandidateList}
          onNextClick={() => setShowInvitingListandEmail(true)}
          onClose={() => {
            resetStates();
            onReject();
          }}
          onModalResolve={() => {
            resetStates();
            onResolve();
          }}
        />
      )}
    </Modal>
  );
};

export const inviteCommunityMemberModalInstance = create(ModalInstance);
