import {
  useMemo,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';
import { useRouter } from 'next/router';
import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import useSWRInfinite from 'swr/infinite';
import useSWR from 'swr';

import { useAuth } from 'lib/providers/AuthProvider';
import { parseAxiosPaginatedResponse } from 'lib/utils/parser';
import { API_ROUTES } from 'lib/api-routes';
import {
  TalentPoolAttributes,
  TalentPoolsResponse,
} from 'lib/models/talent-pool';
import { apiInstance } from 'lib/utils/axios';
import {
  ApplicationAttributes,
  ApplicationsResponse,
} from 'lib/models/application';
import useDebounce from './useDebounce';

export interface TalentPoolFiltersReturnType {
  talentPool: Array<TalentPoolAttributes | ApplicationAttributes>;
  filters: Record<string, Array<string>> | null;
  partialFilters: Record<string, Array<string>> | null;
  setFilters: Dispatch<SetStateAction<Record<string, string[]> | null>>;
  onChangeFilter: (key: string, values: string[], isPartial?: boolean) => void;
  isLoading: boolean;
  isLoadingMore?: boolean;
  hasMoreRecords: boolean;
  talentMetaInfo: { total?: number; all_candidate?: number };
  fetchMoreData: () => void;
  isValidating: boolean;
  mutate: () => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  isMultiselectDisabled: boolean;
  partialCount: number;
  isPartialLoading: boolean;
  onChangeGroupedFilter: ({
    filter,
    isPartial,
  }: {
    filter: Record<string, string[]>;
    isPartial: boolean;
  }) => void;
}

export const mandatoryFilters = [
  'candidate_transactions',
  'university_ids',
  'subject_ids',
  'education_end_date_year',
  'affiliated_society_ids',
  'opportunity_types',
  'touchpointable_kinds',
  'city_ids',
  'country_ids',
  'area_of_responsibility_ids',
  'industry_ids',
  'language_ids',
  'visa_country_ids',
  'cv_available',
];

export const enum SidebarOption {
  EXPLORE_HUZZLE = 1,
  COMMUNITY = 2,
  MANUAL_EMAIL = 3,
  PAST_EVENT = 4,
  PROFILE_VIEWERS = 5,
  EVENT_VIEWERS = 6,
}

export const useToolFilters = ({
  transaction,
  activeFilters,
  sidebarOptionSelected,
}: {
  transaction: string;
  activeFilters?: Record<string, Array<string>>;
  sidebarOptionSelected?: SidebarOption;
}): TalentPoolFiltersReturnType => {
  const { isManager, isRecruiter } = useAuth();
  const router = useRouter();
  const { id: touchpointId, past_event_id } = router.query;
  const [filters, setFilters] = useState<Record<string, string[]> | null>(null);
  const [partialFilters, setPartialFilters] = useState<Record<
    string,
    string[]
  > | null>(null);
  const PER_PAGE_COUNT = 50;

  const [search, setSearch] = useState('');
  const trimmedSearch = search.trim();
  const debouncedSearch = useDebounce(trimmedSearch, 500);

  useEffect(() => {
    if (activeFilters && Object.keys(activeFilters)?.length) {
      setFilters({ ...activeFilters });
    }
  }, [activeFilters]);

  const constructEndpoint = () => {
    if (
      (transaction === 'invite_guests' || transaction === 'invite_community') &&
      isManager
    ) {
      if (sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE) {
        return API_ROUTES.SOCIETY_TALENT_POOL;
      } else if (sidebarOptionSelected === SidebarOption.COMMUNITY) {
        return API_ROUTES.SOCIETY_COMMUNITY_POOL;
      } else if (sidebarOptionSelected === SidebarOption.PROFILE_VIEWERS) {
        return API_ROUTES.SOCIETY_PROFILE_VIEWERS;
      } else if (
        sidebarOptionSelected === SidebarOption.PAST_EVENT &&
        past_event_id
      ) {
        return `${API_ROUTES.SOCIETY_TOUCHPOINTS}/${past_event_id}${API_ROUTES.APPLICATIONS}`;
      } else if (
        sidebarOptionSelected === SidebarOption.EVENT_VIEWERS &&
        past_event_id
      ) {
        return `${API_ROUTES.SOCIETY_EVENT_VIEWERS}`;
      }
    } else {
      if (!touchpointId) return `${API_ROUTES.TALENT_POOL}`;

      if (transaction === 'talent_pool')
        return `${API_ROUTES.JOB_TALENT_POOL}`.replace(
          '[id]',
          touchpointId as string
        );
      else
        return `${API_ROUTES.TRANSACTION_CANDIDATE}`.replace(
          '[id]',
          touchpointId as string
        );
    }
  };

  const constructQueryString = ({
    pageIndex,
    isPartial,
    filters,
  }: {
    pageIndex: number;
    isPartial?: boolean;
    filters: Record<string, string[]> | null;
  }) => {
    if (sidebarOptionSelected === SidebarOption.MANUAL_EMAIL) return;

    let params;
    if (!filters || sidebarOptionSelected === SidebarOption.PAST_EVENT) {
      params = {
        ...(transaction && transaction !== 'talent_pool'
          ? { [transaction]: true }
          : {}),
        page: pageIndex + 1,
        per_page: isPartial ? 0 : PER_PAGE_COUNT,
      };
    } else {
      const {
        cv_available,
        disabilityOptions,
        socio_economic_status,
        ...rest
      } = filters;
      params = {
        ...(transaction && transaction !== 'talent_pool'
          ? { [transaction]: true }
          : {}),
        ...rest,
        ...(cv_available && cv_available?.length ? { cv_available: true } : {}),
        physical_disability: disabilityOptions?.includes(
          'Has physical disability'
        )
          ? ['Yes']
          : undefined,
        cognitive_disability: disabilityOptions?.includes(
          'Has cognitive disability'
        )
          ? ['Yes']
          : undefined,
        socio_economic_status: socio_economic_status?.length
          ? ['Yes']
          : undefined,
        page: pageIndex + 1,
        per_page: isPartial ? 0 : PER_PAGE_COUNT,
      };
    }
    if (transaction === 'invite_guests' && isManager) {
      if (sidebarOptionSelected === SidebarOption.EXPLORE_HUZZLE) {
        params = {
          ...params,
          with_in_touchpoint_id: touchpointId,
        };
      } else if (sidebarOptionSelected === SidebarOption.COMMUNITY) {
        params = {
          ...params,
          with_in_touchpoint_id: touchpointId,
          status: 'subscribed',
          with_details: true,
          q: debouncedSearch,
        };
      } else if (sidebarOptionSelected === SidebarOption.PAST_EVENT) {
        params = {
          ...params,
          with_details: true,
          q: debouncedSearch,
          with_in_touchpoint_id: touchpointId,
        };
      }
    }

    if (transaction === 'invite_community' && isManager) {
      if (sidebarOptionSelected === SidebarOption.PAST_EVENT) {
        params = {
          ...params,
          with_details: true,
          q: debouncedSearch,
        };
      } else if (sidebarOptionSelected === SidebarOption.EVENT_VIEWERS) {
        params = {
          ...params,
          with_details: true,
          q: debouncedSearch,
          touchpoint_id: past_event_id,
        };
      }
    }

    return queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });
  };

  const getTalentPoolKey = (pageIndex: number, isPartial?: boolean) => {
    if (sidebarOptionSelected === SidebarOption.MANUAL_EMAIL) return;

    const appliedPartialFilters = { ...partialFilters };
    for (const key in appliedPartialFilters) {
      if (typeof appliedPartialFilters[key] === 'undefined') {
        delete appliedPartialFilters[key];
      }
    }

    const currentFilters = isPartial
      ? { ...filters, ...appliedPartialFilters }
      : filters;

    if (transaction && constructEndpoint()) {
      const pathname = `${constructEndpoint()}?${constructQueryString({
        pageIndex,
        isPartial,
        filters: currentFilters,
      })}`;
      return pathname;
    }
    return null;
  };

  const {
    data: talentPoolResponse,
    error: talentPoolError,
    size,
    setSize,
    mutate,
    isValidating,
  } = useSWRInfinite<AxiosResponse<TalentPoolsResponse | ApplicationsResponse>>(
    (index) => getTalentPoolKey(index, false),
    apiInstance.get,
    {
      persistSize: false,
      revalidateFirstPage: false,
      revalidateOnFocus: false,
    }
  );

  const { data: partialResponse, isValidating: isPartialValidating } = useSWR<
    AxiosResponse<TalentPoolsResponse | ApplicationsResponse>
  >(() => getTalentPoolKey(0, true), apiInstance.get, {
    revalidateOnFocus: false,
  });

  const talentPool = useMemo(() => {
    if (!talentPoolResponse) return [];
    return parseAxiosPaginatedResponse<
      TalentPoolAttributes | ApplicationAttributes
    >(talentPoolResponse);
  }, [talentPoolResponse]);

  const hasMoreRecords = useMemo(() => {
    const firstTalentList = Array.isArray(talentPoolResponse)
      ? talentPoolResponse[0]
      : null;
    if (firstTalentList) {
      return (firstTalentList?.data?.meta?.total || 0) > size * PER_PAGE_COUNT;
    } else {
      return false;
    }
  }, [talentPoolResponse, size]);

  const talentMetaInfo = useMemo(() => {
    return talentPoolResponse?.[0]?.data?.meta || {};
  }, [talentPoolResponse]);

  const partialCount = useMemo(() => {
    if (!partialResponse || !talentPoolResponse) return 0;
    return typeof partialResponse.data?.meta?.total === 'undefined'
      ? talentPoolResponse?.[0]?.data?.meta?.total || 0
      : partialResponse.data?.meta?.total || 0;
  }, [partialResponse, talentPoolResponse]);

  const isLoading = !(talentPoolResponse || talentPoolError);

  const isLoadingMore =
    isLoading ||
    (size > 0 &&
      talentPoolResponse &&
      typeof talentPoolResponse[size - 1] === 'undefined');

  const fetchMoreData = useCallback(() => {
    if (
      !talentPoolError &&
      !isValidating &&
      talentPool.length === size * PER_PAGE_COUNT &&
      hasMoreRecords
    ) {
      setSize((oldSize) => oldSize + 1);
    }
  }, [
    talentPoolError,
    size,
    setSize,
    isValidating,
    talentPool,
    hasMoreRecords,
  ]);

  const onChangeFilter = useCallback(
    (key: string, values: string[], isPartial?: boolean) => {
      if (isPartial) {
        setPartialFilters((oldFilters) => ({ ...oldFilters, [key]: values }));
      } else {
        setFilters((oldFilters) => ({ ...oldFilters, [key]: values }));
      }
    },
    []
  );

  const onChangeGroupedFilter = useCallback(
    ({
      filter,
      isPartial,
    }: {
      filter: Record<string, string[]>;
      isPartial: boolean;
    }) => {
      if (isPartial) {
        const appliedPartialFilter = { ...filter };
        for (const key in filter) {
          if (typeof filter[key] === 'undefined') {
            delete appliedPartialFilter[key];
          }
        }
        setPartialFilters((oldFilters) => ({
          ...oldFilters,
          ...appliedPartialFilter,
        }));
      } else {
        setFilters((oldFilters) => ({ ...oldFilters, ...filter }));
      }
    },
    []
  );

  const isMultiselectDisabled = useMemo(() => {
    if (!filters) {
      return true;
    }
    for (const key of mandatoryFilters) {
      if (filters[key] && filters[key]?.length) {
        return false;
      }
    }
    return true;
  }, [filters]);

  useEffect(() => {
    // delete any of the keys if filters has non mandatory keys in it.
    if (isMultiselectDisabled && filters && isRecruiter) {
      const keysInFilters = Object.keys(filters);
      keysInFilters.forEach((key) => {
        if (!mandatoryFilters.includes(key)) {
          delete filters[key];
        }
      });
    }
  }, [filters, isMultiselectDisabled, isRecruiter]);

  return {
    talentPool,
    filters,
    onChangeFilter,
    onChangeGroupedFilter,
    partialFilters,
    isLoading,
    isLoadingMore,
    hasMoreRecords,
    talentMetaInfo,
    fetchMoreData,
    isValidating,
    mutate,
    setFilters,
    search,
    setSearch,
    isMultiselectDisabled,
    partialCount,
    isPartialLoading: isPartialValidating,
  };
};
