import React from 'react';
import { ButtonV3 } from 'components/ComponentV2';
import { REFERAL_LEARN_MORE } from 'utils/urls';
import styles from './ReferPopup.module.scss';
import { ProgressReferrals } from './ProgressReferrals/ProgressReferral.component';

export interface ReferralPopupProps {
  title: string;
  message: string;
  onCopyLink: () => void;
  societyReferralsCount: number;
  managerReferralsCount: number;
}

export const ReferralPopup: React.FC<ReferralPopupProps> = ({
  title,
  message,
  onCopyLink,
  societyReferralsCount,
  managerReferralsCount,
}) => {
  return (
    <div className={styles.ReferralPopup}>
      <div className={styles.ReferralPopupContent}>
        <h3>{title}</h3>
        <p className={styles.message}>{message}</p>
        <a target="_blank" href={REFERAL_LEARN_MORE} rel="noreferrer">
          Learn more
        </a>
        <ButtonV3
          isFullWidth
          startIcon="icon_copy"
          startIconClassName={styles.startIcon}
          iconSize="mediumplus"
          className={styles.copyBtn}
          color="primary"
          size="large"
          onClick={onCopyLink}
        >
          Copy your invite link
        </ButtonV3>

        <div className={styles.referrals}>
          <div className={styles.societyReferrals}>
            <h2>{societyReferralsCount}</h2>
            <p>Society referrals</p>
          </div>
          <div className={styles.ownReferrals}>
            <h2>{managerReferralsCount}</h2>
            <p>Your referrals</p>
          </div>
        </div>
        <div className={styles.progressReferrals}>
          <ProgressReferrals rewardCount={societyReferralsCount} />
        </div>
      </div>
    </div>
  );
};
