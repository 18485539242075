import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';

import { IconButton, Spinner } from 'components';
import Modal from 'components/Modal';
import { ButtonV3, InputV2 } from 'components/ComponentV2';
import LinkCard from 'components/CustomLinkCard';
import AddOrEditLinkModal from 'components/AddOrEditLinkModal';
import { useNotification } from 'hooks/useNotification';
import { useSocietyProfile } from 'pages/society-profile/UseSocietyProfile.hook';
import { REGEX } from 'lib/utils/regex';
import { LinkTreeAttributesType } from 'lib/models/linkTree';

import styles from './AddLinkTreeModal.module.scss';

interface AddLinkTreeModalProps {
  open: boolean;
  onClose: () => void;
}

export const AddLinkTreeModal = (props: AddLinkTreeModalProps) => {
  const { open, onClose } = props;

  const { isLoading, fetchLinkTreeLinks, updateCustomLinks } =
    useSocietyProfile();
  const notificationInstance = useNotification();

  const [linkTreeUrl, setLinkTreeUrl] = useState<string>('');
  const [isInvalidLink, setIsInvalidLink] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [linkTreeData, setLinkTreeData] = useState<LinkTreeAttributesType[]>(
    []
  );
  const [showEditLinkModal, setShowEditLinkModal] =
    React.useState<boolean>(false);
  const [modalEditIndex, setModalEditIndex] = React.useState<number>();

  useEffect(() => {
    if (!open) {
      setLinkTreeUrl('');
      setLinkTreeData([]);
      setIsSubmitted(false);
    }
  }, [open]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLinkTreeUrl(value);
    setIsInvalidLink(value ? !REGEX.URL.test(value) : false);
    setIsSubmitted(false);
  };

  const onSubmitLinkTree = async () => {
    const data = await fetchLinkTreeLinks(linkTreeUrl);
    setIsSubmitted(true);
    setLinkTreeData(data || []);
  };

  const changeLinkData = (index: number, selected: boolean, emoji?: string) => {
    if (linkTreeData) {
      const newCustomLink = [...linkTreeData];
      newCustomLink[index] = {
        ...linkTreeData[index],
        emoji,
        selected,
      };
      setLinkTreeData(newCustomLink);
    }
  };

  const onSaveLink = (title: string, link: string, index?: number) => {
    if (index !== undefined && linkTreeData.length > 0) {
      const tempData = [...linkTreeData];
      tempData[index] = {
        ...linkTreeData[index],
        title,
        url: link,
      };
      setLinkTreeData(tempData);
    } else {
      setLinkTreeData([
        ...linkTreeData,
        {
          title,
          url: link,
          selected: true,
        },
      ]);
    }
  };

  const onDeleteLink = (index: number) => {
    if (linkTreeData.length > 0) {
      linkTreeData.splice(index, 1);
      setLinkTreeData([...linkTreeData]);
    }
    setShowEditLinkModal(false);
    setModalEditIndex(undefined);
  };

  const addExternalLinks = async () => {
    const selectedData = linkTreeData.filter((data) => data.selected);
    if (selectedData.length) {
      const custom_links_attributes = selectedData.map((data) => {
        return {
          id: '',
          title: data.title,
          link: data.url,
          sub_title: '',
          emoji: data.emoji || '',
        };
      });

      try {
        await updateCustomLinks(custom_links_attributes, true);
        onClose();
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    } else {
      onClose();
    }
  };

  const renderLinkTree = useMemo(() => {
    return (
      <div className={styles.linkTreeData}>
        <p className={styles.title}>
          We’ve found {linkTreeData.length} links. Select the ones you’d like to
          add to your profile. Don’t worry, you can always change them later.
        </p>
        <div className={styles.customLinks}>
          {linkTreeData.map((link, index) => (
            <LinkCard
              key={index}
              index={index}
              link={{
                link: link.url,
                title: link.title,
                selected: !!link.selected,
                emoji: link.emoji,
              }}
              onCardClick={() => {
                setModalEditIndex(index);
                setShowEditLinkModal(true);
              }}
              changeLinkData={changeLinkData}
            />
          ))}
        </div>
        <ButtonV3
          startIcon="plus"
          color="secondary"
          size="small"
          onClick={() => setShowEditLinkModal(true)}
        >
          Add Link
        </ButtonV3>
      </div>
    );
  }, [linkTreeData, isSubmitted]);

  return (
    <>
      <Modal
        isOpen={open}
        onRequestClose={onClose}
        closeOnOverlayClick={false}
        contentClassName={styles.AddLinkTree}
      >
        {isLoading && <Spinner size="small" showBackDrop wrapper />}
        <div className={styles.AddLinkTreeHeader}>
          <p>Add your Linktree</p>
          <IconButton
            className={styles.closeIcon}
            iconName="close"
            size="small"
            onClick={onClose}
          />
        </div>
        <div className={styles.AddLinkTreeContent}>
          <p className={styles.contentTitle}>
            We’ll import all your links to Huzzle in 1 simple click.
          </p>
          <InputV2
            className={cn(styles.inputUrl, {
              [styles.success]: linkTreeUrl && isSubmitted,
            })}
            placeholder="Paste your Linktree here"
            value={linkTreeUrl}
            onChange={onInputChange}
            isInvalid={isInvalidLink}
          />
          {isSubmitted && renderLinkTree}
        </div>
        <div className={styles.AddLinkTreeAction}>
          {isSubmitted ? (
            <ButtonV3 onClick={addExternalLinks}>Done</ButtonV3>
          ) : (
            <ButtonV3
              disabled={isInvalidLink || !linkTreeUrl}
              onClick={onSubmitLinkTree}
            >
              Submit
            </ButtonV3>
          )}
        </div>
      </Modal>
      {showEditLinkModal && (
        <AddOrEditLinkModal
          saveLink={onSaveLink}
          isEdit={modalEditIndex !== undefined}
          onClose={() => {
            setShowEditLinkModal(false);
            setModalEditIndex(undefined);
          }}
          index={modalEditIndex}
          deleteLink={onDeleteLink}
          title={
            modalEditIndex !== undefined
              ? linkTreeData[modalEditIndex]?.title
              : undefined
          }
          link={
            modalEditIndex !== undefined
              ? linkTreeData[modalEditIndex]?.url
              : undefined
          }
        />
      )}
    </>
  );
};
