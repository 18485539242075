import React from 'react';

import { IconButton } from 'components';
import { ButtonV3, InputV2 } from 'components/ComponentV2';
import { Modal } from 'components/Modal/Modal.component';
import { Col, Row } from 'components/layout';

import { REGEX } from 'lib/utils/regex';

import styles from './AddOrEditLinkModal.module.scss';

interface AddOrEditLinkModalProps {
  isEdit: boolean;
  saveLink: (title: string, link: string, index?: number) => void;
  index?: number;
  deleteLink?: (index: number) => void;
  title?: string;
  link?: string;
  onClose: () => void;
}

export const AddOrEditLinkModal: React.FC<AddOrEditLinkModalProps> = ({
  isEdit,
  title: titleProp,
  link: linkProp,
  onClose: onCloseProp,
  saveLink,
  deleteLink,
  index: linkIndex,
}) => {
  const [title, setTitle] = React.useState<string>(titleProp || '');
  const [link, setLink] = React.useState<string>(linkProp || '');

  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const onClose = () => {
    setTitle('');
    setLink('');
    setErrors({});
    onCloseProp();
  };

  const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setErrors({ ...errors, title: '' });
  };

  const onLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
    setErrors({ ...errors, link: '' });
  };

  const onSave = () => {
    if (!title) {
      setErrors({ ...errors, title: 'Title is required' });
      return;
    }
    if (!link) {
      setErrors({ ...errors, link: 'Link is required' });
      return;
    }
    if (!REGEX.URL.test(link)) {
      setErrors({ ...errors, link: 'Link is invalid' });
      return;
    }
    if (isEdit && linkIndex !== undefined) {
      saveLink(title, link, linkIndex);
    } else {
      saveLink(title, link);
    }
    onClose();
  };

  const onDelete = () => {
    if (deleteLink) {
      deleteLink(linkIndex || 0);
    }
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={true}
        onRequestClose={onClose}
        contentClassName={styles.ProfileModal}
      >
        <div className={styles.ProfileModalHeader}>
          {isEdit ? <p>Edit Link</p> : <p>Add Link</p>}
          <IconButton
            onClick={onClose}
            className={styles.ProfileModalHeaderClose}
            iconName="close"
            size="small"
          />
        </div>
        <Row className={styles.ProfileModalContent}>
          <Col span={24} className={styles.inputCol}>
            <InputV2
              label="Title"
              value={title}
              onChange={onTitleChange}
              placeholder={'Add link Title'}
              labelClassName={styles.fieldLabel}
              isInvalid={!!errors.title}
            />
            {errors.title && (
              <div className={styles.errorMessage}>{errors.title}</div>
            )}
          </Col>
          <Col span={24} className={styles.inputCol}>
            <InputV2
              label="Link Url"
              value={link}
              onChange={onLinkChange}
              placeholder={'Add link url'}
              labelClassName={styles.fieldLabel}
              isInvalid={!!errors.link}
            />
            {errors.link && (
              <div className={styles.errorMessage}>{errors.link}</div>
            )}
          </Col>
        </Row>
        <div className={styles.ProfileModalFooter}>
          {isEdit && (
            <ButtonV3
              className={styles.cancelButton}
              onClick={onDelete}
              color="secondary"
            >
              Delete link
            </ButtonV3>
          )}
          <ButtonV3
            className={styles.saveButton}
            onClick={onSave}
            color="primary"
          >
            Save
          </ButtonV3>
        </div>
      </Modal>
    </>
  );
};
