import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import PendingInviteCard from 'components/PendingInviteCard';
import { useNotification } from 'hooks/useNotification';
import { useChatRoom } from 'hooks/useChatRoom';
import { useAuth } from 'lib/providers/AuthProvider';
import { useApp } from 'lib/contexts/save-in-app-context';
import { PAGE_ROUTES } from 'lib/page-routes';
import { UserRole } from 'lib/models/user';
import { apiInstance } from 'lib/utils/axios';
import { SidebarRecruiter } from './SidebarRecruiter/SidebarRecruiter.component';
import { SidebarManager } from './SidebarManager/SidebarManager.component';

import styles from './Sidebar.module.scss';

export interface SidebarProps {
  children: React.ReactNode;
}

export const Sidebar: React.FC<SidebarProps> = ({ children }: SidebarProps) => {
  const notificationInstance = useNotification();
  const router = useRouter();
  const { user, recruiter } = useAuth();
  const { recruiterMessage, setRecruiterMessage } = useApp();
  const { sendSponsorshipOfferMessage } = useChatRoom();

  /* TODO: this pending card notification is commented as new ticket will be pushed for the pending notification, have to change the logic accordingly*/

  // const [showPendingCardNotification, setShowPendingCardNotification] =
  //   useState(true);

  // useEffect(() => {
  //   if (
  //     showPendingCardNotification &&
  //     router.pathname !== PAGE_ROUTES.CAMPAIGNS &&
  //     router.pathname !== PAGE_ROUTES.DASHBOARD &&
  //     router.pathname !== PAGE_ROUTES.EVENT_MANAGEMENT_DASHBOARD &&
  //     router.pathname !== PAGE_ROUTES.NEWSLETTERS &&
  //     router.pathname !== PAGE_ROUTES.COMMUNITY
  //   ) {
  //     notificationInstance.pendingCard({
  //       message: '',
  //       timeout: 10000,
  //       renderContent: renderPendingNotification,
  //     });
  //     setShowPendingCardNotification(false);
  //   }
  // }, [router, showPendingCardNotification]);

  const {
    sponsorship_student_society_id,
    sponsorship_message,
    sponsorship_plan_id,
  } = recruiter || {};

  useEffect(() => {
    if (!user) return;
    if (
      router.pathname === PAGE_ROUTES.RECRUITER_DASHBOARD &&
      ((sponsorship_student_society_id && sponsorship_message) ||
        recruiterMessage)
    ) {
      try {
        (async () => {
          const response = await sendSponsorshipOfferMessage({
            student_society_id:
              sponsorship_student_society_id ||
              recruiterMessage?.sponsorship_student_society_id ||
              '',
            message:
              sponsorship_message ||
              recruiterMessage?.sponsorship_message ||
              '',
            sponsorship_plan_id:
              sponsorship_plan_id ||
              recruiterMessage?.sponsorship_plan_id ||
              null,
          });
          if (response) {
            setRecruiterMessage(null);

            const body = {
              recruiter: {
                sponsorship_student_society_id: '',
                sponsorship_message: '',
                sponsorship_plan_id: '',
              },
            };
            await apiInstance.patch('/api/v1/recruiter', body);
          }
        })();
      } catch (error) {
        notificationInstance.error(error as Error);
      }
    }
  }, [router, user]);

  return (
    <section className={styles.container} data-testid="Sidebar">
      {user?.userable_type.toLowerCase() === UserRole.MANAGER ? (
        <SidebarManager />
      ) : null}
      {user?.userable_type.toLowerCase() === UserRole.RECRUITER ? (
        <SidebarRecruiter />
      ) : null}
      <main className={styles.main}>{children}</main>
    </section>
  );
};

// https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export const getLayout = (page: React.ReactNode): React.ReactNode => (
  <Sidebar>{page}</Sidebar>
);

export const renderPendingNotification = (
  onClose?: () => void | undefined
): React.ReactElement => {
  return <PendingInviteCard isToaster={true} onCloseToaster={onClose} />;
};
