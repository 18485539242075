import React, { memo, useState, useEffect, useMemo, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'next-i18next';
import { debounce } from 'lodash';

import { Spinner } from 'components';
import { Input } from 'components/form';
import SelectionCheckbox from 'components/form/SelectionCheckbox';
import { MatchingPreferenceFooter } from 'components/InvitePopup/MatchinngPreferenceFooter/MatchingPreferenceFooter';
import { useVisibleOnIntersect } from 'hooks/useVisibleOnIntersect';
import { useResponsive } from 'hooks/useResponsive';
import { useNotification } from 'hooks/useNotification';
import { useCandidate } from 'hooks/useCandidate';
import { useCandidateOnboarding } from 'hooks/useCandidateOnboarding';
import { Option } from 'lib/models/option';
import { MATCHING_PREFERENCE_STEPS } from 'lib/models/candidate';
import { Footer } from '../Footer/Footer.component';
import { Inputs } from '..';
import { SidebarMP } from '../SidebarMP/SidebarMP.component';
import MobileHeaderMp from '../MobileHeaderMp';

import styles from '../Onboarding.module.scss';

export interface IndustriesProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  industries: Array<Option> | undefined;
  candidateIndustries: Array<Inputs>;
  onNextClick: ({
    selectedInterests,
    isBackClicked,
  }: {
    selectedInterests: Array<Inputs>;
    isBackClicked?: boolean;
  }) => void;
  isLoading: boolean;
  isBackLoading: boolean;
  isMatchingPreferences: boolean;
  isSocietyMatchingPreference?: boolean;
  remote_opportunity?: boolean;
  onStepSelect?: (step: MATCHING_PREFERENCE_STEPS) => void;
}

export const Industries: React.FC<IndustriesProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    industries,
    candidateIndustries,
    onNextClick,
    isLoading,
    isBackLoading,
    isMatchingPreferences,
    isSocietyMatchingPreference,
    remote_opportunity,
    onStepSelect,
  }: IndustriesProps) => {
    const [t] = useTranslation('candidate-onboarding');
    const [tCommon] = useTranslation('common');
    const screens = useResponsive();
    const notificationInstance = useNotification();
    const { updateCandidateInterests } = useCandidate();

    const contentClassNames = cn(styles.container, className);
    const [search, setSearch] = useState('');
    const bottomRef = useRef<HTMLDivElement>(null);
    const [fullyScrolled, setFullyScrolled] = useState(false);

    const [selectedIndustries, setSelectedIndustries] = useState<Array<Inputs>>(
      []
    );

    const [showIndustries, setShowIndustries] = useState<boolean>(false);

    useEffect(() => {
      if (candidateIndustries) setSelectedIndustries(candidateIndustries);
    }, [candidateIndustries]);

    const isReady =
      !showIndustries || (showIndustries && selectedIndustries.length > 0);
    const { isValueEdited, setValueEdited, onBackClickMPMobile } =
      useCandidateOnboarding({ isReady });

    const primaryCtaTextFooter = useMemo(() => {
      if (isMatchingPreferences) {
        return screens.sm ? tCommon('save') : tCommon('finish');
      }
      return tCommon('next');
    }, [isMatchingPreferences, screens.sm]);

    const onSubmit = (isBackClicked?: boolean) => {
      setValueEdited(false);
      onNextClick({ selectedInterests: selectedIndustries, isBackClicked });
    };

    const onChevronClick = () => {
      if (isValueEdited) {
        onBackClickMPMobile();
      } else {
        onSubmit(false);
      }
    };

    const apiCallToUpdate = async (modifiedIndustries: Array<Inputs>) => {
      try {
        await updateCandidateInterests({
          selectedInterests: modifiedIndustries,
          interestable_type: 'Industry',
        });
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };
    const debouncedAPICall = React.useRef(
      debounce(async (modifiedIndustries) => {
        await apiCallToUpdate(modifiedIndustries);
      }, 3000)
    ).current;

    const onNoSelection = () => {
      setSelectedIndustries([]);
      setShowIndustries(false);
      onIndustryCheckboxClick();
    };

    const onIndustryCheckboxClick = (id?: string) => {
      setValueEdited(true);
      const index = selectedIndustries?.findIndex(
        ({ interestable_id }) => interestable_id === id
      );
      let modifiedIndustries: Array<Inputs> = [];
      if (id) {
        if (index === -1)
          modifiedIndustries = [
            ...selectedIndustries,
            {
              interestable_id: id,
              interestable_type: 'Industry',
              weight: 1,
            },
          ];
        else
          modifiedIndustries =
            selectedIndustries &&
            selectedIndustries.filter(
              ({ interestable_id }) => interestable_id !== id
            );
      }

      /** For MP
       * Desktop: Autosave on every change
       *          Do not make changes when nothing is selected on mandatory step
       * Mobile: Save on CTA click
       */
      setSelectedIndustries(modifiedIndustries);

      if (
        isMatchingPreferences &&
        !isSocietyMatchingPreference &&
        !screens.sm
      ) {
        debouncedAPICall(modifiedIndustries);
      }
    };

    useVisibleOnIntersect({
      elRef: bottomRef,
      threshold: 0,
      onIntersect: () => setFullyScrolled(true),
      onHidden: () => setFullyScrolled(false),
      rootMargin: '0px',
    });

    const trimmedSearch = search.trim();
    const filteredOptions = useMemo(() => {
      return search
        ? industries?.filter(
            (indus) =>
              indus.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) >
              -1
          )
        : industries;
    }, [trimmedSearch, industries, search]);

    if (!industries) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={styles['content-contianer']}>
        {!isSocietyMatchingPreference && (
          <MobileHeaderMp
            title={t('matching-preferences.industries')}
            onChevronClick={onChevronClick}
          />
        )}

        <div
          className={cn(contentClassNames, {
            [styles['container-society']]: isSocietyMatchingPreference,
          })}
          style={style}
          data-testid="Industries"
        >
          {!isSocietyMatchingPreference &&
            isMatchingPreferences &&
            onStepSelect && (
              <SidebarMP
                isReady={isReady}
                className={styles.sidebar}
                onStepSelect={onStepSelect}
              />
            )}
          <div
            className={cn(
              styles.wrapper,
              {
                [styles['content-mp']]:
                  isMatchingPreferences && !isSocietyMatchingPreference,
              },
              { [styles['content-width-society']]: isSocietyMatchingPreference }
            )}
          >
            {!isSocietyMatchingPreference ? (
              <div className={cn(styles.contentWrapper)}>
                <p className={styles.setup}>{t('industry.title')}</p>
                <div
                  className={cn(styles.checkboxShowIndustries, {
                    [styles['industry-height']]:
                      !showIndustries && selectedIndustries.length === 0,
                  })}
                >
                  <SelectionCheckbox
                    checked={
                      showIndustries === false &&
                      selectedIndustries.length === 0
                    }
                    onChange={onNoSelection}
                    className={styles.checkBoxShowIndustry}
                  >
                    {tCommon('no')}
                  </SelectionCheckbox>
                  <SelectionCheckbox
                    checked={showIndustries || selectedIndustries.length > 0}
                    onChange={() => setShowIndustries(true)}
                    className={styles.checkBoxShowIndustry}
                  >
                    {tCommon('yes')}
                  </SelectionCheckbox>
                </div>
              </div>
            ) : null}

            {isSocietyMatchingPreference ||
            showIndustries ||
            selectedIndustries.length > 0 ? (
              <div
                className={cn(styles.content, {
                  [styles['content-width-society']]:
                    isSocietyMatchingPreference,
                })}
              >
                {isSocietyMatchingPreference ? (
                  <>
                    <p className={styles.fractionalStepper}>
                      {!remote_opportunity ? '2/2' : '3/3'}
                    </p>
                    <p className={styles.setup}>
                      {tCommon('society-matching-preference.industry-title')}
                    </p>
                    <p
                      className={styles.searchDesc}
                      dangerouslySetInnerHTML={{
                        __html: tCommon(
                          'society-matching-preference.industry-subtitle'
                        ),
                      }}
                    />
                  </>
                ) : (
                  <p className={styles.industryHeading}>
                    {t('industry.subtitle')}
                  </p>
                )}
                <Input
                  className={cn(styles.search)}
                  placeholder="Search"
                  startIcon="search"
                  startIconSize="small"
                  endIconSize="xsmall"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  endIcon={search ? 'cancel' : undefined}
                  onEndIconClick={() => setSearch('')}
                />
                <div className={styles.checkboxesResponsibility}>
                  {filteredOptions?.map((industry) => (
                    <SelectionCheckbox
                      key={industry.id}
                      checked={selectedIndustries?.some(
                        ({ interestable_id }) => industry.id === interestable_id
                      )}
                      onChange={() => onIndustryCheckboxClick(industry.id)}
                      className={styles.checkboxResponsibility}
                      disabled={
                        selectedIndustries.length >= 5 &&
                        isSocietyMatchingPreference &&
                        !selectedIndustries?.some(
                          (industryL) =>
                            industryL.interestable_id == industry.id
                        )
                      }
                    >
                      {industry.label}
                    </SelectionCheckbox>
                  ))}
                </div>
                <div className={styles['bottom-ref']} ref={bottomRef}></div>
              </div>
            ) : null}
            <div ref={bottomRef}></div>
          </div>
          {!isSocietyMatchingPreference ? (
            <Footer
              isBackLoading={isBackLoading}
              isLoading={isLoading}
              onSubmit={onSubmit}
              primaryCtaText={primaryCtaTextFooter}
              isFullyScrolled={!showIndustries || fullyScrolled}
              isWindowScrolled={showIndustries || selectedIndustries.length > 0}
              isReady={isReady}
              isMatchingPreference={isMatchingPreferences}
            />
          ) : (
            <MatchingPreferenceFooter
              isBackLoading={isBackLoading}
              isReady={isReady}
              onSubmit={onSubmit}
              primaryCtaText={tCommon('get-matches')}
              secondaryCtaText={tCommon('back')}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    );
  }
);

Industries.displayName = 'Industries';
